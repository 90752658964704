import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  UPDATE_VARIATION_GROUP_BY_GROUP_ID,
  UPDATE_VARIATION_GROUP,
  UpdateVariantGroupAction,
  DeleteVariantGroupAction,
  DELETE_VARIATION_GROUP_BY_GROUP_ID,
  DELETE_VARIATION_GROUP,
  GetVariantAttributesAction,
  FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
  FETCH_VARIATION_ATTRIBUTES,
  GetVariantGroupAction,
  FETCH_VARIATION_GROUP_IDS,
  FETCH_VARIATION_GROUP,
  FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID,
  FETCH_VARIATION_GROUP_DETAILS,
  GetVariantionGroupDetailAction,
  RESET_VARIATION_GROUP_DETAILS,
  RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID,
  RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE,
  RESET_VARIATION_GROUP_ID_ADD,
  RESET_VARIATION_GROUP_ID_ADD_STATE,
  RESET_VARIATION_GROUP_ID_DELETE_STATE,
  RESET_VARIATION_GROUP_ID_DELETE,
  RESET_VARIATION_GROUP_IDS_STATE,
  FETCH_VARIATION_GROUP_PRODUCT_LIST,
  LOAD_VARIATION_PRODUCT_LIST,
  UPDATE_PUBLISH_FLAG_COMPLETE,
  UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG,
  RESET_VARIATION_GROUP_PRODUCT_LIST_STATE,
  RESET_VARIATION_GROUP_PRODUCT_LIST,
  LoadVariationAtrributeValues,
  FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES,
} from "./VariationManagementTypes";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { deleteData, getData, putData } from "../../services/ApiService";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import { LoadProductsFromCategoryAction } from "../product-list/ProductListTypes";
import { DELETE_PRODUCT_BY_CATEGORIES } from "../remove-product-categories/RemoveProductCategoriesTypes";
import {
  LoadChildCategories,
  LoadTopCategoriesByCatalogId,
} from "../category/CategoryActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";

function* updateVariationGroup(action: UpdateVariantGroupAction) {
  let actionType = UPDATE_VARIATION_GROUP_BY_GROUP_ID;
  try {
    const baseProductId = action.payload.baseProductId;
    const variationGroupId = action.payload.variationGroupId;
    const isUpdate = action.payload.isUpdate;
    const constName = Object.keys({
      UPDATE_VARIATION_GROUP_BY_GROUP_ID,
    })[0].toString();

    const payload = {
      variationValues: action.payload.variationValues,
    };

    const endpoint = acquireEndpoint(
      constName,
      baseProductId,
      variationGroupId,
    );
    const result = yield call(callApi, actionType, putData, payload, endpoint);
    if (result?.type === actionType.SUCCESS) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: `variationManagementModal.${isUpdate ? "update" : "add"}`,
                defaultMessage: `Variation group ${isUpdate ? "updated" : "added"}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.updateFailed",
                defaultMessage: "Variation group update failed",
              },
              severity: "warning",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* deleteVariationGroup(action: DeleteVariantGroupAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const variationGroupId = action.payload.variationGroupId;
    const parentId = action.payload.parentId;
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const localeCode = yield select(selectCurrentLocale);
    const actionType = DELETE_VARIATION_GROUP_BY_GROUP_ID;
    const constName = Object.keys({
      DELETE_VARIATION_GROUP_BY_GROUP_ID,
    })[0].toString();
    const endpoint = acquireEndpoint(
      constName,
      baseProductId,
      variationGroupId,
    );
    const result = yield call(callApi, actionType, deleteData, null, endpoint);
    if (result?.type === actionType.SUCCESS) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.deleted",
                defaultMessage: "Variation group deleted.",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      yield put({
        type: DELETE_PRODUCT_BY_CATEGORIES.DONE,
        payload: { productId: variationGroupId },
      });
      !parentId
        ? yield put(
            LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId),
          )
        : yield put(
            LoadChildCategories(catalogId, parentId, localeCode, storeId),
          );
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.deleteFailed",
                defaultMessage: "Variation group delete failed",
              },
              severity: "warning",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: DELETE_VARIATION_GROUP_BY_GROUP_ID.FAILURE,
      message: e.message,
    });
  }
}

function* getVariationGroupDetails(action: GetVariantionGroupDetailAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const variationGroupId = action.payload.variationGroupId;
    const actionType = FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID;
    const constName = Object.keys({
      FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID,
    })[0].toString();
    const endpoint = acquireEndpoint(
      constName,
      baseProductId,
      variationGroupId,
    );
    const result = yield call(callApi, actionType, getData, null, endpoint);
    if (result === undefined) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.getVariationGroupDetails",
                defaultMessage:
                  "Variation group attributes details fetched failed.",
              },
              severity: "warning",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID.FAILURE,
      message: e.message,
    });
  }
}

function* getVariationAttributes(action: GetVariantAttributesAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const actionType = FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID;
    const constName = Object.keys({
      FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, baseProductId);
    const result = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
    );
    if (result?.type === actionType.SUCCESS) {
      const assignedAttrValues: LoadVariationAtrributeValues[] = [];
      const unAssignedAttrValues: LoadVariationAtrributeValues[] = [];
      result.payload?.variationAttributes.forEach((attribute) => {
        const assignedAttr: any = [];
        const unAssignedAttr: any = [];
        attribute.values.forEach((values) => {
          if (values.isAssigned === true) {
            assignedAttr.push({
              attrName: values.name,
              attrValue: values.value,
            });
          } else {
            unAssignedAttr.push({
              attrName: values.name,
              attrValue: values.value,
            });
          }
        });
        if (assignedAttr.length > 0) {
          assignedAttrValues.push({
            attrId: attribute.attributeId,
            attributes: assignedAttr,
          });
        }

        if (unAssignedAttr.length > 0) {
          unAssignedAttrValues.push({
            attrId: attribute.attributeId,
            attributes: unAssignedAttr,
          });
        }
      });
      const variationAssignedAndUnAssignedAttributesPayload = {
        assignedAttrValues: assignedAttrValues,
        unAssignedAttrValues: unAssignedAttrValues,
      };
      yield put({
        type: FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES.REQUEST,
        payload: variationAssignedAndUnAssignedAttributesPayload,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID.FAILURE,
      message: e.message,
    });
  }
}

function* getVariationGroups(action: GetVariantGroupAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const actionType = FETCH_VARIATION_GROUP_IDS;
    const constName = Object.keys({
      FETCH_VARIATION_GROUP_IDS,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, baseProductId);
    const result = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
    );
    if (result === undefined) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.getVariationGroups",
                defaultMessage: "Variation group ids fetched failed.",
              },
              severity: "warning",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_VARIATION_GROUP_IDS.FAILURE,
      message: e.message,
    });
  }
}

function* resetVariantDetails() {
  try {
    yield put({ type: RESET_VARIATION_GROUP_IDS_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

function* resetVariantDetailsByGroupId() {
  try {
    yield put({ type: RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

function* resetVariationAddedState() {
  try {
    yield put({ type: RESET_VARIATION_GROUP_ID_ADD_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

function* resetVariationDeletedState() {
  try {
    yield put({ type: RESET_VARIATION_GROUP_ID_DELETE_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

function* getVariationGroupProductList(action) {
  try {
    const categoryId = action.payload.categoryId;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeId;
    const productIds = action.payload.productIds;
    const productCount = productIds.length;
    const storeListIds = action.payload.storeListIds;
    let totalPagesFetched = 0;
    let numberOfAPIcallsInchunks = Math.ceil(productCount / 4); // considering calling 4 items at a time;
    let currentIndex = 0;
    while (numberOfAPIcallsInchunks !== 0) {
      numberOfAPIcallsInchunks--;
      totalPagesFetched += 1;
      const productIdsChunk = productIds.slice(currentIndex, currentIndex + 4);
      yield put({
        type: LOAD_VARIATION_PRODUCT_LIST,
        payload: {
          productIds: productIdsChunk,
          catalogId,
          storeId,
          localeCode,
          categoryId,
          totalPagesFetched,
          storeListIds,
        },
      });
      currentIndex = currentIndex + 4;
    }
  } catch (error) {
    // yet to add proper error and success messages
    console.log("errorr", error);
  }
}

function* updatePublishFlagInVariationModal(action) {
  try {
    const storeId = action.payload.storeId;
    const productId = action.payload.productId;
    const isPublished = action.payload.isPublished;
    const actionType = UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG;
    const constName = Object.keys({
      UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, productId);
    const headersObj = {
      "x-store-id": storeId,
    };
    const payloadData: any = {
      isPublished,
    };
    const result = yield call(
      callApi,
      actionType,
      putData,
      payloadData,
      endpoint,
      headersObj,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "variationManagementModal.updatePublishFlagSuccess",
                defaultMessage:
                  "Updated published value of {productId} for {storeId} store",
              },
              variables: {
                productId,
                storeId,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      yield put({
        type: UPDATE_PUBLISH_FLAG_COMPLETE,
        payload: { productId, isPublished, storeId },
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG.FAILURE });
  }
}

function* getVariationProducts(action: LoadProductsFromCategoryAction) {
  try {
    const actionType = FETCH_VARIATION_GROUP_PRODUCT_LIST;
    const catalogId = action.payload.catalogId;
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeCode;
    const constName = Object.keys({
      FETCH_VARIATION_GROUP_PRODUCT_LIST,
    })[0].toString();
    const productIds = action.payload.productIds;
    let commaSeperatedProductIds: string = "";
    if (productIds && Array.isArray(productIds) && productIds.length) {
      commaSeperatedProductIds = String(
        productIds.length > 1
          ? productIds.join(",").toString()
          : productIds.length && productIds[0],
      );
    }

    const endpoint = acquireEndpoint(constName, commaSeperatedProductIds);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    // if (response && response.payload) {
    //   //pagesFetched:action.payload.numberOfAPIcallsInchunks
    //   //yield put({ type: FETCH_VARIATION_GROUP_PRODUCT_LIST.SUCCESS, payload: { ...response.payload, categoryId, totalPagesFetched } })
    // }
    // else {
    //   //pagesFetched:action.payload.numberOfAPIcallsInchunks
    //   //yield put({ type: FETCH_VARIATION_GROUP_PRODUCT_LIST.FAILURE, message: 'Something went wrong!!', payload: { totalPagesFetched } });
    // }
  } catch (e: any) {
    yield put({
      type: FETCH_VARIATION_GROUP_PRODUCT_LIST.FAILURE,
      message: e.message,
    });
  }
}

function* resetVariationGroupProductList() {
  try {
    yield put({ type: RESET_VARIATION_GROUP_PRODUCT_LIST_STATE });
  } catch (e: any) {
    console.log(e);
  }
}

export function* watchResetVariationGroupProductListState() {
  yield takeEvery(
    RESET_VARIATION_GROUP_PRODUCT_LIST,
    resetVariationGroupProductList,
  );
}

export function* watchResetVariationDeletedState() {
  yield takeEvery(RESET_VARIATION_GROUP_ID_DELETE, resetVariationDeletedState);
}

export function* watchResetVariationAddedState() {
  yield takeEvery(RESET_VARIATION_GROUP_ID_ADD, resetVariationAddedState);
}

export function* watchResetVariantGroupDetailsByGroupId() {
  yield takeEvery(
    RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID,
    resetVariantDetailsByGroupId,
  );
}

export function* watchResetVariantGroupDetails() {
  yield takeEvery(RESET_VARIATION_GROUP_DETAILS, resetVariantDetails);
}

export function* watchUpdateVariationGroup() {
  yield takeEvery(UPDATE_VARIATION_GROUP, updateVariationGroup);
}

export function* watchDeleteVariationGroup() {
  yield takeEvery(DELETE_VARIATION_GROUP, deleteVariationGroup);
}

export function* watchGetVariationAttributes() {
  yield takeEvery(FETCH_VARIATION_ATTRIBUTES, getVariationAttributes);
}

export function* watchGetVariationGroupId() {
  yield takeEvery(FETCH_VARIATION_GROUP, getVariationGroups);
}

export function* watchGetVariationGroupDetails() {
  yield takeEvery(FETCH_VARIATION_GROUP_DETAILS, getVariationGroupDetails);
}

export function* watchFetchVariationGroupProducts() {
  yield takeEvery(
    FETCH_VARIATION_GROUP_PRODUCT_LIST.REQUEST,
    getVariationGroupProductList,
  );
}

export function* watchLoadVariationProducts() {
  yield takeEvery(LOAD_VARIATION_PRODUCT_LIST, getVariationProducts);
}

export function* watchUpdatePublishFlagInVariationModal() {
  yield takeEvery(
    UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG.REQUEST,
    updatePublishFlagInVariationModal,
  );
}
