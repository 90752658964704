import {
  LoadProductsFromCategoryResponseAction,
  ProductSequenceData,
} from "../product-list/ProductListTypes";
import {
  DELETE_VARIATION_GROUP_BY_GROUP_ID,
  FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
  FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID,
  FETCH_VARIATION_GROUP_IDS,
  FETCH_VARIATION_GROUP_PRODUCT_LIST,
  LoadVariationProductsListResponseAction,
  RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE,
  RESET_VARIATION_GROUP_IDS_STATE,
  RESET_VARIATION_GROUP_ID_ADD_STATE,
  RESET_VARIATION_GROUP_ID_DELETE_STATE,
  RESET_VARIATION_GROUP_PRODUCT_LIST_STATE,
  UPDATE_PUBLISH_FLAG_COMPLETE,
  UPDATE_VARIATION_GROUP_BY_GROUP_ID,
  UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG,
  VariationAttributes,
  VariationGroup,
  LoadVariationAtrributeValues,
  FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES,
} from "./VariationManagementTypes";

export interface VariationData {
  isVariationGroupAdded: boolean;
  isFetched: boolean;
  isGroupIdsFetched: boolean;
  isVariationGroupDeleted: boolean;
  data: VariationAttributes;
  variationGroupIds: string[];
  variationGroupDetails: VariationGroup;
  variationGroupProductList: ProductSequenceData[];
  isFetchingVariationGroupProductList: boolean;
  areAllPagesFetched: boolean;
  assignedAttributeValues: LoadVariationAtrributeValues[];
  unAssignedAttributeValues: LoadVariationAtrributeValues[];
}

const initialState: VariationData = {
  isFetched: false,
  isGroupIdsFetched: false,
  isVariationGroupAdded: false,
  isVariationGroupDeleted: false,
  data: {
    productId: "",
    variationAttributes: [
      {
        attributeId: "",
        name: "",
        translations: [
          {
            name: "",
            localeCode: "",
          },
        ],
        values: [
          {
            name: "",
            value: "",
            translations: [
              {
                name: "",
                localeCode: "",
              },
            ],
            isAssigned: false,
          },
        ],
      },
    ],
  },
  variationGroupIds: [],
  variationGroupDetails: {
    productId: "",
    variationValues: {},
  },
  variationGroupProductList: [],
  isFetchingVariationGroupProductList: false,
  areAllPagesFetched: false,
  assignedAttributeValues: [],
  unAssignedAttributeValues: [],
};

const getProductsFromResponse = (
  action: LoadProductsFromCategoryResponseAction,
): ProductSequenceData[] => {
  const productsInCategory: any = [];
  if (action?.payload) {
    for (const v of action.payload.results) {
      productsInCategory.push({
        sequence: v?.cachedProduct?.sequence,
        productId: v?.productId,
        product: v?.cachedProduct?.product,
        cachedAt: v?.cachedProduct?.cachedAt,
      });
    }
  }
  return productsInCategory;
};

export const VariationManagementReducer = (
  state: VariationData = initialState,
  action,
) => {
  const data = action.payload;
  switch (action.type) {
    case UPDATE_VARIATION_GROUP_BY_GROUP_ID.FAILURE:
    case UPDATE_VARIATION_GROUP_BY_GROUP_ID.SUCCESS:
      return {
        ...state,
        isVariationGroupAdded: true,
      };
    case DELETE_VARIATION_GROUP_BY_GROUP_ID.FAILURE:
    case DELETE_VARIATION_GROUP_BY_GROUP_ID.SUCCESS:
      return {
        ...state,
        isVariationGroupDeleted: true,
      };
    case FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID.SUCCESS: {
      return {
        ...state,
        data: data,
      };
    }
    case FETCH_VARIATION_GROUP_IDS.SUCCESS: {
      const vGroupIds = action.payload.variationGroupIds;
      return {
        ...state,
        isGroupIdsFetched: true,
        variationGroupIds: vGroupIds,
      };
    }
    case FETCH_VARIATION_GROUP_IDS.FAILURE: {
      return {
        ...state,
        isGroupIdsFetched: true,
        variationGroupIds: [],
      };
    }
    case RESET_VARIATION_GROUP_IDS_STATE: {
      return {
        ...state,
        variationGroupIds: [],
        isGroupIdsFetched: false,
        variationGroupDetails: [],
      };
    }
    case FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID.SUCCESS: {
      return {
        ...state,
        isFetched: true,
        variationGroupDetails: data,
      };
    }
    case FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID.FAILURE: {
      return {
        ...state,
        isFetched: true,
      };
    }
    case RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE: {
      return {
        ...state,
        isFetched: false,
      };
    }
    case RESET_VARIATION_GROUP_ID_ADD_STATE: {
      return {
        ...state,
        isVariationGroupAdded: false,
      };
    }
    case RESET_VARIATION_GROUP_ID_DELETE_STATE: {
      return {
        ...state,
        isVariationGroupDeleted: false,
      };
    }
    case FETCH_VARIATION_GROUP_PRODUCT_LIST.REQUEST: {
      return {
        ...state,
        isFetchingVariationGroupProductList: true,
        variationGroupProductList: [],
      };
    }
    case FETCH_VARIATION_GROUP_PRODUCT_LIST.SUCCESS: {
      let fetchAction = action as LoadVariationProductsListResponseAction;
      const storeListIds = action?.payload?.storeListIds;
      const productList = getProductsFromResponse(fetchAction);

      let updatedProductList = [
        ...Object.values(state.variationGroupProductList),
        ...productList,
      ];
      let uniqueProductList = updatedProductList.filter((obj, index) => {
        return (
          index ===
          updatedProductList.findIndex((o) => obj.productId === o.productId)
        );
      });

      uniqueProductList.forEach((product, index) => {
        let storeSpecificDetailsForAllStores = {};
        // setting all the stores publishflag initially, if not available set it to null with key as storeId and value as boolean or null
        storeListIds.forEach((store) => {
          storeSpecificDetailsForAllStores[store.storeId] = {
            isPublished:
              product?.product?.storeSpecific?.[store.storeId]?.isPublished !==
              null
                ? product?.product?.storeSpecific?.[store.storeId]?.isPublished
                : null,
          };
        });
        uniqueProductList[index] = {
          ...uniqueProductList[index],
          product: {
            ...uniqueProductList[index].product,
            storeSpecific: {
              ...uniqueProductList[index]?.product?.storeSpecific,
              ...storeSpecificDetailsForAllStores,
            } as Object,
            isUpdatingPublishFlag: false,
          },
        };
      });
      return {
        ...state,
        isFetchingVariationGroupProductList: !(
          state.variationGroupProductList.length === updatedProductList.length
        ),
        variationGroupProductList: uniqueProductList,
        areAllPagesFetched:
          uniqueProductList.length === state.variationGroupIds.length,
      };
    }
    case RESET_VARIATION_GROUP_PRODUCT_LIST_STATE: {
      return {
        ...state,
        variationGroupProductList: [],
      };
    }
    case FETCH_VARIATION_GROUP_PRODUCT_LIST.FAILURE: {
      return {
        ...state,
        isFetchingVariationGroupProductList: !(
          state.variationGroupProductList.length ===
          state.variationGroupIds.length
        ),
      };
    }
    case UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG.REQUEST: {
      const productId = action.payload.productId;
      let updatedProductsList = [...state.variationGroupProductList];
      const indexToBeUpdated = updatedProductsList.findIndex(
        (product) => product.productId === productId,
      );
      updatedProductsList[indexToBeUpdated] = {
        ...updatedProductsList[indexToBeUpdated],
        product: {
          ...updatedProductsList[indexToBeUpdated].product,
          isUpdatingPublishFlag: true,
        },
      };
      return {
        ...state,
        variationGroupProductList: updatedProductsList,
      };
    }
    case UPDATE_PUBLISH_FLAG_COMPLETE: {
      const productId = action.payload.productId;
      const storeId = action.payload.storeId;
      const isPublishedUpdatedFlag = action.payload.isPublished;
      let updatedProductsList = [...state.variationGroupProductList];
      const indexToBeUpdated = updatedProductsList.findIndex(
        (product) => product.productId === productId,
      );
      updatedProductsList[indexToBeUpdated] = {
        ...updatedProductsList[indexToBeUpdated],
        product: {
          ...updatedProductsList[indexToBeUpdated].product,
          storeSpecific: {
            ...updatedProductsList[indexToBeUpdated]?.product?.storeSpecific,
            [storeId]: {
              isPublished: isPublishedUpdatedFlag,
            },
          } as Object,
          isUpdatingPublishFlag: false,
        },
      };
      return {
        ...state,
        variationGroupProductList: updatedProductsList,
      };
    }
    case FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES.REQUEST:
      const payload = action.payload;
      return {
        ...state,
        assignedAttributeValues: payload.assignedAttrValues,
        unAssignedAttributeValues: payload.unAssignedAttrValues,
      };
    default:
      return state;
  }
};
