/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../../../../store/AppState";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../../../../store/modal/ModalSelectors";
import { setModalState } from "../../../../../store/modal/ModalActions";
// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Fade from "@material-ui/core/Fade/Fade";
import { useIntl } from "react-intl";
//import { CircularProgress, Typography } from "@material-ui/core";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../Textfield/TextFieldWrapper";
import {
  loadAttributesList,
  loadUnifiedSortingRulesList,
  resetBusinessRulesDetails,
  saveUnifiedBusinessRules,
  updateUnifiedBusinessRules,
} from "../../../../../store/businessRules-list/BusinessRulesListActions";
import {
  selectAttributesListFetched,
  selectBusinessRuledDetailsById,
  selectIsDetailsSaved,
  selectIsFetchedAttributes,
  selectIsFetchedById,
} from "../../../../../store/businessRules-list/BusinessRuleslistSelectors";
import {
  MappedValue,
  SelectType,
} from "../../../../../store/businessRules-list/BusinessRulesListTypes";
import { setLoaderState } from "../../../../../store/loader/LoaderActions";
import { selectIsLoading } from "../../../../../store/loader/LoaderSelectors";
import { classes, StyledDiv, StyledSpan } from "./Styles";
import { useUtils } from "./utils";
import AttributesForm from "./AttributesForm";
import MetricsForm from "./MetricsForm";
import CustomDialog from "../../../../common/DialogContainer";
import DropdownMenu from "../../../../common/DropdownMenu";
import ButtonComponent from "../../../../common/ButtonComponent";

const UnifiedSortingRuleModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    FORM_VALIDATION_ATTRIBUTES,
    FORM_VALIDATION_METRICS,
    FORM_VALIDATION,
    FORM_VALIDATION_EMPTY,
    INITIAL_STATE,
    SortingCriteria,
  } = useUtils();
  const attributesList = useSelector(selectAttributesListFetched);
  const isDetailsSaved = useSelector(selectIsDetailsSaved);
  const isLoading = useSelector((state: AppState) =>
    selectIsLoading(state, "SAVE_BUSINESS_RULES_DETAILS"),
  );
  const isFetchedById = useSelector(selectIsFetchedById);
  const isFetchedAttributes = useSelector(selectIsFetchedAttributes);
  const businessRulesDetailsObj = useSelector(selectBusinessRuledDetailsById);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [mappedValues, setMappedValues] = useState<MappedValue[]>([]);
  const [initialState, setInitialState] = useState(INITIAL_STATE);
  const [criteria, setCriteria] = useState<string>("");
  const [isFetchingDetails, setIsFetchingDetails] = useState<boolean>(false);
  const ruleId = useSelector((state: AppState) =>
    selectModalVariable(state, "AttributeSortingRuleModal", "ruleId"),
  );

  const handleAttributeSection = useCallback(() => {
    dispatch(loadAttributesList());
  }, [dispatch]);

  const handleAttributesValues = (value) => {
    const filteredData = attributesList.find((attribute) =>
      attribute.label === value ? attribute : null,
    );
    const matchingValuesNew: SelectType[] = [];
    filteredData?.values.map((data) => {
      matchingValuesNew.push({ label: data.label, value: data.valueId });
      return matchingValuesNew;
    });
    setMappedValues((prevState) => [
      ...prevState,
      {
        label: filteredData?.label,
        values: matchingValuesNew,
      },
    ]);
  };
  const handleBusinessRulesDetailsPayload = (dataObj) => {
    if (dataObj.ruleType === "MCS" && isFetchedById && isFetchedAttributes) {
      setCriteria(SortingCriteria.Multi);
      dataObj.criteria[0].sortingPointsLUT.map((data) =>
        handleAttributesValues(data.attr),
      );
    }
    if (dataObj.ruleType === "AS" && isFetchedById && isFetchedAttributes) {
      dataObj.sortingPointsLUT.forEach((data) =>
        handleAttributesValues(data.attr),
      );
      setCriteria(SortingCriteria.Attribute);
    }
    if (dataObj.ruleType === "WS" && isFetchedById) {
      setCriteria(SortingCriteria.Metric);
    }
    const updatedDataObjPayload = {
      name: dataObj.name,
      description: dataObj.description,
      scoringFormula:
        dataObj.ruleType === "AS"
          ? dataObj.strategy
          : dataObj.ruleType === "MCS" && dataObj.criteria.length
            ? dataObj.criteria[0].strategy
            : "",
      attributes:
        dataObj.ruleType === "AS"
          ? dataObj.sortingPointsLUT
          : dataObj.ruleType === "MCS"
            ? dataObj.criteria[0].sortingPointsLUT
            : [
                {
                  attr: "",
                  value: "",
                  points: "",
                },
              ],
      period:
        dataObj.ruleType === "WS"
          ? dataObj.dateRange
          : dataObj.ruleType === "MCS"
            ? dataObj.criteria[1].dateRange
            : "",
      timezone:
        dataObj.ruleType === "WS"
          ? dataObj.timezone
          : dataObj.ruleType === "MCS"
            ? dataObj.criteria[1].timezone
            : "",
      metrics:
        dataObj.ruleType === "WS"
          ? dataObj.formula
          : dataObj.ruleType === "MCS"
            ? dataObj.criteria[1].formula
            : [
                {
                  metric: "",
                  weight: "",
                  order: "",
                },
              ],
    };
    return updatedDataObjPayload;
  };

  useEffect(() => {
    if (ruleId) {
      setIsFetchingDetails(true);
    }
  }, [ruleId]);

  useEffect(() => {
    if (isLoading && isDetailsSaved) {
      dispatch(setLoaderState("SAVE_BUSINESS_RULES_DETAILS", false));
      dispatch(setModalState("AttributeSortingRuleModal", false));
      dispatch(setLoaderState("LIST_BUSINESS_RULES_LOADER", true));
      dispatch(loadUnifiedSortingRulesList());
    }
  }, [isLoading, isDetailsSaved, dispatch]);

  useEffect(() => {
    if (
      businessRulesDetailsObj.ruleType !== "" &&
      isFetchedById &&
      isFetchedAttributes &&
      attributesList.length
    ) {
      const loadedObjData = handleBusinessRulesDetailsPayload(
        businessRulesDetailsObj,
      );
      setIsFetchingDetails(false);
      setInitialState({ ...initialState, ...loadedObjData });
    } else {
      setInitialState(INITIAL_STATE);
    }
  }, [businessRulesDetailsObj, isFetchedById, attributesList.length]);

  useEffect(() => {
    if (criteria) {
      if (
        criteria === SortingCriteria.Attribute ||
        criteria === SortingCriteria.Multi
      ) {
        handleAttributeSection();
      }
    }
  }, [criteria]);

  useEffect(() => {
    setCriteria("");
  }, []);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "AttributeSortingRuleModal"),
  );
  const handleClose = () => {
    setCriteria("");
    setAlertMessage("");
    dispatch(setModalState("AttributeSortingRuleModal", false));
    dispatch(resetBusinessRulesDetails());
    //dispatch(loadUnifiedSortingRulesList());
    setMappedValues([]);
    setInitialState(INITIAL_STATE);
    return;
  };

  const handleSaveUnifiedBusinessRulesForm = (payload, resetForm) => {
    if (payload?.ruleId) {
      dispatch(setLoaderState("SAVE_BUSINESS_RULES_DETAILS", true));
      dispatch(updateUnifiedBusinessRules(payload));
      resetForm();
      setAlertMessage("");
      setCriteria("");
      setMappedValues([]);
      return;
    }
    dispatch(setLoaderState("SAVE_BUSINESS_RULES_DETAILS", true));
    dispatch(saveUnifiedBusinessRules(payload));
    resetForm();
    setAlertMessage("");
    setCriteria("");
    setMappedValues([]);
    return;
  };
  const handleFormSubmission = (data, criteria, resetForm) => {
    const formAttributes: string[] = [];
    let isDuplicateAttrFound = false;
    for (const attribute of data?.attributes) {
      const attrValue = attribute?.attr + "_" + attribute?.value;
      formAttributes?.find((attr) => attr === attrValue)
        ? (isDuplicateAttrFound = true)
        : formAttributes.push(attrValue);
      if (isDuplicateAttrFound) {
        break;
      }
    }
    const uniqueMetrics = new Set(data.metrics.map((metric) => metric.metric));
    let totalPercentage = 0;
    data.metrics.map((metric) => {
      totalPercentage = totalPercentage + Number(metric.weight);
      return totalPercentage;
    });
    let payload;
    if (criteria === SortingCriteria.Attribute) {
      if (isDuplicateAttrFound) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.duplicateAttributes",
            defaultMessage: "Some Duplicate Attributes found!",
          })}`,
        );
        return;
      } else {
        payload = {
          ruleType: "AS",
          name: data.name,
          description: data.description,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          strategy: data.scoringFormula,
          sortingPointsLUT: data.attributes,
        };
        if (businessRulesDetailsObj.ruleType !== "") {
          payload.ruleId = businessRulesDetailsObj.ruleId;
        }
        handleSaveUnifiedBusinessRulesForm(payload, resetForm);
        return;
      }
    }

    if (criteria === SortingCriteria.Metric) {
      if (Array.from(uniqueMetrics).length < data.metrics.length) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.duplicateMetrics",
            defaultMessage: "Some Duplicate Metrics found!",
          })}`,
        );
        return;
      } else if (totalPercentage < 100) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.weightedPercentage-less-than-100",
            defaultMessage: "Total sum of weighted Percentage should be 100",
          })}`,
        );
        return;
      } else if (totalPercentage > 100) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.weightedPercentage-more-than-100",
            defaultMessage: "Total weighted percentage cannot be more than 100",
          })}`,
        );
        return;
      } else {
        payload = {
          ruleType: "WS",
          name: data.name,
          description: data.description,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          formula: data.metrics,
        };
        if (businessRulesDetailsObj.ruleType !== "") {
          payload.ruleId = businessRulesDetailsObj.ruleId;
        }
        handleSaveUnifiedBusinessRulesForm(payload, resetForm);
        return;
      }
    }
    if (criteria === SortingCriteria.Multi) {
      if (isDuplicateAttrFound) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.duplicateAttributes",
            defaultMessage: "Some Duplicate Attributes found!",
          })}`,
        );
        return;
      } else if (Array.from(uniqueMetrics).length < data.metrics.length) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.duplicateMetrics",
            defaultMessage: "Some Duplicate Metrics found!",
          })}`,
        );
        return;
      } else if (totalPercentage < 100) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.weightedPercentage-less-than-100",
            defaultMessage: "Total sum of weighted Percentage should be 100",
          })}`,
        );
        return;
      } else if (totalPercentage > 100) {
        setAlertMessage(
          `${intl.formatMessage({
            id: "unifiedSortingRuleModal.weightedPercentage-more-than-100",
            defaultMessage: "Total weighted percentage cannot be more than 100",
          })}`,
        );
        return;
      } else {
        const attributesPayload = {
          ruleType: "AS",
          strategy: data.scoringFormula,
          sortingPointsLUT: data.attributes,
        };
        const metricsPayload = {
          ruleType: "WS",
          formula: data.metrics,
        };
        const criteriaPayload = [attributesPayload, metricsPayload];
        payload = {
          ruleType: "MCS",
          name: data.name,
          description: data.description,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          criteria: criteriaPayload,
        };
        if (businessRulesDetailsObj.ruleType !== "") {
          payload.ruleId = businessRulesDetailsObj.ruleId;
        }
        handleSaveUnifiedBusinessRulesForm(payload, resetForm);
        return;
      }
    }
  };

  const handleMappedValues = (attributeLabel) => {
    if (mappedValues.length) {
      let result = mappedValues?.filter(
        (mappedData) => mappedData.label === attributeLabel,
      );
      return result.length ? result[0].values : [];
    } else {
      return mappedValues;
    }
  };

  const handleRemoveSingleAttribute = () => {
    setCriteria("");
  };

  const handleRemoveSingleMetric = () => {
    setCriteria("");
  };

  const handleChange = useCallback(
    (event) => {
      setCriteria(event.target.value);
    },
    [setCriteria],
  );

  const validateSortingForm = () => {
    if (!criteria) {
      return FORM_VALIDATION_EMPTY;
    }
    if (criteria === SortingCriteria.Attribute) {
      return FORM_VALIDATION_ATTRIBUTES;
    } else if (criteria === SortingCriteria.Metric) {
      return FORM_VALIDATION_METRICS;
    } else {
      return FORM_VALIDATION;
    }
  };

  const sortingRulesModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "unifiedSortingRuleModal.sortingRules",
          defaultMessage: "Sorting Rules",
        })}
      </Typography>
    );
  };

  const sortingRulesModalContent = () => {
    return (
      <>
        {alertMessage !== "" && (
          <Alert onClose={() => setAlertMessage("")} severity="error">
            {alertMessage}
          </Alert>
        )}
        {isLoading || isFetchingDetails ? (
          <StyledDiv className={classes.dialogChildClass}>
            <StyledSpan className={classes.saveTextClass}>
              {isLoading ? (
                <Typography variant="subHeader">
                  {intl.formatMessage({
                    id: "unifiedSortingRuleModal.loaderMessage",
                    defaultMessage: "Saving Details...",
                  })}
                </Typography>
              ) : (
                <Typography variant="subHeader">
                  {intl.formatMessage({
                    id: "unifiedSortingRuleModal.fetchMessage",
                    defaultMessage: " Fetching Details...",
                  })}
                </Typography>
              )}
            </StyledSpan>
            <CircularProgress />
          </StyledDiv>
        ) : null}
        <StyledDiv
          className={`${
            isLoading || isFetchingDetails
              ? classes.dialogBlurClass
              : classes.formWrapper
          }`}
        >
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSortingForm}
            onSubmit={(values, { resetForm }) => {
              handleFormSubmission(values, criteria, resetForm);
            }}
          >
            {(formik) => {
              return (
                <div>
                  <Form>
                    <Box>
                      <TextfieldWrapper
                        name="name"
                        label="Name"
                        type="text"
                        style={{
                          marginBottom: 18,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <Box>
                      <TextfieldWrapper
                        name="description"
                        type="text"
                        label="Description"
                        style={{
                          marginBottom: 18,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <DropdownMenu
                      menuId="UnifiedSortingRuleModal"
                      classes={classes}
                      changeHandler={handleChange}
                      value={criteria}
                      SortingCriteria={SortingCriteria}
                    />

                    <div>
                      <div style={{ marginBottom: 20 }}>
                        {(criteria === SortingCriteria.Attribute ||
                          criteria === SortingCriteria.Multi) && (
                          <AttributesForm
                            handleMappedValues={handleMappedValues}
                            attributesList={attributesList}
                            formik={formik}
                            handleAttributesValues={handleAttributesValues}
                            handleRemoveSingleAttribute={
                              handleRemoveSingleAttribute
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(criteria === SortingCriteria.Metric ||
                          criteria === SortingCriteria.Multi) && (
                          <MetricsForm
                            formik={formik}
                            handleRemoveSingleMetric={handleRemoveSingleMetric}
                          />
                        )}
                      </div>
                    </div>
                    <StyledDiv className={classes.btnGroup}>
                      <ButtonComponent
                        color="secondaryButtonColorCTABlue"
                        variant="outlined"
                        justifyContent="center"
                        disabled={isLoading ? true : false}
                        onClick={() => handleClose()}
                      >
                        {intl.formatMessage({
                          id: "unifiedSortingRuleModal.cancel",
                          defaultMessage: "Cancel",
                        })}
                      </ButtonComponent>
                      <ButtonComponent
                        color="primary"
                        variant="contained"
                        justifyContent="center"
                        type="submit"
                        disabled={!isLoading ? false : true}
                      >
                        {businessRulesDetailsObj.ruleType !== ""
                          ? intl.formatMessage({
                              id: "unifiedSortingRuleModal.update",
                              defaultMessage: "Update",
                            })
                          : intl.formatMessage({
                              id: "unifiedSortingRuleModal.save",
                              defaultMessage: "Save",
                            })}
                      </ButtonComponent>
                    </StyledDiv>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </StyledDiv>
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      title={sortingRulesModalTitle()}
      onClose={() => handleClose()}
      fullWidth
      maxWidth={"md"}
    >
      {sortingRulesModalContent()}
    </CustomDialog>
  );
};
export default UnifiedSortingRuleModal;
