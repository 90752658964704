import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

export const classes = {
  dialogChildClass: "dialogChildClass",
  saveTextClass: "saveTextClass",
  metricFieldsParent: "metricFieldsParent",
  metricSectionParent: "metricSectionParent",
  removeBtnParent: "removeBtnParent",
  border: "border",
  attributeFieldsParent: "attributeFieldsParent",
  attributeScoringFormula: "attributeScoringFormula",
  attributeSectionParent: "attributeSectionParent",
  removeAttrBtnParent: "removeAttrBtnParent",
  dialogBlurClass: "dialogBlurClass",
  formWrapper: "formWrapper",
  btnGroup: "btnGroup",
  btnDiv: "btnDiv",
};
export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${classes.dialogChildClass}`]: {
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`&.${classes.metricFieldsParent}`]: {
    border: "2px solid lightgray",
    padding: 10,
  },
  [`&.${classes.metricSectionParent}`]: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  [`&.${classes.removeBtnParent}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  [`&.${classes.border}`]: {
    borderBottom: "2px solid lightgray",
    marginBottom: 10,
  },
  [`&.${classes.attributeFieldsParent}`]: {
    border: "2px solid lightgray",
    padding: 10,
  },
  [`&.${classes.attributeScoringFormula}`]: {
    marginBottom: 10,
  },
  [`&.${classes.attributeSectionParent}`]: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  [`&.${classes.removeAttrBtnParent}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 10,
  },
  [`&.${classes.attributeFieldsParent}`]: {
    border: "2px solid lightgray",
    padding: 10,
  },
  [`&.${classes.dialogBlurClass}`]: {
    filter: "blur(2px)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`&.${classes.formWrapper}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`&.${classes.btnGroup}`]: {
    display: "flex",
    margin: 20,
    justifyContent: "flex-end",
  },
  [`&.${classes.btnDiv}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const StyledSpan = styled("span")(({ theme }) => ({
  [`&.${classes.saveTextClass}`]: {
    marginRight: 10,
    fontSize: 20,
    fontWeight: 600,
  },
}));
export const useStyles = (theme: Theme) => {
  return {
    formLayout: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    fieldMargin: {
      marginBottom: 10,
    },
    attributeSection: {
      display: "grid",
      gridTemplateColumns: "24% 76%",
    },
    addBtn: {
      display: "flex",
      width: "100%",
      textTransform: "none",
    },
    attributeSubSection: {
      marginRight: 30,
    },
    metricsParent: {
      display: "grid",
      gridTemplateColumns: "24% 76%",
      marginTop: 20,
    },
    metricsDisplay: {
      display: "flex",
      justifyContent: "space-between",
    },
    metricSubSection: { marginRight: 30 },
    submitBtnChild: {
      padding: 5,
      backgroundColor: "rgb(241, 131, 8)",
      color: "#fff",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "rgb(241, 131, 8)",
        color: "#fff",
      },
      textTransform: "none",
    },
    addAttributeBtn: {
      padding: 5,
      backgroundColor: "#ccc",
      color: "#000",
      fontSize: 16,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ccc3",
        color: "#000",
      },
    },
    addMetricBtn: {
      padding: 5,
      backgroundColor: "#ccc",
      color: "#000",
      fontSize: 16,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ccc3",
        color: "#000",
      },
    },
    cancelBtnChild: {
      padding: 5,
      backgroundColor: "#ccc",
      color: "#000",
      fontSize: 16,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ccc3",
        color: "#000",
      },
    },
    grpFields: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      marginBottom: 10,
    },
    dialogClass: {
      position: "relative",
      width: "100%",
    },
    closeIcon: {
      width: 21,
      height: 21,
      border: 2,
      color: theme.palette.text.primary,
    },
    menuItem: {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
      },
    },
    attributeSearch: {
      width: "324px !important",
      marginRight: "10px !important",
    },
  };
};
