import React from "react";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import Grid from "@material-ui/core/Grid";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { updateSelectedAttribute } from "../../store/overlay/OverlayActions";
import { setCurrentCategory } from "../../store/category/CategoryActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { makeStyles, createStyles } from "@material-ui/core";

export const ConfirmCategorySelectionModalId = "ConfirmCategorySelectionModal";

interface Props {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    dailogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

const ConfirmCategorySelection: React.FC<Props> = ({ setSelected }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const categoryId = useSelector((state: AppState) =>
    selectModalVariable(state, ConfirmCategorySelectionModalId, "categoryId"),
  );
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, ConfirmCategorySelectionModalId),
  );

  const handleClose = () => {
    dispatch(setModalState(ConfirmCategorySelectionModalId, false));
  };

  const handleContinue = () => {
    dispatch(updateSelectedAttribute(""));
    setSelected([categoryId]);
    dispatch(setCurrentCategory(categoryId));
    handleClose();
  };

  const confirmationCategorySelectionTitle = () => {
    return (
      <Typography variant="inherit">
        {" "}
        {intl.formatMessage({
          id: "confirmation.modalTitle",
          defaultMessage: "Warning: Unsaved Changes",
        })}
      </Typography>
    );
  };

  const confirmationCategorySelectionContent = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        ></Grid>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmCategorySelection.instruction",
            defaultMessage:
              "You have unsaved changes. Are you sure you want to continue without saving your changes?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dailogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleContinue}
        >
          {intl.formatMessage({
            id: "confirmCategorySelection.continue",
            defaultMessage: "Continue",
          })}
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      title={confirmationCategorySelectionTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmationCategorySelectionContent()}
    </CustomDialog>
  );
};

export default React.memo(ConfirmCategorySelection);
