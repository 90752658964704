import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyle = makeStyles(() =>
  createStyles({
    actiondiv: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);
const ConfirmationActionVariation = (props: {
  handleClose: (checked?) => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyle();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmationActionVariation"),
  );

  const handleCloseModal = () => {
    dispatch(setModalState("ConfirmationActionVariation", false));
  };

  const confirmationActionVariationTitle = () => {
    return (
      <Typography variant="inherit">
        {" "}
        {intl.formatMessage({
          id: "confirmation.modalTitle",
          defaultMessage: "Warning: Unsaved Changes",
        })}
      </Typography>
    );
  };

  const confirmationActionVariationContent = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        ></Grid>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmRemoveProducts.instruction",
            defaultMessage:
              "Are you sure you want to proceed all products will be removed?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.actiondiv}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleCloseModal}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            props.handleClose(true);
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      title={confirmationActionVariationTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmationActionVariationContent()}
    </CustomDialog>
  );
};

export default ConfirmationActionVariation;
