import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      height: "80vh",
      minWidth: "60vw",
      overflow: "hidden",
    },
    dialogContent: {
      padding: "0 24px",
      overflow: "auto",
      height: "100%",
    },
    dialogAction: {
      justifyContent: "center",
    },
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 0, 3),
      width: "90%",
      height: "80%",
      maxWidth: "1240px",
      position: "relative",
    },
    checkBox: {
      padding: "5px 9px",
    },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
    dailogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

export default useStyles;
