import { GetProductListPayload } from "../product-list/ProductListTypes";
import {
  DELETE_VARIATION_GROUP,
  FETCH_VARIATION_ATTRIBUTES,
  FETCH_VARIATION_GROUP,
  FETCH_VARIATION_GROUP_DETAILS,
  FETCH_VARIATION_GROUP_PRODUCT_LIST,
  GetVariantAttributesAction,
  GetVariantGroupAction,
  GetVariantionGroupDetailAction,
  GetVariationAttributesPayload,
  GetVariationGroupDetailPayload,
  GetVariationGroupPayload,
  RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE,
  RESET_VARIATION_GROUP_IDS_STATE,
  RESET_VARIATION_GROUP_ID_ADD_STATE,
  RESET_VARIATION_GROUP_ID_DELETE_STATE,
  RESET_VARIATION_GROUP_PRODUCT_LIST_STATE,
  UPDATE_VARIATION_GROUP,
  UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG,
  UpdatePublishFlagVariationProductPayload,
  fetchAssignedAndUnAssignedAttributesPayload,
  FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES,
} from "./VariationManagementTypes";

export const updateVariationGroup = (payload) => {
  return {
    type: UPDATE_VARIATION_GROUP,
    payload,
  };
};

export const deleteVariationGroup = (payload) => {
  return {
    type: DELETE_VARIATION_GROUP,
    payload,
  };
};

export const getVariationAttributes = (
  payload: GetVariationAttributesPayload,
): GetVariantAttributesAction => {
  return {
    payload,
    type: FETCH_VARIATION_ATTRIBUTES,
  };
};

export const getVariationGroupId = (
  payload: GetVariationGroupPayload,
): GetVariantGroupAction => {
  return {
    payload,
    type: FETCH_VARIATION_GROUP,
  };
};

export const getVariationGroupDetails = (
  payload: GetVariationGroupDetailPayload,
): GetVariantionGroupDetailAction => {
  return {
    payload,
    type: FETCH_VARIATION_GROUP_DETAILS,
  };
};

export const resetVariationGroupIds = () => {
  return {
    type: RESET_VARIATION_GROUP_IDS_STATE,
  };
};

export const resetVariationDetailsByGroupId = () => {
  return {
    type: RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE,
  };
};

export const resetVariationAddState = () => {
  return {
    type: RESET_VARIATION_GROUP_ID_ADD_STATE,
  };
};

export const resetVariationDeleteState = () => {
  return {
    type: RESET_VARIATION_GROUP_ID_DELETE_STATE,
  };
};

export const loadVariationProductsList = (payload: GetProductListPayload) => {
  return {
    payload,
    type: FETCH_VARIATION_GROUP_PRODUCT_LIST.REQUEST,
  };
};

export const updatePublishFlagVariationProduct = (
  payload: UpdatePublishFlagVariationProductPayload,
) => {
  return {
    type: typeof UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG.REQUEST,
    payload,
  };
};

export const resetVariationGroupProductList = () => {
  return {
    type: RESET_VARIATION_GROUP_PRODUCT_LIST_STATE,
  };
};

export const fetchAssignedAndUnAssignedAttributeValues = (
  payload: fetchAssignedAndUnAssignedAttributesPayload,
) => {
  return {
    payload,
    type: FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES.REQUEST,
  };
};
