import { styled } from "@mui/material";

export const styleClasses = {
  overlay: "overlay",
  actiondiv: "actiondiv",
};

export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.actiondiv}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px",
  },
}));
