import { createSelector } from "reselect";
import AppState from "../AppState";
import {
  AttributesList,
  AutomationRulesList,
  BusinessRulesListDetails,
  jobsList,
  pendingJobsList,
} from "./BusinessRulesListTypes";

export const selectBusinessRulesListFetched = (
  state: AppState,
): BusinessRulesListDetails[] => {
  return state.businessRulesList.businessRulesList
    ? state.businessRulesList.businessRulesList
    : [];
};

export const selectIsBusinessRulesFetched = (state: AppState): boolean => {
  return state.businessRulesList.isFetched
    ? state.businessRulesList.isFetched
    : false;
};

export const selectAttributesListFetched = (
  state: AppState,
): AttributesList[] => {
  return state.businessRulesList.attributesList.length
    ? state.businessRulesList.attributesList
    : [];
};

export const selectIsDetailsSaved = (state: AppState): boolean => {
  return state.businessRulesList.isDetailsSaved;
};
export const selectIsFetchedById = (state: AppState): boolean => {
  return state.businessRulesList.isFetchedById;
};
export const selectBusinessRuledDetailsById = (
  state: AppState,
): BusinessRulesListDetails => {
  return state.businessRulesList.businessRulesDetailsObj;
};
export const selectIsBusinessRuleDeleted = (state: AppState): boolean => {
  return state.businessRulesList.isDeleted;
};

export const selectIsBusinessRuleIdDeleted = (state: AppState): boolean => {
  return state.businessRulesList.isRuleDeleted;
};

export const selectIsFetchedAttributes = (state: AppState): boolean => {
  return state.businessRulesList.isFetchedAttributes;
};
export const selectJobsListFetched = (state: AppState): jobsList[] => {
  return state.businessRulesList.jobsList.length
    ? state.businessRulesList.jobsList
    : [];
};
export const selectLogTimeList = createSelector(
  [selectJobsListFetched],
  (jobData) => {
    if (jobData.length) {
      const logsList = jobData.map((data) => data.logTime);
      const latestLogTime = logsList.reduce((latest, current) => {
        const currentLogTime = new Date(current);
        const latestLogTime = new Date(latest);

        return currentLogTime > latestLogTime
          ? new Date(current).toISOString()
          : new Date(latest).toISOString();
      }, logsList[0]);
      return latestLogTime;
    } else {
      return undefined;
    }
  },
);
export const selectIsAutomationRuleDeleted = (state: AppState): boolean => {
  return state.businessRulesList.isAutomationRuleDeleted;
};
export const selectIsJobListFetched = (state: AppState): boolean => {
  return state.businessRulesList.isJobListFetched;
};

export const selectAutomationRulesListFetched = (
  state: AppState,
): AutomationRulesList[] => {
  return state.businessRulesList.automationRulesList
    ? state.businessRulesList.automationRulesList
    : [];
};
export const selectIsAutomationRuleSaved = (state: AppState): boolean => {
  return state.businessRulesList.isAutomationRuleSaved;
};

export const selectIsFetchedByJobId = (state: AppState): boolean => {
  return state.businessRulesList.isFetchedByJobId;
};
export const selectAutomationRulesByJobId = (
  state: AppState,
): AutomationRulesList => {
  return state.businessRulesList.automationRuleDetailsObj;
};
export const selectAutomationRuleCategories = (state: AppState) => {
  return state.businessRulesList.categoryDetails;
};

export const selectFetchFailedAutomationRulesCategoryDetails = (
  state: AppState,
) => {
  return state.businessRulesList.fetchFailedAutomationRulesCategoryDetails;
};

export const selectSortingRuleType = (state: AppState): string => {
  return state.businessRulesList.sortingRuleType;
};

export const selectPendingJobsListFetched = (
  state: AppState,
): pendingJobsList[] => {
  return state.businessRulesList.pendingJobsList.length
    ? state.businessRulesList.pendingJobsList
    : [];
};

export const selectIsAutomationRulesJobFailed = (state: AppState): boolean => {
  return state.businessRulesList.isAutomationRulesJobFailed;
};

export const selectSystemGeneratedRules = (
  state: AppState,
): BusinessRulesListDetails[] => {
  return state?.businessRulesList?.systemGeneratedSortingRules ?? [];
};

export const selectIsAutomationRulesFetched = (state: AppState): boolean => {
  return state.businessRulesList.isAutomationRulesFecthed;
};

export const selectIsAnalytic = (state: AppState): boolean => {
  return state.businessRulesList.isAnalytic;
};
