import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import { components, palette } from "./overrides";

const classicTheme = createTheme({
  palette,
  components,
  spacing: 8,
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D9D9D9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#5CB65F",
  },
}));

export type TTheme = typeof classicTheme;
export { classicTheme, BorderLinearProgress };
