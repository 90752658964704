import {
  Store,
  FETCH_STORE_LIST_SUCCESS,
  SET_CURRENT_STORE,
  StoreListActionTypes,
  StoreInfoNode,
  StoreData,
  SET_CURRENT_LOCALE,
  FETCH_ALL_ATTRIBUTES,
  LoadAllAttributesResponseAction,
  LoadStoreListResponseAction,
  SetCurrentStoreAction,
  SetCurrentLocaleAction,
  RESET_STORE_STATE,
} from "./StoreListTypes";
import { AttributeData } from "../category/CategoryTypes";
import { GET_ATTRIBUTE_BY_ID } from "../attributes/AttributeTypes";

export interface StoreListState {
  store: Store[];
  storeInfo: StoreInfoNode;
  currentStoreId: string | null;
  currentLocaleCode: string | null;
  currentCurrencyCode: string;
  attributes: AttributeData[];
  nonEnumAttributes: AttributeData[];
  isStoresLoaded: boolean;
}

const initialState: StoreListState = {
  store: [],
  storeInfo: {},
  currentStoreId: null,
  currentLocaleCode: null,
  currentCurrencyCode: "",
  attributes: [],
  nonEnumAttributes: [],
  isStoresLoaded: false,
};

/**
 * To-Do : Create general parser to avoid duplicate code
 */
const parseStoreDefaults = (StoreList: StoreData[]): StoreInfoNode => {
  const storeInfo: StoreInfoNode = {};
  StoreList.forEach((store) => {
    storeInfo[store.storeId] = {
      currencyCodes: [],
      fulfillmentCenterIds: [],
      localeCodes: store.locales,
      priceLists: [],
      storeDefaults: {
        ...store.defaults,
        currencyCode: "",
      },
      localeToCurrency: {},
    };
  });

  return storeInfo;
};

export const StoreListReducer = (
  state = initialState,
  action: StoreListActionTypes,
): StoreListState => {
  switch (action.type) {
    case FETCH_STORE_LIST_SUCCESS:
      const storeListAction = action as LoadStoreListResponseAction;
      const store = storeListAction.payload.results.map((e) => {
        return {
          storeId: e.storeId,
          name: e.name || e.code,
          code: e.code,
        };
      });
      return {
        ...state,
        store: store,
        storeInfo: parseStoreDefaults(storeListAction.payload.results),
        isStoresLoaded: true,
      };
    case SET_CURRENT_STORE:
      const currentStoreAction = action as SetCurrentStoreAction;
      return {
        ...state,
        currentStoreId: currentStoreAction.payload.storeId,
      };
    case SET_CURRENT_LOCALE:
      const localeAction = action as SetCurrentLocaleAction;
      const storeId = state.currentStoreId ? state.currentStoreId : "";
      const storeInfo = state.storeInfo[storeId];
      let currencyCode = "";
      if (storeInfo) {
        currencyCode =
          storeInfo.localeToCurrency[localeAction.payload.localeCode];
        if (!currencyCode || currencyCode === "")
          currencyCode = storeInfo.storeDefaults.currencyCode;
      }
      return {
        ...state,
        currentLocaleCode: localeAction.payload.localeCode,
        currentCurrencyCode: currencyCode,
      };
    case FETCH_ALL_ATTRIBUTES.SUCCESS:
      const allAttributesAction = action as LoadAllAttributesResponseAction;
      return {
        ...state,
        attributes: allAttributesAction.payload.data.Attribute,
      };
    case GET_ATTRIBUTE_BY_ID.SUCCESS:
      const attributeByIdAction = action as LoadAllAttributesResponseAction;
      return {
        ...state,
        nonEnumAttributes: attributeByIdAction.payload.data.Attribute,
      };
    case RESET_STORE_STATE:
      return initialState;

    default:
      return state;
  }
};
