import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { styleClasses, StyledDiv } from "./RemoveProductsModalStyles";
import RemoveProductCategorySelection from "../remove-products-modal/RemoveProductCategorySelection";
import {
  DeleteProductByCategories,
  ResetProductDeletedState,
} from "../../store/remove-product-categories/RemoveProductCategoriesActions";
import {
  selectIsProductDeleted,
  selectProductCategories,
  selectIsProgressStopped,
} from "../../store/remove-product-categories/RemoveProductCategoriesSelector";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "../../store/category/CategorySelectors";
import { useIntl } from "react-intl";
import ConfirmRemoveProducts from "./ConfirmRemoveProducts";
import useClipboard from "../../hooks/useClipBoard";
import { CircularProgress } from "@material-ui/core";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";

interface CategoryList {
  name: string;
  categoryId: string;
}

const RemoveProductsModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([] as string[]);
  const [categoryList, setCategoryList] = useState([] as CategoryList[]);
  const productCategories = useSelector(selectProductCategories);
  const clipBoardHook = useClipboard();
  const productIds = clipBoardHook.getClipboardProductCodes();
  const isProductDeleted = useSelector(selectIsProductDeleted);
  const currentCategoryId = useSelector(selectCurrentCategory);
  const childCategories = useSelector(selectChildCategories);
  const topCategories = useSelector(selectTopCategories);
  const isProgressStopped = useSelector(selectIsProgressStopped);
  const [loader, setLoader] = useState(false);
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "RemoveProductsModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("RemoveProductsModal", false));
  };

  useEffect(() => {
    if (isProductDeleted) {
      handleClose();
      dispatch(ResetProductDeletedState());
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductDeleted]);

  useEffect(() => {
    setSelectedIds([]);
    const categoriesList: CategoryList[] = [];
    productCategories.forEach((prod) => {
      prod.categories.forEach((cat) =>
        categoriesList.push({
          name: `${cat.name} [${cat.code}]`,
          categoryId: cat.categoryId,
        }),
      );
    });
    const uniqueCat = [
      ...new Map(categoriesList.map((cat) => [cat["name"], cat])).values(),
    ];
    setCategoryList(uniqueCat);
    if (uniqueCat.find((cat) => cat.categoryId === currentCategoryId)) {
      setSelectedIds([currentCategoryId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategories]);

  const handleDelete = () => {
    const tcFilter = topCategories.filter((e) =>
      selectedIds.includes(e.categoryId),
    );
    const ccFilter = Object.entries(childCategories)
      .map((e) => {
        const search = e[1].childCategories.find((el) =>
          selectedIds.includes(el.categoryId),
        );
        if (search) {
          return search.categoryId;
        }
        return null;
      })
      .filter((e) => e !== null);
    setLoader(true);
    dispatch(
      DeleteProductByCategories({
        categoryIds: selectedIds,
        productIds: productIds,
        childrenCategories: ccFilter,
        topCategories: tcFilter,
      }),
    );
    dispatch(setModalState("ConfirmRemoveProducts", false));
  };

  const handleSelectAll = () => {
    const mapIds = {};
    selectedIds.forEach((id) => (mapIds[id] = true));
    const finalData = [...selectedIds];
    categoryList.forEach((cat) => {
      if (!mapIds[cat.categoryId]) {
        finalData.push(cat.categoryId);
      }
    });
    setSelectedIds(finalData);
  };

  const handleUnselectAll = () => {
    setSelectedIds([]);
  };

  const removeProductsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "removeProductsModal.modalTitle",
          defaultMessage: "Remove from following categories",
        })}
      </Typography>
    );
  };

  const removeProductsModalContent = () => {
    return (
      <>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <span
            style={{
              fontSize: "0.875rem",
              margin: "8px",
              color: "#D50000",
              cursor: "pointer",
            }}
            onClick={handleSelectAll}
          >
            {intl.formatMessage({
              id: "general.selectAllLabel",
              defaultMessage: "Select All",
            })}
          </span>
          /
          <span
            style={{
              fontSize: "0.875rem",
              margin: "8px",
              color: "#0C61A0",
              cursor: "pointer",
            }}
            onClick={handleUnselectAll}
          >
            {intl.formatMessage({
              id: "general.unSelectAllLabel",
              defaultMessage: "Unselect All",
            })}
          </span>
        </Grid>
        <Typography variant="subHeader">
          {intl.formatMessage({
            id: "removeProductsModal.instruction",
            defaultMessage:
              "Selected products will be removed from the following checked categories:",
          })}
        </Typography>
        <RemoveProductCategorySelection
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          categoryList={categoryList}
        />
      </>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.actiondiv}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          justifyContent="center"
          onClick={() => dispatch(setModalState("ConfirmRemoveProducts", true))}
        >
          {intl.formatMessage({
            id: "general.save",
            defaultMessage: "Save",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <>
      {loader && !isProgressStopped && (
        <StyledDiv className={styleClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      <CustomDialog
        open={isModalOpen}
        onClose={() => handleClose()}
        title={removeProductsModalTitle()}
        fullWidth
        maxWidth={"md"}
        actions={dialogActions()}
      >
        <>
          {removeProductsModalContent()}
          <ConfirmRemoveProducts
            handleDelete={handleDelete}
            handleClose={handleClose}
          />
        </>
      </CustomDialog>
    </>
  );
};

export default RemoveProductsModal;
