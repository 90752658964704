import { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonComponent from "../common/ButtonComponent";
import { setModalState } from "../../store/modal/ModalActions";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import {
  selectFacetsToAdd,
  selectCategoryFacets,
} from "../../store/facets/FacetSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { getFacetDisplayName } from "../../utils/FacetUtils";
import { addFacetToCategory } from "../../store/facets/FacetActions";
import { useIntl } from "react-intl";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import CustomDialog from "../common/DialogContainer";
import CheckboxComponent from "../common/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formControlLabel: {
      "& .MuiTypography-root": {
        fontSize: 14,
      },
    },
    checkBox: {
      padding: "5px 9px",
    },
    dailogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

export const AddFacetModalId = "AddFacetModal";

const AddFacetModal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [selectedFacetIds, setSelectedFacetIds] = useState<string[]>([]);

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, AddFacetModalId),
  );
  const categoryId = useSelector(selectCurrentCategory);
  const storeId = useSelector(selectCurrentStoreId);
  const facetsToAdd = useSelector(selectFacetsToAdd);
  const existingFacets = useSelector(selectCategoryFacets);
  const localeCode = useSelector(selectCurrentLocale);
  const dispatch = useDispatch();
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const handleClose = () => {
    dispatch(setModalState(AddFacetModalId, false));
  };
  const saveHandler = () => {
    const payload = {
      facetIds: selectedFacetIds,
      storeId: storeId ? storeId : "",
      categoryId: categoryId ? categoryId : "",
    };
    dispatch(addFacetToCategory(payload));
    setSelectedFacetIds([]);
    handleClose();
  };

  const handleChange = (facetId: string) => {
    const copiedIds = selectedFacetIds.slice();
    const index = copiedIds.findIndex((id) => id === facetId);
    if (index >= 0) copiedIds.splice(index, 1);
    else copiedIds.push(facetId);
    setSelectedFacetIds(copiedIds);
  };

  const existingFacetIds = existingFacets.map(
    (existingFacet) => existingFacet.facetId,
  );

  const facetsList = facetsToAdd.filter(
    (facet) => !existingFacetIds.includes(facet.facetId),
  );

  const addFacetModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "addFacetModal.header",
          defaultMessage: "Add facets to category",
        })}
      </Typography>
    );
  };

  const addFacetModalContent = () => {
    return (
      <>
        {facetsList.length === 0 && (
          <div>
            {intl.formatMessage({
              id: "addFacetModal.defaultFacetMessage",
              defaultMessage:
                "No facets available to be added for the category",
            })}
          </div>
        )}
        {facetsList.length > 0 && (
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: "addFacetModal.subtitle",
              defaultMessage: "Select facets to be added to current category.",
            })}
          </Typography>
        )}
        {facetsList.map((facet) => (
          <FormGroup row key={facet.facetId}>
            <FormControlLabel
              key={facet.facetId}
              control={
                <CheckboxComponent
                  checkboxId="AddFacetModal"
                  classes={classes}
                  checked={selectedFacetIds.includes(facet.facetId)}
                  handleChange={handleChange}
                  handleParam={facet.facetId}
                />
              }
              label={getFacetDisplayName(facet, localeCode, defaultLocaleId)}
              className={classes.formControlLabel}
            />
          </FormGroup>
        ))}
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dailogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "addFacetModal.cancelButton",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          justifyContent="center"
          onClick={saveHandler}
          disabled={selectedFacetIds.length === 0}
        >
          {intl.formatMessage({
            id: "addFacetModal.addButton",
            defaultMessage: "Add",
          })}
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={addFacetModalTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
      fullWidth
    >
      {addFacetModalContent()}
    </CustomDialog>
  );
};

export default AddFacetModal;
