import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    btnGroup: {
      textAlign: "end",
      margin: "20px",
      width: "100%",
    },
  }),
);

const ConfirmRemoveProducts = (props: {
  handleDelete: () => void;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmRemoveProducts"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmRemoveProducts", false));
  };

  const confirmRemoveProductsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmRemoveProducts.header",
          defaultMessage: "Remove Products",
        })}
      </Typography>
    );
  };

  const confirmRemoveProductsModalContent = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmRemoveProducts.instruction",
            defaultMessage: "Are you sure you want to remove the products?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            props.handleDelete();
            props.handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmRemoveProductsModalTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmRemoveProductsModalContent()}
    </CustomDialog>
  );
};

export default ConfirmRemoveProducts;
