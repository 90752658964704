import React, { Key } from "react";
import {
  Typography,
  ListSubheader,
  Box,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { MenuItem, Skeleton } from "@mui/material";
import Select from "@material-ui/core/Select";
import { useIntl } from "react-intl";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { BusinessRulesListDetails } from "../../store/product-list/ProductListTypes";
import { Store, StoreInfo } from "../../store/store-list/StoreListTypes";
import {
  ColorData,
  ItemManagementSFCCData,
  Product,
} from "../../store/product/ProductTypes";
import { AttributeData } from "../../store/category/CategoryTypes";
import { getAttributeDisplayName } from "../../utils/AttributeUtils";
import { useSelector } from "react-redux";
import LanguageIcon from "@material-ui/icons/Language";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { LoadVariationAtrributeValues } from "../../store/variation-management/VariationManagementTypes";
import {
  selectCurrentStoreId,
  selectStoreListIds,
} from "store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { classicTheme } from "../../styles/themes/classic";
import { styled } from "@mui/system";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#FFFFFF",
    color:
      theme?.palette?.primary?.main ??
      classicTheme.palette.selectedMenuItem.main,
    fontWeight: "bold",
  },
  "&:hover": {
    backgroundColor: classicTheme.palette.hoveredMenuItem.main,
  },
}));

interface DropdownMenuProps {
  menuId: string;
  classes?: ClassNameMap;
  open?: boolean;
  value?: any;
  closeHandler?: () => void;
  openHandler?: () => void;
  changeHandler?: (event: any) => void;
  isSortingRulesListLoaded?: boolean;
  isAllSortingRulesFetched?: boolean;
  select?: {
    minWidth: number;
    maxHeight: string;
    margin: string;
    border: string;
  };
  muiListSubheader?: {
    backgroundColor: string;
    fontWeight: string;
    padding: string;
    zIndex: number;
  };
  muiMenuItem?: {
    padding: string;
  };
  skeleton?: {
    display: string;
    flexDirection: string;
    rowGap: string;
    padding: string;
  };
  sortedSystemGeneratedRulesList?: BusinessRulesListDetails[];
  sortingRulesList?: BusinessRulesListDetails[];
  periodTypes?: {
    label: string;
    value: string;
  }[];
  options?: {
    label: string;
    value: string;
  }[];
  handleSelect?: (e: any) => void;
  selectedLocale?: string;
  localeCodes?: string[];
  setValue?: (value: React.SetStateAction<number>) => void;
  catalogList?: {
    name: string;
    id: string;
  }[];
  sortingRules?: {
    name: {
      id: string;
      defaultMessage: string;
    };
    value: string;
  }[];
  label?: string;
  menuOptions?: Key[];
  stores?: Store[];
  attribute?: {
    values: {
      name: string;
      value: string;
      translations: {
        localeCode: string;
        name: string;
      }[];
      isAssigned: boolean;
    }[];
    attributeId: string;
    name: string;
    translations: {
      localeCode: string;
      name: string;
    }[];
    colors?: ColorData[] | undefined;
  };
  filters?: {
    [x: string]: string;
  };
  filterBy?: (attributeId: string, value: string | unknown) => void;
  isLeadingColor?: boolean;
  product?: Product;
  storeId?: string;
  storeInfo?: StoreInfo;
  storeListIds?: Store[];
  SKUAttributes?: string[];
  productAttributes?: AttributeData[];
  localeCode?: string;
  defaultLocaleId?: string;
  name?: string;
  data?: ItemManagementSFCCData | { [key: string]: string };
  SortingCriteria?: any;
  selectedAttributes?: {
    [key: string]: string;
  };
  handleStoreChange?: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => void;
  assignedAttributeValues?: LoadVariationAtrributeValues[];
  unAssignedAttributeValues?: LoadVariationAtrributeValues[];
  handleAttributeValueChange?: (attributeId: string, value: string) => void;
  children?: JSX.Element;
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const {
    menuId,
    data,
    SortingCriteria,
    selectedAttributes,
    assignedAttributeValues,
    unAssignedAttributeValues,
    classes,
    SKUAttributes,
    storeId,
    productAttributes,
    defaultLocaleId,
    localeCode,
    open,
    sortingRules,
    menuOptions,
    stores,
    attribute,
    filters,
    filterBy,
    isLeadingColor,
    value,
    options,
    handleSelect,
    selectedLocale,
    localeCodes,
    catalogList,
    label,
    name,
    select,
    muiListSubheader,
    muiMenuItem,
    skeleton,
    periodTypes,
    setValue,
    product,
    handleAttributeValueChange,
    handleStoreChange,
    closeHandler,
    openHandler,
    changeHandler,
    sortedSystemGeneratedRulesList,
    sortingRulesList,
    isSortingRulesListLoaded,
    isAllSortingRulesFetched,
    storeInfo,
    children,
  } = props;
  const intl = useIntl();
  const useStyles = makeStyles((theme) =>
    createStyles({
      select: {
        minWidth: 200,
        maxHeight: "32px",
        margin: "10px",
        border: "black",
      },
      selectMenu: {
        maxHeight: "480px",
        marginLeft: "-0.5rem",
      },
      productViewSelectMenu: {
        marginTop: "-0.75rem",
      },
      findProductSelectMenu: {
        maxHeight: "360px",
      },
      sortingToolSelectMenu: {
        marginTop: "-0.5rem",
        maxHeight: "500px",
      },
      sortingToolSelect: {
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent",
          border: "none",
          borderRadius: 10,
        },
      },
      productViewSelect: {
        width: 125,
        maxHeight: "32px",
        marginRight: 10,
        border: "black",
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent",
          border: "none",
          borderRadius: 10,
        },
      },
      sideBarTitle: {
        color: classicTheme.palette.sideBarTitle.main,
        display: "block",
      },
      jobsListDataGridSelect: {
        minWidth: 200,
        maxHeight: "32px",
        margin: "10px",
        border: "black",
      },
      productAlertsRoot: { margin: 1, width: "120px", height: "40px" },
      unifiedSortingRuleModalForm: {
        width: 324,
        marginBottom: 2,
        "&  .MuiInputLabel-formControl": {
          width: "158px",
          height: "23px",
          fontSize: "16px",
          lineHeight: "22.4px",
          fontWeight: 400,
          fontFamily: "Helvetica",
          paddingLeft: "1px",
        },
        "&  .MuiInputLabel-formControl.Mui-focused": {
          color: classicTheme.palette.focusColor.main,
          height: "23px",
          fontSize: "16px",
          lineHeight: "22.4px",
          fontWeight: 400,
          fontFamily: "Helvetica",
        },
        "& .MuiInputBase-root.Mui-focused fieldset": {
          borderColor: classicTheme.palette.focusColor.main,
        },
        "& .MuiInputBase-root input": {
          fontSize: "16px",
          lineHeight: "22.4px",
          fontWeight: 400,
          fontFamily: "Helvetica",
        },
        "& .MuiInputBase-root.Mui-focused": {
          border: "1.5px !important",
        },
      },
      unifiedSortingRuleModalMenu: {
        marginBottom: 18,
        "&& .Mui-selected": {
          backgroundColor:
            theme?.palette?.unifiedSortingRuleModalMenu?.main ??
            classicTheme?.palette?.primary?.main,
          opacity: 0.5,
        },
      },
      unifiedSortingRuleModalMenuItem: {
        "&:hover": {
          backgroundColor:
            theme?.palette?.unifiedSortingRuleModalMenuItem?.main ??
            classicTheme?.palette?.unifiedSortingRuleModalMenuItem?.main,
          opacity: 0.5,
        },
      },
    }),
  );
  const menuclass = useStyles();
  const storeListIds = useSelector(selectStoreListIds);
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId);

  switch (menuId) {
    case "ProductListViewSelect":
      if (classes && closeHandler && openHandler && changeHandler && value)
        return (
          <Select
            className={menuclass.productViewSelect}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open ?? false}
            onClose={closeHandler}
            onOpen={openHandler}
            value={value}
            variant="outlined"
            onChange={(e) => changeHandler(e)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                className: menuclass.productViewSelectMenu,
              },
            }}
          >
            <StyledMenuItem className={classes.menuItem} value={2}>
              <Typography variant="inherit">
                {intl.formatMessage(
                  {
                    id: "productListViewSelect.columnsLabel",
                    defaultMessage: "{number} Columns",
                  },
                  {
                    number: 2,
                  },
                )}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem className={classes.menuItem} value={3}>
              <Typography variant="inherit">
                {intl.formatMessage(
                  {
                    id: "productListViewSelect.columnsLabel",
                    defaultMessage: "{number} Columns",
                  },
                  {
                    number: 3,
                  },
                )}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem className={classes.menuItem} value={4}>
              <Typography variant="inherit">
                {intl.formatMessage(
                  {
                    id: "productListViewSelect.columnsLabel",
                    defaultMessage: "{number} Columns",
                  },
                  {
                    number: 4,
                  },
                )}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem className={classes.menuItem} value={5}>
              <Typography variant="inherit">
                {intl.formatMessage(
                  {
                    id: "productListViewSelect.columnsLabel",
                    defaultMessage: "{number} Columns",
                  },
                  {
                    number: 5,
                  },
                )}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem className={classes.menuItem} value={6}>
              <Typography variant="inherit">
                {intl.formatMessage(
                  {
                    id: "productListViewSelect.columnsLabel",
                    defaultMessage: "{number} Columns",
                  },
                  {
                    number: 6,
                  },
                )}
              </Typography>
            </StyledMenuItem>
          </Select>
        );
      break;
    case "SortingTools":
      if (
        classes &&
        changeHandler &&
        select &&
        muiListSubheader &&
        sortedSystemGeneratedRulesList &&
        muiMenuItem &&
        sortingRulesList &&
        skeleton
      )
        return (
          <Select
            className={menuclass.sortingToolSelect}
            style={select}
            value={value}
            onChange={changeHandler}
            variant="outlined"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                className: menuclass.sortingToolSelectMenu,
              },
            }}
          >
            {isSortingRulesListLoaded && isAllSortingRulesFetched ? (
              [
                <ListSubheader style={muiListSubheader}>
                  {intl.formatMessage({
                    id: "sortingTools.sytemGeneratedLabel",
                    defaultMessage: "System Managed",
                  })}
                </ListSubheader>,
                sortedSystemGeneratedRulesList.map((data, index) => (
                  <StyledMenuItem
                    sx={muiMenuItem}
                    key={index}
                    value={data.ruleId}
                  >
                    {data.name}
                  </StyledMenuItem>
                )),
                <ListSubheader style={muiListSubheader}>
                  {intl.formatMessage({
                    id: "sortingTools.userGeneratedLabel",
                    defaultMessage: "User Generated",
                  })}
                </ListSubheader>,
                sortingRulesList.map((data, index) => (
                  <StyledMenuItem
                    sx={muiMenuItem}
                    key={index}
                    value={data.ruleId}
                  >
                    {data.name}
                  </StyledMenuItem>
                )),
              ]
            ) : (
              <Box sx={skeleton}>
                {[...Array(6)].map(() => (
                  <Skeleton variant="rectangular" />
                ))}
              </Box>
            )}
          </Select>
        );
      break;
    case "SortingToolsAnalytic":
      if (changeHandler && periodTypes)
        return (
          <Select
            className={menuclass.select}
            style={select}
            value={value}
            onChange={changeHandler}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {periodTypes.map((data) => (
              <StyledMenuItem key={data.value} value={data.value}>
                {data.label}
              </StyledMenuItem>
            ))}
          </Select>
        );
      break;
    case "JobsListDataGridUI":
      if (options && value)
        return (
          <Select
            className={menuclass.jobsListDataGridSelect}
            value={value}
            disabled
            inputProps={{ "aria-label": "Without label" }}
          >
            {options.map((item, index) => {
              return (
                <StyledMenuItem value={item.value}>{item.label}</StyledMenuItem>
              );
            })}
          </Select>
        );
      break;
    case "UnifiedSortingRuleModal":
      if (changeHandler && SortingCriteria)
        return (
          <FormControl
            className={menuclass.unifiedSortingRuleModalForm}
            size="small"
            variant="outlined"
          >
            <InputLabel id="demo-select-small-label">
              {intl.formatMessage({
                id: "unifiedSortingRuleModal.sortingCriteria",
                defaultMessage: " Sorting Criteria",
              })}
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={value}
              label="Sorting Criteria"
              onChange={(e) => changeHandler(e)}
              variant="outlined"
              className={menuclass.unifiedSortingRuleModalMenu}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <StyledMenuItem
                value={SortingCriteria.Attribute}
                className={menuclass.unifiedSortingRuleModalMenuItem}
              >
                {intl.formatMessage({
                  id: "unifiedSortingRuleModal.attributeRule",
                  defaultMessage: " Attributes Sorting",
                })}
              </StyledMenuItem>
              <StyledMenuItem
                value={SortingCriteria.Metric}
                className={menuclass.unifiedSortingRuleModalMenuItem}
              >
                {intl.formatMessage({
                  id: "unifiedSortingRuleModal.weightedRule",
                  defaultMessage: "Metrics Sorting",
                })}
              </StyledMenuItem>
              <StyledMenuItem
                value={SortingCriteria.Multi}
                className={menuclass.unifiedSortingRuleModalMenuItem}
              >
                {intl.formatMessage({
                  id: "unifiedSortingRuleModal.multiCriteriaRule",
                  defaultMessage: "Multiple Criteria Sorting",
                })}
              </StyledMenuItem>
            </Select>
          </FormControl>
        );
      break;
    case "CoreSizesModal":
      if (classes && handleSelect && value)
        return (
          <Select
            autoWidth={true}
            defaultValue={""}
            id={"select-product-type"}
            labelId="select-product-type-label"
            className={classes.select}
            onChange={handleSelect}
            value={value}
          >
            <StyledMenuItem key={`product-type-tops`} value={"tops"}>
              tops
            </StyledMenuItem>
            <StyledMenuItem key={`product-type-bottoms`} value={"bottoms"}>
              bottoms
            </StyledMenuItem>
          </Select>
        );
      break;
    case "EditFacetBucketModal":
      if (classes && changeHandler && localeCodes)
        return (
          <Select
            labelId="facet-bucket-locale-label"
            id="facet-bucket-locale-select"
            value={selectedLocale ? selectedLocale : localeCodes[0]}
            onChange={changeHandler}
          >
            {localeCodes.map((localeCode) => (
              <StyledMenuItem value={localeCode} key={localeCode}>
                {localeCode}
              </StyledMenuItem>
            ))}
          </Select>
        );
      break;
    case "FacetTypeSelect":
      if (
        classes &&
        value &&
        setValue &&
        closeHandler &&
        openHandler &&
        setValue
      )
        return (
          <Select
            labelId="facet-view-label"
            id="facet-view"
            open={open}
            onClose={closeHandler}
            onOpen={openHandler}
            value={value}
            onChange={(e) => {
              const newValue = e.target.value as number;
              setValue(newValue);
            }}
          >
            <StyledMenuItem value={0}>
              {intl.formatMessage({
                id: "facetTypeSelect.verticalLabel",
                defaultMessage: "Vertical",
              })}
            </StyledMenuItem>
            <StyledMenuItem value={1}>
              {intl.formatMessage({
                id: "facetTypeSelect.horizontalLabel",
                defaultMessage: "Horizontal",
              })}
            </StyledMenuItem>
          </Select>
        );
      break;
    case "FindProductsCatalogSelection":
      if (changeHandler && catalogList)
        return (
          <Select
            labelId="catalog-list-label"
            id="catalog-list"
            value={value}
            onChange={changeHandler}
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",

                horizontal: "left",
              },

              transformOrigin: {
                vertical: "top",

                horizontal: "left",
              },

              getContentAnchorEl: null,

              PaperProps: {
                className: menuclass.findProductSelectMenu,
              },
            }}
          >
            <StyledMenuItem value="">
              <em>
                {intl.formatMessage({
                  id: "findProductsCatalogSelection.actionLabel",
                  defaultMessage: "Select a catalog",
                })}
              </em>
            </StyledMenuItem>
            {catalogList
              ? catalogList.map((catalog, idx) => {
                  return (
                    <StyledMenuItem key={`catalog_${idx}`} value={catalog.id}>
                      {catalog.name}
                    </StyledMenuItem>
                  );
                })
              : null}
          </Select>
        );
      break;
    case "FindProductsModal":
      if (classes && value && sortingRules)
        return (
          <Select
            autoWidth={true}
            defaultValue={sortingRules[0].value}
            id={"find-product-select-sorting"}
            labelId="find-product-select-sorting-label"
            style={{
              marginLeft: "0.5rem",
              minWidth: "100px",
            }}
            value={value}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",

                horizontal: "left",
              },

              transformOrigin: {
                vertical: "top",

                horizontal: "left",
              },

              getContentAnchorEl: null,

              PaperProps: {
                className: menuclass.findProductSelectMenu,
              },
            }}
          >
            {sortingRules.map((rule, idx) => {
              return (
                <StyledMenuItem
                  key={`find-products-sorting-${rule.value}-${idx}`}
                  value={rule.value}
                >
                  {intl.formatMessage(rule.name)}
                </StyledMenuItem>
              );
            })}
          </Select>
        );
      break;
    case "PreferencesModal":
      if (classes && value && changeHandler)
        return (
          <Select
            labelId="locale-list-label"
            id="locale-list"
            value={value}
            onChange={changeHandler}
          >
            <StyledMenuItem value="">
              <em>
                {intl.formatMessage({
                  id: "preferencesModal.localeSelectionActionLabel",
                  defaultMessage: "Select a locale",
                })}
              </em>
            </StyledMenuItem>
            {["en-US", "it-IT", "es"].map((localeCode, idx) => {
              return (
                <StyledMenuItem key={`locale_${idx}`} value={localeCode}>
                  {localeCode}
                </StyledMenuItem>
              );
            })}
          </Select>
        );
      break;
    case "ProductAlertScheduleModal":
      if (changeHandler && menuOptions)
        return (
          <FormControl
            variant="outlined"
            className={menuclass.productAlertsRoot}
            size="small"
          >
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
              labelId={`${label}-label`}
              value={value || ""}
              label={label}
              variant="outlined"
              onChange={changeHandler}
            >
              {menuOptions.map((menuItem: string | Number) => (
                <StyledMenuItem
                  style={{ fontSize: "12px" }}
                  key={menuItem as any}
                  value={menuItem.toString()}
                >
                  {menuItem}
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        );
      break;
    case "ItemManagementSFCC":
      if (classes && data && changeHandler)
        return (
          <Select
            labelId="select-label"
            name={data["SKU"]}
            value={
              data["publishedStore"] === null
                ? "Use Default"
                : data["publishedStore"] === true
                  ? "Publish"
                  : "Unpublish"
            }
            disableUnderline
            className={classes.selectInput}
            onChange={changeHandler}
          >
            <StyledMenuItem value="Use Default">Use Default</StyledMenuItem>
            <StyledMenuItem value="Publish">Publish</StyledMenuItem>
            <StyledMenuItem value="Unpublish">Unpublish</StyledMenuItem>
          </Select>
        );
      break;
    case "ItemManagementToolbar":
      if (classes && stores && changeHandler)
        return (
          <Select
            labelId="select-label"
            name="store"
            value={value}
            onChange={changeHandler}
            disableUnderline
          >
            {stores.map((store) => (
              <StyledMenuItem value={store.storeId} key={store.storeId}>
                {store.name}
              </StyledMenuItem>
            ))}
          </Select>
        );
      break;
    case "LeadingImageModal":
      if (attribute && filters && filterBy && product) {
        return (
          <FormControl variant="outlined">
            <React.Fragment key={attribute.attributeId}>
              <InputLabel id={`filter-by-${attribute.attributeId}`}>
                {attribute.attributeId}
              </InputLabel>
              <Select
                labelId={`filter-by-${attribute.attributeId}`}
                label={attribute.name}
                defaultValue={""}
                value={filters[attribute.attributeId] || "None"}
                onChange={(evt) =>
                  filterBy(attribute.attributeId, evt?.target?.value ?? "")
                }
                variant="outlined"
                disabled={attribute.attributeId === "color" && isLeadingColor}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <StyledMenuItem value="None">None</StyledMenuItem>
                {attribute.values
                  .filter(
                    (value) =>
                      product?.colors.find(
                        (color) => color.colorId === value.value,
                      )?.isPublished,
                  )
                  .map((value) => (
                    <StyledMenuItem key={value.value} value={value.value}>
                      {value.name}
                    </StyledMenuItem>
                  ))}
              </Select>
            </React.Fragment>
          </FormControl>
        );
      }
      break;
    case "CatalogSelection":
      if (changeHandler && catalogList)
        return (
          <Select
            labelId="catalog-list-label"
            id="catalog-list"
            value={currentCatalogId}
            onChange={changeHandler}
            variant="standard"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                className: menuclass.selectMenu,
              },
            }}
          >
            <StyledMenuItem value="">
              <Typography variant="inherit">
                {intl.formatMessage({
                  id: "catalogSelection.actionLabel",
                  defaultMessage: "Select a catalog",
                })}
              </Typography>
            </StyledMenuItem>
            {currentStoreId !== "" && catalogList
              ? catalogList.map((catalog, idx) => {
                  return (
                    <StyledMenuItem key={`catalog_${idx}`} value={catalog?.id}>
                      <Typography variant="inherit">{catalog?.name}</Typography>
                    </StyledMenuItem>
                  );
                })
              : null}
          </Select>
        );
      break;
    case "LanguageSelection":
      if (value && changeHandler && storeInfo)
        return (
          <Select
            labelId="locale-list-label"
            id="locale-list"
            value={value}
            onChange={changeHandler}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                className: menuclass.selectMenu,
              },
            }}
          >
            {storeInfo &&
              storeInfo.localeCodes.map((locale, idx) => {
                return (
                  <StyledMenuItem
                    key={`locale_${idx}`}
                    value={locale.localeCode}
                  >
                    <Typography variant="inherit">
                      {locale.localeName}
                    </Typography>
                  </StyledMenuItem>
                );
              })}
          </Select>
        );
      break;
    case "StoreSelection":
      if (classes && handleStoreChange && storeListIds) {
        return (
          <FormControl className={classes.formControl}>
            <InputLabel
              id={`${menuId}-label`}
              className={menuclass.sideBarTitle}
            >
              {" "}
              {intl.formatMessage({
                id: "storeSelection.label",
                defaultMessage: "Store Selection",
              })}
            </InputLabel>
            <Select
              labelId={`${menuId}-label`}
              id="store-list"
              value={currentStoreId}
              onChange={handleStoreChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  className: menuclass.selectMenu,
                },
              }}
            >
              <StyledMenuItem value="">
                <Typography className={menuclass.sideBarTitle}>
                  {intl.formatMessage({
                    id: "storeSelection.actionLabel",
                    defaultMessage: "Select a store",
                  })}
                </Typography>
              </StyledMenuItem>
              {storeListIds.map((e, idx) => {
                return (
                  <StyledMenuItem
                    key={`${e}_${idx}`}
                    value={e.storeId}
                    className={menuclass.sideBarTitle}
                  >
                    {e.name}
                  </StyledMenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }
      break;
    case "MultipleStoreModal":
      if (name && value && changeHandler)
        return (
          <Select
            labelId="select-label"
            name={name}
            value={value}
            onChange={changeHandler}
          >
            <StyledMenuItem value="No Change">No Change</StyledMenuItem>
            {name === "Default" && (
              <StyledMenuItem value="Use Default">Use Default</StyledMenuItem>
            )}
            <StyledMenuItem value="Publish">Publish</StyledMenuItem>
            <StyledMenuItem value="Unpublish">Unpublish</StyledMenuItem>
          </Select>
        );
      break;
    case "AttributesOverlayLabel":
      if (
        classes &&
        productAttributes &&
        value &&
        localeCode &&
        defaultLocaleId
      )
        return (
          <Select
            className={classes.select}
            value={value}
            onChange={changeHandler}
          >
            {productAttributes.map((attr) => (
              <StyledMenuItem
                key={attr.attributeId}
                value={attr.attributeId}
                classes={{ root: classes.menuItemRoot }}
              >
                {attr.isLocalized && (
                  <LanguageIcon className={classes.selectOptionIcon} />
                )}
                {attr.isStoreSpecific && (
                  <StorefrontIcon className={classes.selectOptionIcon} />
                )}
                {getAttributeDisplayName(attr, localeCode, defaultLocaleId)}
              </StyledMenuItem>
            ))}
            <StyledMenuItem
              key="newAttribute"
              value="newAttribute"
              classes={{ root: classes.menuItemRoot }}
            >
              {intl.formatMessage({
                id: "overlayTabs.addAttributeLabel",
                defaultMessage: "Add...",
              })}
            </StyledMenuItem>
          </Select>
        );
      break;
    case "InventoryOverlayLabel":
      if (classes && changeHandler && SKUAttributes)
        return (
          <Select
            className={classes.select}
            value={value}
            onChange={changeHandler}
          >
            {SKUAttributes.map((attribute) => (
              <StyledMenuItem
                key={attribute}
                value={attribute}
                classes={{ root: classes.menuItemRoot }}
              >
                {attribute}
              </StyledMenuItem>
            ))}
          </Select>
        );
      break;
    case "CatalogFilter":
      if (value && changeHandler && storeId && catalogList)
        return (
          <Select
            labelId="catalog-list-label"
            id="catalog-list"
            value={value}
            onChange={changeHandler}
          >
            <StyledMenuItem value="">
              <em>Select a catalog</em>
            </StyledMenuItem>
            {storeId !== "" &&
              catalogList.map((catalog, idx) => {
                return (
                  <StyledMenuItem key={`catalog_${idx}`} value={catalog.id}>
                    {catalog.name}
                  </StyledMenuItem>
                );
              })}
          </Select>
        );
      break;
    case "StoreFilter":
      if (storeId && changeHandler && storeListIds)
        return (
          <Select
            labelId="store-list-label"
            id="store-list"
            value={storeId}
            onChange={changeHandler}
          >
            <StyledMenuItem value="">
              <em>Select a store</em>
            </StyledMenuItem>
            {storeListIds.map((store, idx) => {
              return (
                <StyledMenuItem
                  key={`${store.storeId}_${idx}`}
                  value={store.storeId}
                >
                  {store.name}
                </StyledMenuItem>
              );
            })}
          </Select>
        );
      break;
    case "VariationForm":
      if (
        selectedAttributes &&
        attribute &&
        assignedAttributeValues &&
        unAssignedAttributeValues &&
        handleAttributeValueChange
      )
        return (
          <Select
            id={attribute.attributeId}
            value={selectedAttributes[attribute.attributeId] || "-None-"}
            onChange={(event) =>
              handleAttributeValueChange(
                attribute.attributeId,
                event.target.value as string,
              )
            }
          >
            <StyledMenuItem value="-None-"> -None- </StyledMenuItem>
            {assignedAttributeValues.length > 0 &&
              assignedAttributeValues.map((attr) => [
                attr.attrId === attribute.attributeId && (
                  <ListSubheader>
                    {intl.formatMessage({
                      id: "variationForm.selectAssignedAttribute",
                      defaultMessage: "assigned",
                    })}
                  </ListSubheader>
                ),
                attr.attrId === attribute.attributeId &&
                  attr.attributes.map((attrVal) => (
                    <StyledMenuItem
                      key={attrVal.attrValue}
                      value={attrVal.attrValue}
                    >
                      {attrVal.attrName}
                    </StyledMenuItem>
                  )),
              ])}
            {unAssignedAttributeValues.length > 0 &&
              unAssignedAttributeValues.map((attr) => [
                attr.attrId === attribute.attributeId && (
                  <ListSubheader>
                    {intl.formatMessage({
                      id: "variationForm.selectUnAssignedAttribute",
                      defaultMessage: "unassigned",
                    })}
                  </ListSubheader>
                ),
                attr.attrId === attribute.attributeId &&
                  attr.attributes.map((attrVal) => (
                    <StyledMenuItem
                      key={attrVal.attrValue}
                      value={attrVal.attrValue}
                    >
                      {attrVal.attrName}
                    </StyledMenuItem>
                  )),
              ])}
          </Select>
        );
      break;
    default:
      if (children)
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={changeHandler}
            className={menuclass.select}
          >
            {children}
          </Select>
        );
  }
  return <></>;
};

export default React.memo(DropdownMenu);
