import { MenuItem, Typography } from "@material-ui/core";
import CustomDialog from "../../common/DialogContainer";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../../store/modal/ModalActions";
import { CallerAccountInfoObject } from "../../../utils/AppConfigUtils";
import { loadAllAccountDetails } from "../../../store/app-config/AppConfigActions";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../../store/modal/ModalSelectors";
import AppState from "../../../store/AppState";
import {
  LoadStoreList,
  resetStoreState,
} from "../../../store/store-list/StoreListActions";
import { resetProductList } from "../../../store/product-list/ProductListActions";
import {
  closeAllCategorySubscriptionsAndResetState,
  setCategoryExpandState,
} from "../../../store/category/CategoryActions";
import { resetProducts } from "../../../store/product/ProductActions";
import { selectCurrentCatalogId } from "../../../store/catalog/CatalogSelectors";
import { useHistory, useLocation } from "react-router";
import { Stack } from "@mui/material";
import {
  selectCallerAccounts,
  selectCallerAccountId,
} from "../../../store/app-config/AppConfigSelectors";
import { setCallerAccount } from "../../../utils/SmartMerchandiserAPI";
import {
  loadAutomationRulesList,
  loadUnifiedSortingRulesList,
} from "../../../store/businessRules-list/BusinessRulesListActions";
import { BUSINESS_RULES_NAVIGATION_TABS } from "../../../utils/Constants";
import { useStyles } from "./CallerAccountModalStyled";

const CallerAccountsModal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentCatalogId = useSelector(selectCurrentCatalogId) ?? "";
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "AccountModalId"),
  );
  const selectedSwitchAccounts = useSelector((state: AppState) =>
    selectModalVariable(state, "AccountModalId", "switchAccounts"),
  );
  const availableCallerAccounts: CallerAccountInfoObject =
    useSelector(selectCallerAccounts);
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const accountId = useSelector(selectCallerAccountId);
  const selectedAccount = availableCallerAccounts.accounts.find(
    (acc) => acc.accountId === accountId,
  );
  const isAccountActive = (
    selectedAccountName: string | undefined,
    accountName: string | undefined,
  ): boolean => {
    return selectedAccountName === accountName;
  };

  const handleClose = () => {
    dispatch(setModalState("AccountModalId", false));
  };

  const handleAccountSelection = (accountId: string) => {
    setCallerAccount(accountId);
    dispatch(loadAllAccountDetails({ accountId }));
    dispatch(setModalState("AccountModalId", false));
    if (selectedSwitchAccounts === true) {
      dispatch(resetStoreState());
      dispatch(resetProductList());
      dispatch(resetProducts());
      dispatch(closeAllCategorySubscriptionsAndResetState(currentCatalogId));
      dispatch(setCategoryExpandState());
      history.replace(`${url}?accountId=${accountId}`);
      const path = `?accountId=${accountId}`;
      localStorage.setItem("catalogPath", path);
      if (
        url ===
        `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES}`
      ) {
        dispatch(loadUnifiedSortingRulesList());
      } else if (
        url ===
        `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES}`
      ) {
        dispatch(loadAutomationRulesList());
      } else {
        dispatch(LoadStoreList());
      }
    }
  };

  const accountModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "accountModalTitle.header",
          defaultMessage: "Choose an Account",
        })}
      </Typography>
    );
  };

  const accountModalContent = () => {
    return (
      <Stack spacing={2} className={classes.stack}>
        {availableCallerAccounts?.accounts?.map((account) => (
          <MenuItem
            onClick={() => {
              handleAccountSelection(account.accountId);
            }}
            key={account.accountId}
            value={account.accountId}
            className={`${
              isAccountActive(
                selectedAccount?.configs?.account?.name,
                account.configs?.account?.name,
              )
                ? `${classes.activeAccountContent} ${classes.accountActiveContent}`
                : classes.accountContent
            }`}
          >
            <Typography variant="subtitle1" className={classes.accountText}>
              {account.configs?.account?.name}
            </Typography>
          </MenuItem>
        ))}
      </Stack>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      title={accountModalTitle()}
      onClose={handleClose}
      maxWidth={"sm"}
    >
      {accountModalContent()}
    </CustomDialog>
  );
};

export default CallerAccountsModal;
