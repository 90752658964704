export const LOAD_UNIFIED_SORTING_RULES_LIST =
  "LOAD_UNIFIED_SORTING_RULES_LIST";

export const FETCH_UNIFIED_SORTING_RULES_LIST = {
  REQUEST: "FETCH_UNIFIED_SORTING_RULES_LIST_REQUEST",
  SUCCESS: "FETCH_UNIFIED_SORTING_RULES_LIST_SUCCESS",
  FAILURE: "FETCH_UNIFIED_SORTING_RULES_LIST_FAILURE",
};

export const SAVE_UNIFIED_BUSINESS_RULES = "SAVE_UNIFIED_BUSINESS_RULES";
export const SAVE_UNIFIED_BUSINESS_RULES_DETAILS = {
  REQUEST: "SAVE_UNIFIED_BUSINESS_RULES_DETAILS_REQUEST",
  SUCCESS: "SAVE_UNIFIED_BUSINESS_RULES_DETAILS_SUCCESS",
  FAILURE: "SAVE_UNIFIED_BUSINESS_RULES_DETAILS_FAILURE",
};

export const LOAD_ATTRIBUTES_LIST = "LOAD_ATTRIBUTES_LIST";

export const FETCH_ATTRIBUTES_LIST = {
  REQUEST: "FETCH_ATTRIBUTES_LIST_REQUEST",
  SUCCESS: "FETCH_ATTRIBUTES_LIST_SUCCESS",
  FAILURE: "FETCH_ATTRIBUTES_LIST_FAILURE",
};

export const GET_BUSINESS_RULES_DETAILS_BY_ID =
  "GET_BUSINESS_RULES_DETAILS_BY_ID";

export const FETCH_BUSINESS_RULES_DETAILS_BY_ID = {
  REQUEST: "FETCH_BUSINESS_RULES_DETAILS_BY_ID_REQUEST",
  SUCCESS: "FETCH_BUSINESS_RULES_DETAILS_BY_ID_SUCCESS",
  FAILURE: "FETCH_BUSINESS_RULES_DETAILS_BY_ID_FAILURE",
};

export const UPDATE_BUSINESS_RULES = "UPDATE_BUSINESS_RULES";
export const UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID = {
  REQUEST: "UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID_REQUEST",
  SUCCESS: "UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID_SUCCESS",
  FAILURE: "UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID_FAILURE",
};

export const DELETE_BUSINESS_RULE = "DELETE_BUSINESS_RULE";
export const DELETE_BUSINESS_RULE_BY_RULE_ID = {
  REQUEST: "DELETE_BUSINESS_RULE_BY_RULE_ID_REQUEST",
  SUCCESS: "DELETE_BUSINESS_RULE_BY_RULE_ID_SUCCESS",
  DONE: "DELETE_BUSINESS_RULE_BY_RULE_ID_DONE",
  FAILURE: "DELETE_BUSINESS_RULE_BY_RULE_ID_FAILURE",
};

export const LOAD_JOBS_LIST = "LOAD_JOBS_LIST";
export const FETCH_JOBS_LIST = {
  REQUEST: "FETCH_JOBS_LIST_REQUEST",
  SUCCESS: "FETCH_JOBS_LIST_SUCCESS",
  FAILURE: "FETCH_JOBS_LIST_FAILURE",
};
export const LOAD_AUTOMATION_LIST = "LOAD_AUTOMATION_LIST";
export const FETCH_AUTOMATION_LIST = {
  REQUEST: "FETCH_AUTOMATION_LIST_REQUEST",
  SUCCESS: "FETCH_AUTOMATION_LIST_SUCCESS",
  FAILURE: "FETCH_AUTOMATION_LIST_FAILURE",
};

export const DELETE_AUTOMATION_RULE = "DELETE_AUTOMATION_RULE";
export const DELETE_AUTOMATION_RULE_BY_JOB_ID = {
  REQUEST: "DELETE_AUTOMATION_RULE_BY_JOB_ID_REQUEST",
  SUCCESS: "DELETE_AUTOMATION_RULE_BY_JOB_ID_SUCCESS",
  FAILURE: "DELETE_AUTOMATION_RULE_BY_JOB_ID_FAILURE",
};
export const SAVE_AUTOMATION_RULES = "SAVE_AUTOMATION_RULES";
export const SAVE_AUTOMATION_RULES_DETAILS = {
  REQUEST: "SAVE_AUTOMATION_RULES_DETAILS_REQUEST",
  SUCCESS: "SAVE_AUTOMATION_RULES_DETAILS_SUCCESS",
  FAILURE: "SAVE_AUTOMATION_RULES_DETAILS_FAILURE",
};
export const GET_AUTOMATION_RULES_DETAILS_BY_ID =
  "GET_AUTOMATION_RULES_DETAILS_BY_ID";

export const FETCH_AUTOMATION_RULES_DETAILS_BY_ID = {
  REQUEST: "FETCH_AUTOMATION_RULES_DETAILS_BY_ID_REQUEST",
  SUCCESS: "FETCH_AUTOMATION_RULES_DETAILS_BY_ID_SUCCESS",
  FAILURE: "FETCH_AUTOMATION_RULES_DETAILS_BY_ID_FAILURE",
};
export const UPDATE_AUTOMATION_RULES = "UPDATE_AUTOMATION_RULES";
export const UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID = {
  REQUEST: "UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID_REQUEST",
  SUCCESS: "UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID_SUCCESS",
  FAILURE: "UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID_FAILURE",
};
export const LOAD_BUSINESS_RULES_SERVICE_URL =
  "LOAD_BUSINESS_RULES_SERVICE_URL";

export const FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS =
  "FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS";
export const FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID = {
  REQUEST: "FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID_REQUEST",
  SUCCESS: "FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID_SUCCESS",
  FAILURE: "FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID_FAILURE",
};

export const LOAD_PLATFORM_INDEPENDENT_URL = "LOAD_PLATFORM_INDEPENDENT_URL";

export const RESET_SELECTED_CATEGORIES_DATA = "RESET_SELECTED_CATEGORIES_DATA";

export const SET_SORTING_RULE_TYPE = "SET_SORTING_RULE_TYPE";

export const FETCH_PENDING_JOBS_LIST = {
  REQUEST: "FETCH_PENDING_JOBS_LIST_REQUEST",
  SUCCESS: "FETCH_PENDING_JOBS_LIST_SUCCESS",
  FAILURE: "FETCH_PENDING_JOBS_LIST_FAILURE",
};

export const START_DATE_IN_PAST = "START_DATE_IN_PAST";

export const RESET_AUTOMATION_JOB_CREATION_STATE =
  "RESET_AUTOMATION_JOB_CREATION_STATE";

export const RESET_JOBS_LIST = "RESET_JOBS_LIST";

export const LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST =
  "LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST";

export const FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST = {
  REQUEST: "FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST_REQUEST",
  SUCCESS: "FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST_SUCCESS",
  FAILURE: "FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST_FAILURE",
};

export const RESET_BUSINESS_RULES_DETAILS = "RESET_BUSINESS_RULES_DETAILS";

export const SET_IS_ANALYTIC = "SET_IS_ANALYTIC";

export const RESET_AUTOMATION_JOB = "RESET_AUTOMATION_JOB";

export interface SystemGeneratedRulesListPayload {
  results: BusinessRulesListDetails[];
}
export interface LoadSystemGeneratedRulesResponseAction {
  type: typeof FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST;
  total: number;
  payload: SystemGeneratedRulesListPayload;
}

export interface LoadSystemGeneratedSortingRulesListAction {
  type: typeof LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST;
}
export interface loadUnifiedSortingRuleslistAction {
  type: typeof LOAD_UNIFIED_SORTING_RULES_LIST;
}

export interface loadAttributeslistAction {
  type: typeof LOAD_ATTRIBUTES_LIST;
}
export interface SelectType {
  label: string;
  value: string;

  // only applicable if ruleType == "WS" || "MCS"
  type?: string;
  isAnalyticMetric?: boolean;
}
export interface MappedValue {
  label: string | undefined;
  values: SelectType[] | undefined;
}

export interface FormulaNode {
  metric: string;
  order: string;
  weight: number;
}

export interface SortingPointsLUTNode {
  attr: string;
  value: string;
  points: number;
}
export interface MultiCriteriaNode {
  ruleType: string;
  strategy?: string;
  sortingPointsLUT: SortingPointsLUTNode[];
  dateRange?: string;
  timezone?: string;
  formula: FormulaNode[];
}

export interface BusinessRulesListDetails {
  ruleType: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  ruleId: string;

  // only applicable if ruleType == "WS": {
  dateRange?: string;
  timezone?: string;
  formula: FormulaNode[];
  // }

  // only applicable if ruleType == "AS": {
  strategy?: string;
  sortingPointsLUT: SortingPointsLUTNode[];
  // }

  // only applicable if ruleType == "MCS": {
  criteria: MultiCriteriaNode[];
}

export interface AttributeValues {
  valueId: string;
  label: string;
}
export interface AttributesList {
  attributeId: string;
  label: string;
  values: AttributeValues[];
}
export interface saveUnifiedBusinessRulesPayload {
  total: number;
  results: BusinessRulesListDetails[];

  // }
}
export interface attributesListPayload {
  attributes: AttributesList[];
}

export interface jobListScope {
  storeId: string;
  catalogId: string;
  categoryId: string;
  localeCode: string;
  currencyCode: string;
  ruleId: string;
  dateRange?: string;
  timezone?: string;
}
export interface jobListParameters {
  scope: jobListScope[];
}
export interface AutomationRulesList {
  jobId?: string;
  name: string;
  description?: string;
  jobType?: string;
  parameters: jobListParameters;
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
  startAt: string;
  endAt?: string;
  timezone: string;
  retries: string;
  repeats: string;
  repeatsEvery?: number;
  repeatDays?: string[];
  ruleId?: string;
}
export interface automationRulesListPayload {
  total: number;
  results: AutomationRulesList[];
}
export interface saveUnifiedBusinessRulesAction {
  payload: saveUnifiedBusinessRulesPayload;
  type: typeof SAVE_UNIFIED_BUSINESS_RULES;
}

export interface loadAttributesListAction {
  payload: attributesListPayload;
  type: typeof FETCH_ATTRIBUTES_LIST;
}

export interface loadJobsListAction {
  payload?: string;
  type: typeof LOAD_JOBS_LIST;
}
export interface loadJobsListActionPayload {
  payload: jobsListPayload;
  type: typeof FETCH_JOBS_LIST;
}

export interface JobDetails {
  jobExecutionId: string;
  logTime: string;
  logType: string;
  name: string;
  startTime: string;
  endTime: string;
  progress: number;
  description?: string;
}

export interface jobListScope {
  storeId: string;
  catalogId: string;
  categoryId: string;
  localeCode: string;
  currencyCode: string;
  ruleId: string;
  dateRange?: string;
  timezone?: string;
}
export interface jobListParameters {
  scope: jobListScope[];
}
export interface jobsList {
  logTime: string;
  jobExecutionId: string;
  logType: string;
  jobId?: string;
  jobType?: string;
  name?: string;
  totalSteps?: number;
  message?: string;
  step?: number;
}

export interface jobsListPayload {
  total: number; // the total number of rules
  results: jobsList[];
}

export interface JobListPayload {
  jobId?: string;
  jobExecutionId: string;
  status: string;
  name: string;
  startTime: string;
  endTime: string;
  progress?: number;
  logTime: string;
  logType: string;
  description: string;
}

export interface Children {
  //id: string;
  name: string;
  parentId: string;
  children: Children[];
}
export interface TreeStructure {
  id: string;
  name: string;
  children: Children[];
}

export interface Translations {
  name: string;
  localeCode: string;
}
export interface CategoryData {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  translations?: Translations[];
  storeId: string;
}
export interface LoadAutomationRulesListAction {
  type: typeof LOAD_AUTOMATION_LIST;
}
export interface loadAutomationRulesListPayload {
  payload: automationRulesListPayload;
  type: typeof FETCH_AUTOMATION_LIST;
}

export interface saveAutomationRuleAction {
  payload: AutomationRulesList;
  type: typeof SAVE_AUTOMATION_RULES;
}

export interface loadPendingJobsListActionPayload {
  payload: pendingJobsListPayload;
  type: typeof FETCH_PENDING_JOBS_LIST;
}

export interface pendingJobsListPayload {
  total: number;
  results: pendingJobsList[];
}

export interface pendingJobsList {
  jobId: string;
  jobType: string;
  name: string;
  nextRunAt: string;
  description: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  startAt: string;
  endAt?: string;
  timezone: string;
  retries: string;
  repeats: string;
  repeatsEvery?: number;
  repeatDays?: string[];
  ruleId?: string;
  parameters: jobListParameters;
}

export interface DeleteSchedulePayload {
  jobId: string;
  name: string;
}

export interface DeleteScheduleAction {
  type: typeof DELETE_AUTOMATION_RULE;
  payload: DeleteSchedulePayload;
}
