import React from "react";
import { FieldArray } from "formik";
import { StyledDiv, classes } from "./Styles";
import TextfieldWrapper from "../../../Textfield/TextFieldWrapper";
import SelectWrapper from "../../../Select/SelectWrapper";
import { Delete } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { MetricsList, sortByOrder } from "../../../../../utils/Constants";
import ButtonComponent from "../../../../common/ButtonComponent";

const MetricsForm = ({ formik, handleRemoveSingleMetric }) => {
  const intl = useIntl();
  return (
    <div>
      <StyledDiv className={classes.metricFieldsParent}>
        <div>
          <Grid>
            <Typography
              style={{
                marginBottom: 10,
                fontWeight: 600,
                marginTop: 10,
              }}
            >
              {intl.formatMessage({
                id: "unifiedSortingRuleModal.metrics",
                defaultMessage: "Metrics",
              })}
            </Typography>
            <FieldArray name="metrics">
              {({ push, remove }) => (
                <div>
                  {formik.values?.metrics?.map((metric, index) => (
                    <div>
                      <StyledDiv
                        key={index}
                        className={classes.metricSectionParent}
                      >
                        <SelectWrapper
                          name={`metrics.${index}.metric`}
                          label="Sort By"
                          options={MetricsList}
                          currentModalValue=""
                          defaultValue={""}
                          variant="outlined"
                          size="small"
                        ></SelectWrapper>
                        <TextfieldWrapper
                          type="text"
                          label="Weighted %"
                          name={`metrics.${index}.weight`}
                          variant="outlined"
                          size="small"
                        />
                        <div
                          style={{
                            marginRight: 10,
                          }}
                        ></div>

                        <SelectWrapper
                          name={`metrics.${index}.order`}
                          label="Direction"
                          options={sortByOrder}
                          currentModalValue=""
                          defaultValue={""}
                          variant="outlined"
                          size="small"
                        ></SelectWrapper>
                        <StyledDiv
                          className={classes.removeBtnParent}
                          onClick={() => {
                            if (formik.values.metrics.length === 1) {
                              //setMetricsFlag(false);
                              handleRemoveSingleMetric();
                              formik.setFieldValue(`metrics.${index}`, {
                                metric: "",
                                weight: null,
                                order: "",
                              });
                              formik.setFieldTouched(`metrics.${index}`, false);
                              formik.setFieldTouched("points", false);
                            } else {
                              remove(index);
                            }
                          }}
                        >
                          <Delete color="error" />
                        </StyledDiv>
                      </StyledDiv>
                      <StyledDiv className={classes.border}></StyledDiv>
                    </div>
                  ))}
                  <StyledDiv className={classes.btnDiv}>
                    <ButtonComponent
                      color="tertiary"
                      variant="contained"
                      fullWidth={true}
                      justifyContent="center"
                      padding="15px"
                      onClick={() =>
                        push({
                          metric: "",
                          weight: "",
                          order: "",
                        })
                      }
                    >
                      {intl.formatMessage({
                        id: "unifiedSortingRuleModal.addMetrics",
                        defaultMessage: "Add Metrics",
                      })}
                    </ButtonComponent>
                  </StyledDiv>
                </div>
              )}
            </FieldArray>
          </Grid>
        </div>
      </StyledDiv>
    </div>
  );
};

export default MetricsForm;
