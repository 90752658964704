import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../common/ButtonComponent";
import Link from "@material-ui/core/Link";
import ErrorIcon from "@material-ui/icons/Error";
import { useIntl, defineMessage, defineMessages } from "react-intl";
import Tooltip from "../common/ToolTip";
import TextFieldComponent from "../common/TextField";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { closeFindProductsSubscriptionsAndResetState } from "../../store/find-products/FindProductsActions";
import {
  selectIsSubmitted,
  selectIsSubmitting,
} from "../../store/find-products/FindProductSelectors";
import FindProductCatalogSelection from "./FindProductsCatalogSelection";
import FindProductsCategorySelection from "./FindProductsCategorySelection";
import FindProductsProductList from "./FindProductsProductList";
import {
  useStyles,
  StyledDiv,
  StyledFormControl,
  styleClasses,
} from "./FindProductsModalStyles";
import { UpdateProductData } from "../../store/find-products/FindProductsTypes";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { isAddingRemovingProductIdsList } from "../../store/product-list/ProductListSelectors";
import CustomDialog from "../common/DialogContainer";
import { useTheme } from "@mui/material";
import DropdownMenu from "../common/DropdownMenu";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";

const text = defineMessages({
  modalTitle: {
    id: "findProductModal.modalTitle",
    defaultMessage: "Find Products",
  },
  saveAction: {
    id: "findProductModal.saveActionLabel",
    defaultMessage: "Save",
  },
  closeAction: {
    id: "findProductModal.closeActionLabel",
    defaultMessage: "Close",
  },
  selectAll: {
    id: "findProductModal.selectAllLabel",
    defaultMessage: "Select All",
  },
  deselectAll: {
    id: "findProductModal.deselectAllLabel",
    defaultMessage: "Deselect All",
  },
  reset: {
    id: "findProductModal.resetLabel",
    defaultMessage: "Reset",
  },
  sortBy: {
    id: "findProductModal.sortByLabel",
    defaultMessage: "Sort By:",
  },
  search: {
    id: "findProductModal.searchLabel",
    defaultMessage: "Search...",
  },
});

const sortingRules = [
  {
    name: defineMessage({
      id: "findProductModal.sortingRulesByNameText",
      defaultMessage: "Name",
    }),
    value: "name",
  },
  {
    name: defineMessage({
      id: "findProductModal.sortingRulesByPartNumberText",
      defaultMessage: "Part Number",
    }),
    value: "part",
  },
];

interface Props {
  categoryId: string;
}

const FindProductsModal: React.FC<Props> = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [selected, setSelected] = useState([] as string[]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortingValue, setSortingValue] = useState(sortingRules[0].value);
  const storeId = useSelector(selectCurrentStoreId) ?? "";
  const localeId = useSelector(selectCurrentLocale) ?? "";

  const [productsToBeAdded, setProductsToBeAdded] = useState(
    {} as UpdateProductData,
  );
  const [productsToBeRemoved, setProductsToBeRemoved] = useState(
    {} as UpdateProductData,
  );

  const advancedFindEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "advancedFindEnabled"),
    ) === "true";

  const [currentModalSelectedCategory, setCurrentModalSelectedCategory] =
    useState("");

  const isSubmitting = useSelector((state: AppState) =>
    selectIsSubmitting(state),
  );
  const isSubmitted = useSelector((state: AppState) =>
    selectIsSubmitted(state),
  );

  useEffect(() => {
    if (isSubmitted && !isSubmitting) {
      setProductsToBeAdded({});
      setProductsToBeRemoved({});
    }
  }, [isSubmitted, isSubmitting]);

  const existingAddingRemoveProductIdsOperation = useSelector(
    isAddingRemovingProductIdsList,
  );

  const [isErrorOnSearch, setIsErrorOnSearch] = useState(false);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "FindProductsModal"),
  );
  const clearSearchForm = () => {
    setSearchTerm("");
    setIsErrorOnSearch(false);
  };

  const handleClose = () => {
    dispatch(closeFindProductsSubscriptionsAndResetState(selectedCatalog));
    setSelected([]);
    setSelectedCatalog("");
    clearSearchForm();
    setSortingValue(sortingRules[0].value);
    setProductsToBeAdded({});
    setProductsToBeRemoved({});
    setCurrentModalSelectedCategory("");
    dispatch(setModalState("FindProductsModal", false));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchTerm.length > 0) {
      // Validate the search term
      setIsErrorOnSearch(false);
      //need update how new structure expecting
      // const payload = {
      //   storeId,
      //   localeId,
      //   searchTerm: searchTerm.toLowerCase(),
      //   orderBy: sortingValue,
      // };
      // dispatch(searchProductsForFindProducts({ ...payload }));
    } else {
      setIsErrorOnSearch(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      handleSearchSubmit(); // Call search function directly
    }
  };
  const showCategoryList = useMemo(() => {
    return selectedCatalog !== "";
  }, [selectedCatalog]);

  const findProductsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage(text.modalTitle)}
      </Typography>
    );
  };

  const findProductsModalContent = () => {
    return (
      <StyledDiv>
        <FindProductCatalogSelection
          localeId={localeId}
          storeId={storeId}
          setCatalog={setSelectedCatalog}
          catalog={selectedCatalog}
          setCurrentModalSelectedCategory={setCurrentModalSelectedCategory}
        />
        <StyledDiv className={styleClasses.main}>
          <StyledDiv
            className={
              isErrorOnSearch ? styleClasses.searchError : styleClasses.search
            }
          >
            {isErrorOnSearch ? (
              <StyledDiv className={styleClasses.errorIcon}>
                <Tooltip tooltipTitle={"Please enter 3 or more characters"}>
                  <ErrorIcon />
                </Tooltip>
              </StyledDiv>
            ) : null}
            {advancedFindEnabled && (
              <TextFieldComponent
                textFieldId="SearchInput"
                value={searchTerm}
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
                placeholderText={intl.formatMessage(text.search)}
                disabled={!searchTerm.trim()}
                classes={{
                  root: styleClasses.inputRoot, // Matches the styled classes
                  input: styleClasses.inputInput,
                }}
              />
            )}
          </StyledDiv>
          <StyledDiv
            style={{ display: "none" }}
            className={styleClasses.sorting}
          >
            <Link style={classes.sortingLink}>
              {intl.formatMessage(text.selectAll)}
            </Link>
            <Link style={classes.sortingLink}>
              {intl.formatMessage(text.deselectAll)}
            </Link>
            <Link style={classes.sortingLink}>
              {intl.formatMessage(text.reset)}
            </Link>
          </StyledDiv>
          <div style={{ display: "none" }}>
            <StyledFormControl className={styleClasses.formControl}>
              <label id={"find-product-select-sorting-label"}>
                {" "}
                {intl.formatMessage(text.sortBy)}
                {": "}
              </label>
              <DropdownMenu
                menuId="FindProductsModal"
                value={sortingValue}
                sortingRules={sortingRules}
              />
            </StyledFormControl>
          </div>
        </StyledDiv>

        {showCategoryList && (
          <StyledDiv className={styleClasses.catagoryProductList}>
            <StyledDiv className={styleClasses.leftPanel}>
              <FindProductsCategorySelection
                storeId={storeId}
                localeId={localeId}
                catalog={selectedCatalog}
                checked={true}
                setSelected={setSelected}
                selected={selected}
                orderBy={sortingValue}
                clearSearchForm={clearSearchForm}
                setCurrentModalSelectedCategory={
                  setCurrentModalSelectedCategory
                }
              />
            </StyledDiv>
            <StyledDiv className={styleClasses.productList}>
              <FindProductsProductList
                productsToBeAdded={productsToBeAdded}
                setProductsToBeAdded={setProductsToBeAdded}
                productsToBeRemoved={productsToBeRemoved}
                setProductsToBeRemoved={setProductsToBeRemoved}
                currentModalSelectedCategory={currentModalSelectedCategory}
                existingAddingRemoveProductIdsOperation={
                  existingAddingRemoveProductIdsOperation
                }
              />
            </StyledDiv>
          </StyledDiv>
        )}
        {/* </StyledDiv> */}
      </StyledDiv>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.btnClose}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {intl.formatMessage(text.closeAction)}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={findProductsModalTitle()}
      fullWidth
      maxWidth={"lg"}
      actions={dialogActions()}
    >
      {findProductsModalContent()}
    </CustomDialog>
  );
};

export default FindProductsModal;
