import { styled, Theme } from "@mui/material/styles";

export const classes = {
  dialogChildClass: "dialogChildClass",
  saveTextClass: "saveTextClass",
  dialogBlurClass: "dialogBlurClass",
  formWrapper: "formWrapper",
  automationSection: "automationSection",
  spanSection: "spanSection",
  datePicker: "datePicker",
  radioGroup: "radioGroup",
  label: "label",
  categoriesSection: "categoriesSection",
  addBackground: "addBackground",
  listSpace: "listSpace",
  categoryBtnGrp: "categoryBtnGrp",
  btnGroup: "btnGroup",
  submitBtn: "submitBtn",
};
export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${classes.dialogChildClass}`]: {
    position: "absolute",
    top: "50%",
    left: 50,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`&.${classes.dialogBlurClass}`]: {
    filter: "blur(2px)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`&.${classes.formWrapper}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`&.${classes.automationSection}`]: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  [`&.${classes.spanSection}`]: {
    alignItems: "center",
    display: "flex",
    marginTop: "20px",
  },
  [`&.${classes.formWrapper}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`&.${classes.radioGroup}`]: {
    marginTop: 5,
  },
  [`&.${classes.categoriesSection}`]: {
    border: "1px solid " + theme.palette.borderColor.main,
    padding: 20,
    maxHeight: "50vh",
    overflow: "auto",
    width: 400,
    minHeight: "30vh",
    marginBottom: 10,
  },
  [`&.${classes.addBackground}`]: {
    background: "lightblue",
    padding: 3,
    cursor: "pointer",
  },
  [`&.${classes.listSpace}`]: {
    padding: 3,
    cursor: "pointer",
  },
  [`&.${classes.categoryBtnGrp}`]: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px",
  },
  [`&.${classes.btnGroup}`]: {
    display: "flex",
    margin: "20px",
    justifyContent: "flex-end",
  },
  // [`&.${classes.submitBtn}`]: {
  //   marginRight: 20,
  // },
}));
export const StyledSpan = styled("span")(({ theme }) => ({
  [`&.${classes.saveTextClass}`]: {
    marginRight: 10,
    fontSize: 20,
    fontWeight: 600,
  },
}));
export const styles = (theme: Theme) => {
  return {
    datePicker: {
      width: 324,
      "& .MuiFormControl-root.MuiInputBase-root.MuiInputAdornment-root": {
        width: "24px",
        height: "24px",
      },
      "& .MuiFormLabel-root": {
        width: "158px",
        height: "23px",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 400,
        fontFamily: "Helvetica",
        paddingLeft: "1px",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.focusColor.main,
        height: "23px",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
      "& .MuiInputBase-root.Mui-focused fieldset": {
        borderColor: theme.palette.focusColor.main,
      },
      "& .MuiInputBase-root input": {
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 400,
        fontFamily: "Helvetica",
        height: "50.4px !important",
      },
      "& .MuiInputBase-root.Mui-focused": {
        border: "1.5px !important",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "1px !important",
        marginTop: "0px !important",
      },
    },
  };
};

export const Label = styled("label")(({ theme }) => ({
  [`&.${classes.label}`]: {
    marginRight: 20,
  },
}));
