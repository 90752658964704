import { SET_MODAL_STATE, setModalStateAction, Variables } from "./ModalTypes";

export const setModalState = (
  modalId: string,
  isOpen: boolean,
  variables?: Variables,
): setModalStateAction => {
  return {
    type: SET_MODAL_STATE,
    payload: {
      modalId,
      isOpen,
      ...variables,
    },
  };
};
