import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setIsAnalytic } from "../../../../src/store/businessRules-list/BusinessRulesListActions";
import { styled } from "@mui/material/styles";

const classes = {
  textField: "textField",
};
const Text = styled(TextField)(({ theme }) => ({
  [`&.${classes.textField}`]: {
    maxHeight: 50,
    marginRight: 10,
    width: 324,
    backgroundColor: theme.palette.background.paper,
    "& .MuiFormLabel-root": {
      width: "158px",
      height: "23px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.focusColor.main,
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiInputBase-root.Mui-focused fieldset": {
      borderColor: theme.palette.focusColor.main,
    },
    "& .MuiInputBase-root input": {
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      height: "50.4px !important",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: 1.5,
    },
    "& .MuiInputBase-root fieldset": {
      height: 56.4,
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiSelect-root": {
      height: 24.4,
      paddingTop: 16,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.dangerColor.main,
    },
    "& .MuiInputBase-root.Mui-error fieldset": {
      borderColor: theme.palette.dangerColor.main,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 400,
      fontFamily: "Helvetica !important",
      color: theme.palette.dangerColor.main,
      marginBottom: "-20px !important",
    },
  },
}));

const SelectWrapper = ({
  name,
  options,
  currentModalValue,
  defaultValue,
  variant,
  size,
  ...otherProps
}) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (defaultValue !== "") {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, name, setFieldValue]);

  const handleSortingPeriod = (value) => {
    setFieldValue("dateRange", "");
    options.forEach((sortRule) => {
      if (sortRule.value === value) {
        dispatch(setIsAnalytic(sortRule.isAnalyticMetric));
        if (
          sortRule.isAnalyticMetric === true &&
          (sortRule.type === "WS" || sortRule.type === "MCS")
        ) {
          setFieldValue("showDateRange", true);
        } else {
          setFieldValue("showDateRange", false);
        }
        return;
      }
    });
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    if (name === "appliedRule") {
      handleSortingPeriod(value);
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };
  return (
    <Text
      size={size ?? "small"}
      disabled={!options.length ? true : false}
      className={classes.textField}
      variant={variant ?? "outlined"}
      error={meta.error && meta.touched ? true : false}
      helperText={meta.touched ? meta.error : ""}
      {...configSelect}
    >
      {options?.map((item) => {
        if (currentModalValue !== "") {
          return item.values.map((currentItem) => {
            return (
              <MenuItem key={currentItem.value} value={currentItem.value}>
                {intl.formatMessage({
                  id: `${currentItem.label}`,
                  defaultMessage: `${currentItem.label}`,
                })}
              </MenuItem>
            );
          });
        } else {
          return (
            <MenuItem key={item.value} value={item.value}>
              {intl.formatMessage({
                id: `${item.label}`,
                defaultMessage: `${item.label}`,
              })}
            </MenuItem>
          );
        }
      })}
    </Text>
  );
};

export default SelectWrapper;
