import React, { SyntheticEvent } from "react";
import { FieldArray } from "formik";
import { StyledDiv, classes, useStyles } from "./Styles";
import { Autocomplete, useTheme } from "@mui/material";
import TextfieldWrapper from "../../../Textfield/TextFieldWrapper";
import SelectWrapper from "../../../Select/SelectWrapper";
import { Delete } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { scoringFormulaTypes } from "../../../../../utils/Constants";
import ButtonComponent from "../../../../common/ButtonComponent";

const AttributesForm = ({
  formik,
  handleAttributesValues,
  attributesList,
  handleMappedValues,
  handleRemoveSingleAttribute,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <StyledDiv className={classes.attributeFieldsParent}>
      <StyledDiv className={classes.attributeScoringFormula}>
        <SelectWrapper
          defaultValue={""}
          name="scoringFormula"
          label="Scoring Formula"
          options={scoringFormulaTypes}
          currentModalValue=""
          variant="outlined"
          size="small"
        />
      </StyledDiv>
      <div>
        <Grid>
          <Typography
            style={{
              marginBottom: 10,
              fontWeight: 600,
            }}
          >
            {intl.formatMessage({
              id: "unifiedSortingRuleModal.attributes",
              defaultMessage: "Attributes",
            })}
          </Typography>
          <FieldArray name="attributes">
            {({ push, remove }) => (
              <div>
                {formik.values.attributes.map((attribute, index) => (
                  <div>
                    <StyledDiv
                      key={index}
                      className={classes.attributeSectionParent}
                    >
                      <Autocomplete
                        value={attribute.attr}
                        size="small"
                        sx={styles.attributeSearch}
                        onChange={(_: SyntheticEvent, newValue) => {
                          formik.setFieldValue(
                            `attributes.${index}.attr`,
                            newValue,
                          );
                          handleAttributesValues(newValue);
                        }}
                        options={attributesList?.map(
                          (attribute) => attribute.label,
                        )}
                        renderInput={(params) => (
                          <TextfieldWrapper
                            {...params}
                            label="Search"
                            name={`attributes.${index}.attr`}
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      ></Autocomplete>
                      <SelectWrapper
                        defaultValue={""}
                        currentModalValue=""
                        label="Value"
                        name={`attributes.${index}.value`}
                        options={handleMappedValues(
                          formik.values.attributes[index].attr,
                        )}
                        variant="outlined"
                        size="small"
                      />
                      <TextfieldWrapper
                        name={`attributes.${index}.points`}
                        label="Points"
                        type="number"
                        variant="outlined"
                        size="small"
                      ></TextfieldWrapper>
                      <StyledDiv
                        className={classes.removeAttrBtnParent}
                        onClick={() => {
                          if (formik.values.attributes.length === 1) {
                            handleRemoveSingleAttribute();
                            formik.setFieldValue(`attributes.${index}`, {
                              attr: "",
                              value: "",
                              points: null,
                            });
                            formik.setFieldTouched(
                              `attributes.${index}`,
                              false,
                            );
                            formik.setFieldValue("scoringFormula", "");
                            formik.setFieldTouched("scoringFormula", false);
                          } else {
                            remove(index);
                          }
                        }}
                      >
                        <Delete color="error" />
                      </StyledDiv>
                    </StyledDiv>
                    <StyledDiv className={classes.border}></StyledDiv>
                  </div>
                ))}
                <StyledDiv className={classes.btnDiv}>
                  <ButtonComponent
                    color="tertiary"
                    variant="contained"
                    fullWidth={true}
                    padding="15px"
                    justifyContent="center"
                    onClick={() =>
                      push({
                        attr: "",
                        value: "",
                        points: "",
                      })
                    }
                  >
                    {intl.formatMessage({
                      id: "unifiedSortingRuleModal.addAttributes",
                      defaultMessage: "Add Attributes",
                    })}
                  </ButtonComponent>
                </StyledDiv>
              </div>
            )}
          </FieldArray>
        </Grid>
      </div>
    </StyledDiv>
  );
};

export default AttributesForm;
