import React from "react";
import { Typography } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import { useIntl } from "react-intl";
import CustomDialog from "./common/DialogContainer";
import ButtonComponent from "../components/common/ButtonComponent";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    dialogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

const ConfirmationActionSequence = (props: {
  handleChange: (value?: boolean) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const { handleChange, isModalOpen, setIsModalOpen } = props;
  const intl = useIntl();
  const classes = useStyles();
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const confirmationActionSequenceTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmation.modalTitle",
          defaultMessage: "Warning: Unsaved Changes",
        })}
      </Typography>
    );
  };

  const confirmationActionSequenceContent = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        ></Grid>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmCategorySelection.instruction",
            defaultMessage:
              "You have unsaved changes. Are you sure you want to continue without saving your changes?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dialogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            handleChange(false);
          }}
        >
          {intl.formatMessage({
            id: "confirmCategorySelection.continue",
            defaultMessage: "Continue",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmationActionSequenceTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmationActionSequenceContent()}
    </CustomDialog>
  );
};

export default React.memo(ConfirmationActionSequence);
