import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, makeStyles, createStyles, Collapse } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import ClipboardArea from "./ClipboardArea";
import { setModalState } from "../store/modal/ModalActions";
import { selectAllProductsFetched } from "../store/product/ProductSelectors";
import PublishConfigProducts from "./publish-config-products/PublishConfigProducts";
import { selectCurrentStoreId } from "../store/store-list/StoreListSelectors";
import PublishConfigProductsSFCC from "./publish-config-products/PublishConfigProductsSFCC";
import useClipboard from "../hooks/useClipBoard";
import { VERIFY_CLIPBOARD_PRODUCT_CODES } from "../store/clipboard/ClipBoardTypes";
import { selectCurrentCatalogId } from "../store/catalog/CatalogSelectors";
import {
  selectClipboardInvalidProductCodes,
  selectCurrentActionModal,
} from "../store/clipboard/ClipBoardSelectors";
import ClipBoardActionModal from "./clipboard-action-modal/ClipBoardActionModal";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import ButtonComponent from "./common/ButtonComponent";
import AppState from "../store/AppState";
import {
  SearchRounded as SearchRoundedIcon,
  ContentCopy as ContentCopyIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  CompareArrowsTwoTone as CompareArrowsTwoToneIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "10px",
      display: "grid",
    },
  }),
);

interface Props {
  categoryId: string;
}
const AddAndCopyProducts: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { categoryId } = props;
  const storeId = useSelector(selectCurrentStoreId);
  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true";
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const products = useSelector(selectAllProductsFetched);
  const dispatch = useDispatch();
  const { addClipboardProductCodes, getClipboardProductCodes } = useClipboard();
  const catalogId = useSelector(selectCurrentCatalogId) || "";
  const productCodes = getClipboardProductCodes() ?? [];
  const invalidProductCodes = useSelector(selectClipboardInvalidProductCodes);
  const currentAction = useSelector(selectCurrentActionModal);

  useEffect(() => {
    if (currentAction && invalidProductCodes.length === 0) {
      dispatch(setModalState(currentAction, true));
    } else if (
      currentAction &&
      invalidProductCodes.length &&
      invalidProductCodes.length !== productCodes.length
    ) {
      dispatch(setModalState("ClipBoardConfirmParNumbersModal", true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction, dispatch, invalidProductCodes]);

  const collapseHandler = useCallback((isCollapse: boolean) => {
    setCollapse(isCollapse);
  }, []);

  const validatePartNumber = (action) => {
    const currentAction = action;
    const payload = {
      storeId,
      catalogId,
      productCodes,
      currentAction,
    };
    dispatch({ type: VERIFY_CLIPBOARD_PRODUCT_CODES.REQUEST, payload });
  };

  const copyAllCodesHandler = () => {
    const payload = {
      productCodes: Object.keys(products),
    };
    addClipboardProductCodes(payload);
  };

  const copyAllClickHandler = () => {
    if (Object.keys(products).length > 0) {
      copyAllCodesHandler();
      collapseHandler(true);
    }
  };

  const openRequestedProductActionModal = () => {
    dispatch(setModalState("ClipBoardConfirmParNumbersModal", false));
    dispatch(setModalState(currentAction as string, true));
  };

  const openFindProductsModal = () => {
    dispatch(setModalState("FindProductsModal", true));
  };

  return (
    <div className={classes.container}>
      <ClipBoardActionModal
        handleAdd={openRequestedProductActionModal}
        currentAction={currentAction}
        invalidProductCodes={invalidProductCodes}
      />
      <Typography variant="header" align="center" gutterBottom>
        {intl.formatMessage({
          id: "addAndCopyProducts.title",
          defaultMessage: "Add / Copy",
        })}
      </Typography>
      <Divider />
      <ButtonComponent
        color="tertiary"
        variant="contained"
        fullWidth={true}
        startIcon={<SearchRoundedIcon fontSize="medium" />}
        onClick={openFindProductsModal}
        width="100%"
        style={{
          display:
            categoryId === "" || categoryId === undefined ? "none" : "flex",
          // minWidth:"120px"
        }}
      >
        {intl.formatMessage({
          id: "addAndCopyProducts.findProductsLabel",
          defaultMessage: "Find Products",
        })}
      </ButtonComponent>
      <ButtonComponent
        color="tertiary"
        variant="contained"
        fullWidth={true}
        startIcon={<ContentCopyIcon fontSize="medium" />}
        onClick={copyAllClickHandler}
      >
        {intl.formatMessage({
          id: "addAndCopyProducts.copyAllLabel",
          defaultMessage: "Copy All",
        })}
      </ButtonComponent>
      <ClipboardArea onCollapse={collapseHandler} />
      <Collapse in={collapse}>
        <ButtonComponent
          color="tertiary"
          variant="contained"
          fullWidth={true}
          startIcon={<AddCircleOutlineIcon fontSize="medium" />}
          onClick={() => validatePartNumber("AddProductsModal")}
        >
          {intl.formatMessage({
            id: "addAndCopyProducts.addProductsLabel",
            defaultMessage: "Add Products",
          })}
        </ButtonComponent>
      </Collapse>
      <Collapse in={collapse}>
        <ButtonComponent
          color="tertiary"
          variant="contained"
          fullWidth={true}
          startIcon={<CompareArrowsTwoToneIcon fontSize="medium" />}
          onClick={() => validatePartNumber("ReplaceProductsModal")}
        >
          {intl.formatMessage({
            id: "addAndCopyProducts.replaceProductsLabel",
            defaultMessage: "Replace Products",
          })}
        </ButtonComponent>
      </Collapse>
      <Collapse in={collapse}>
        <ButtonComponent
          color="tertiary"
          variant="contained"
          fullWidth={true}
          startIcon={<RemoveCircleOutlineIcon fontSize="medium" />}
          onClick={() => validatePartNumber("RemoveProductsModal")}
          style={{
            minWidth: "120px",
          }}
        >
          {intl.formatMessage({
            id: "addAndCopyProducts.removeProductsLabel",
            defaultMessage: "Remove Products",
          })}
        </ButtonComponent>
      </Collapse>
      {bulkUpdateEnabled ? (
        <>
          <Collapse in={collapse}>
            <PublishConfigProducts publish />
          </Collapse>
          <Collapse in={collapse}>
            <PublishConfigProducts publish={false} />
          </Collapse>
        </>
      ) : (
        <Collapse in={collapse}>
          <PublishConfigProductsSFCC validatePartNumber={validatePartNumber} />
        </Collapse>
      )}
    </div>
  );
};

export default React.memo(AddAndCopyProducts);
