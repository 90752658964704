import {
  ADD_CLIPBOARD_PRODUCT_CODES,
  AddClipBoardProductCodesAction,
  CLEAR_CLIPBOARD,
  SET_CLIPBOARD_PRODUCT_CODES,
  SetClipBoardProductCodesAction,
  VERIFY_CLIPBOARD_PRODUCT_CODES,
} from "./ClipBoardTypes";

export interface ClipBoardState {
  productCodes: string[];
  invalidProductCodes: string[];
  isValidatingProductCodes: boolean;
  currentAction: string | null;
}

const initialState: ClipBoardState = {
  productCodes: [],
  invalidProductCodes: [],
  isValidatingProductCodes: false,
  currentAction: null,
};

export const ClipBoardReducer = (
  state: ClipBoardState = initialState,
  action,
) => {
  switch (action.type) {
    case SET_CLIPBOARD_PRODUCT_CODES: {
      const productCodes = action as SetClipBoardProductCodesAction;
      const items = productCodes.payload.productCodes;
      const filterCodes = new Set(items);
      const uniqueCodes = Array.from(filterCodes);
      return {
        ...state,
        productCodes: uniqueCodes,
        currentAction: null,
      };
    }
    case ADD_CLIPBOARD_PRODUCT_CODES: {
      const codes = action as AddClipBoardProductCodesAction;
      const updatedCodes = codes.payload.productCodes;
      return {
        ...state,
        productCodes: updatedCodes,
        currentAction: null,
      };
    }
    case CLEAR_CLIPBOARD:
      return {
        ...state,
        productCodes: [],
        invalidProductCodes: [],
        currentAction: null,
      };

    case VERIFY_CLIPBOARD_PRODUCT_CODES.REQUEST: {
      return {
        ...state,
        isValidatingProductCodes: true,
        currentAction: null,
      };
    }

    case VERIFY_CLIPBOARD_PRODUCT_CODES.COMPLETE: {
      const invalidProductCodes = action.payload.invalidProductCodes;
      const currentAction = action.payload.currentAction;
      return {
        ...state,
        invalidProductCodes,
        isValidatingProductCodes: false,
        currentAction,
      };
    }
    default:
      return state;
  }
};
