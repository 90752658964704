import { Divider } from "@mui/material";
import { useCallback, useState } from "react";

import { makeStyles } from "hooks";

import { CopyRight } from "./Copyright";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { PrivacyPolicyContent } from "./PrivacyPolicyContent";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.neutral.lightGrey,

    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
  },
  divider: {
    margin: `${theme.spacing(0, 1)} !important`,
  },
}));

export function Footer() {
  const classes = useStyles();

  const [isIframeOpen, setIsIframeOpen] = useState(false);

  const toggleIframeVisibility = useCallback(() => {
    setIsIframeOpen((prev) => !prev);
  }, [setIsIframeOpen]);

  return (
    <footer className={classes.footer}>
      <CopyRight />
      <Divider
        className={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <PrivacyPolicy onClick={toggleIframeVisibility} />
      <PrivacyPolicyContent
        open={isIframeOpen}
        onClose={toggleIframeVisibility}
      />
    </footer>
  );
}
