import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    btnGroup: {
      textAlign: "end",
      margin: "20px",
      width: "100%",
    },
  }),
);

const ConfirmAddProducts = (props: { handleAdd: () => void }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmAddProducts"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmAddProducts", false));
  };

  const confirmAddProductsTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmAddProducts.modalHead",
          defaultMessage: "Add the Products",
        })}
      </Typography>
    );
  };

  const confirmAddProductsContent = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmAddProducts.instruction",
            defaultMessage: "Are you sure you want to add the products?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            props.handleAdd();
            handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmAddProductsTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
    >
      {confirmAddProductsContent()}
    </CustomDialog>
  );
};

export default ConfirmAddProducts;
