export const FETCH_APP_CONFIG = {
  REQUEST: "FETCH_APP_CONFIG_REQUEST",
  SUCCESS: "FETCH_APP_CONFIG_SUCCESS",
  FAILURE: "FETCH_APP_CONFIG_FAILURE",
};

export const FETCH_CALLER_ACCOUNTS = {
  REQUEST: "FETCH_CALLER_ACCOUNTS_REQUEST",
  SUCCESS: "FETCH_CALLER_ACCOUNTS_SUCCESS",
  FAILURE: "FETCH_CALLER_ACCOUNTS_FAILURE",
};

export const LOAD_APP_CONFIG_SERVICE_URL = "LOAD_APP_CONFIG_SERVICE_URL";

export const GET_APP_CONFIG_DETAILS = "GET_APP_CONFIG_DETAILS";

export const SET_ADAPTER_TYPE = "SET_ADAPTER_TYPE";

export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";

export const GET_CALLER_ACCOUNTS = "GET_CALLER_ACCOUNTS";

export const LOAD_ALL_ACCOUNT_DETAILS = "LOAD_ALL_ACCOUNT_DETAILS";

export interface AppConfigDetails {
  account: {
    accountId: string;
    name: string;
    logo: string;
    inventoryThreshold?: string;
    createdAt: string;
    updatedAt: string;
    enableProductAlerts: boolean;
  };
  stores: StoreDetails[];
}

export interface StoreDetails {
  storeId: string;
  logo: string;
  productImagePrefix: string;
  inventoryThreshold: string;
  createdAt: string;
  updatedAt: string;
}

export interface storeAndAccountDetails {
  [x: string]: {
    [name: string]: any;
  };
}

export interface LoadConfigFromAppConfigAction {
  type: typeof GET_APP_CONFIG_DETAILS;
}

export interface accountDetailsPayload {
  accountId: string;
}

export interface CallerAccountInfoObject {
  accounts: accountDetails[];
}

export interface accountDetails {
  accountId: string;
  roles: string[];
  configs?: AppConfigDetails;
}

export interface LoadCallerAccountsAction {
  type: typeof GET_CALLER_ACCOUNTS;
}

export interface LoadAllAccountDetailsAction {
  type: typeof LOAD_ALL_ACCOUNT_DETAILS;
  payload: accountDetailsPayload;
}
