import { put, call, takeEvery, select } from "redux-saga/effects";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import {
  FETCH_CATALOGS,
  FETCH_STORES,
  LOAD_STORES,
  LoadCatalogsAction,
  LoadStoresAction,
  LOAD_CATALOGS,
  LoadAllTopCategoriesAction,
  FETCH_ALL_TOP_CATEGORIES,
  LOAD_All_TOP_CATEGORIES,
  LoadAllChildCategoriesAction,
  FETCH_ALL_CHILD_CATEGORIES,
  LOAD_ALL_CHILD_CATEGORIES,
  AddProductsToCategoryAction,
  ADD_PRODUCTS_TO_CATEGORIES,
  POST_PRODUCTS_TO_CATEGORIES,
  RESET_PRODUCT_ADDED_STATE,
  RESET_PRODUCT_ADDED,
  AddProductByCategoriesIdSuccessAndErrorResponses,
} from "./AddProductCategoriesTypes";
import { getData, postData } from "../../services/ApiService";
import { callApi } from "../../utils/SagaUtils";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { decodeNodeId } from "./AddProductCategoriesUtils";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import { selectCurrentCategory } from "../category/CategorySelectors";
import {
  FETCH_PRODUCT,
  REFRESH_PRODUCT_COLOR,
} from "../product-list/ProductListTypes";
import { LoadTopCategoriesByCatalogId } from "../category/CategoryActions";
import { LoadParentChildCategories } from "../category/CategoryActions";
import { LOAD_CATEGORIES_BY_PRODUCT_IDS } from "../remove-product-categories/RemoveProductCategoriesTypes";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";

export function* watchLoadStores() {
  yield takeEvery(LOAD_STORES, fetchStores);
}

export function* fetchStores(action: LoadStoresAction) {
  const actionType = FETCH_STORES;
  const constName = Object.keys({ FETCH_STORES })[0].toString();
  const headersObj = {
    "x-locale-code": "default",
    "x-currency-code": "USD",
    "x-store-id": "my-store",
    "x-catalog-id": "my-catalog",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    yield call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_STORES.FAILURE, message: e.message });
  }
}

export function* watchLoadCatalogs() {
  yield takeEvery(LOAD_CATALOGS, fetchCatalogs);
}

function* fetchCatalogs(action: LoadCatalogsAction) {
  const actionType = FETCH_CATALOGS;
  const constName = Object.keys({ FETCH_CATALOGS })[0].toString();
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);

  const headersObj = {
    "x-store-id": storeId || "my-store",
    "x-locale-code": localeCode || "default",
    "x-currency-code": "USD",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    const response = yield call(
      callApi,
      actionType,
      getData,
      null,
      endpoint,
      headersObj,
    );

    if (response?.payload) {
      yield put({
        type: FETCH_CATALOGS.SUCCESS,
        payload: { results: response?.payload.results, storeId: storeId },
      });
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_CATALOGS.FAILURE, message: e.message });
  }
}

export function* watchLoadAllTopCategories() {
  yield takeEvery(LOAD_All_TOP_CATEGORIES, fetchTopCategories);
}

function* fetchTopCategories(action: LoadAllTopCategoriesAction) {
  const actionType = FETCH_ALL_TOP_CATEGORIES;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);

  const constName = Object.keys({ FETCH_ALL_TOP_CATEGORIES })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, catalogId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
    };
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_ALL_TOP_CATEGORIES.FAILURE, message: e.message });
  }
}

export function* watchLoadAllChildCategories() {
  yield takeEvery(LOAD_ALL_CHILD_CATEGORIES, loadAllChildCategories);
}

function* loadAllChildCategories(action: LoadAllChildCategoriesAction) {
  const actionType = FETCH_ALL_CHILD_CATEGORIES;
  const categoryId = action.payload.categoryId;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({ FETCH_ALL_CHILD_CATEGORIES })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_ALL_CHILD_CATEGORIES.FAILURE, message: e.message });
  }
}

export function* watchAddToProductCategories() {
  yield takeEvery(POST_PRODUCTS_TO_CATEGORIES, addProductToCategories);
}

function* addProductToCategories(action: AddProductsToCategoryAction) {
  const actionType = ADD_PRODUCTS_TO_CATEGORIES;
  const selectedIds = action.payload.selectedIds || [];
  const products = action.payload.products;
  const localeCode = action.payload.localeCode;
  const productCategories = action.payload.productCategories;
  const categoryIds = action.payload.categoryIds;
  const currentCategoryId = yield select(selectCurrentCategory);
  const constName = Object.keys({ ADD_PRODUCTS_TO_CATEGORIES })[0].toString();
  const isSfcc = process.env.REACT_APP_SM_ENV === "sfcc";
  const existedProductIds: string[] = [];
  const successAndErrorResponses = {
    success: [],
    errors: [],
  } as AddProductByCategoriesIdSuccessAndErrorResponses;
  let numberOfAPiCalls = selectedIds?.length * products.length;
  const currentStoreId = yield select(selectCurrentStoreId);
  const currentCatalogId = yield select(selectCurrentCatalogId);
  try {
    if (selectedIds && Array.isArray(selectedIds) && selectedIds.length > 0) {
      for (const node of selectedIds) {
        const parts = decodeNodeId(node);
        const storeId = parts[0];
        const catalogId = parts[1];
        const categoryId = parts[parts.length - 1];

        const endpoint = acquireEndpoint(constName, categoryId);
        const headersObj = {
          "x-currency-code": "USD",
          "x-store-id": storeId,
          "x-catalog-id": catalogId,
          "x-locale-code": localeCode || "default",
        };
        const optionsObj = {
          showGenericError: false,
        };

        for (const product of products) {
          const shouldAddProduct =
            productCategories &&
            productCategories[product] &&
            productCategories?.[product]?.find(
              (cat) => cat?.categoryId === categoryId,
            );
          if (!shouldAddProduct) {
            const payload = {
              productId: product,
              sequence: isSfcc ? null : 0,
              categoryId: categoryId,
            };

            const result = yield call(
              callApi,
              actionType,
              postData,
              payload,
              endpoint,
              headersObj,
              optionsObj,
            );
            if (result && result.type === actionType.SUCCESS) {
              if (currentCategoryId === categoryId) {
                const singleProductFetchPayload = {
                  productId: product,
                  catalogId,
                  localeId: localeCode,
                  storeId: storeId,
                  isProductAddedFromClipBoard: true,
                };

                const refreshProductAction = {
                  payload: singleProductFetchPayload,
                  type: REFRESH_PRODUCT_COLOR.REQUEST,
                };

                yield put({
                  type: FETCH_PRODUCT.REQUEST,
                  payload: singleProductFetchPayload,
                });
                yield put(refreshProductAction);
              }
              result.productId = product;
              result.categoryId = categoryId;
              successAndErrorResponses.success.push(result);
              numberOfAPiCalls--;
            } else {
              result.productId = product;
              result.categoryId = categoryId;
              successAndErrorResponses.errors.push(result);
              numberOfAPiCalls--;
            }
          } else {
            numberOfAPiCalls--;
            if (!existedProductIds.includes(product)) {
              existedProductIds.push(product);
              //yield put({ type: ADD_PRODUCTS_TO_CATEGORIES.FAILURE });
            }
          }
        }
      }

      const addedProducts: string[] = [];
      let addedProductCount: number = 0;
      const apiSuccessResults = successAndErrorResponses.success;
      for (const successResponse of apiSuccessResults) {
        const productId = successResponse["productId"];
        if (addedProducts.length > 0) {
          if (!addedProducts.includes(productId)) {
            addedProducts.push(productId);
            addedProductCount++;
          }
        } else {
          addedProducts.push(productId);
          addedProductCount++;
        }
      }
      const errorProducts: string[] = [];
      const errorCategories: string[] = [];
      const apiErrorResults = successAndErrorResponses.errors;
      for (const errorResponse of apiErrorResults) {
        const errProductId = errorResponse["productId"];
        const errCategoryId = errorResponse["categoryId"];
        if (errorProducts.length > 0) {
          if (!errorProducts.includes(errProductId)) {
            errorProducts.push(errProductId);
          }
        } else {
          errorProducts.push(errProductId);
        }
        if (!errorCategories.includes(errCategoryId)) {
          errorCategories.push(errCategoryId);
        }
      }
      if (
        successAndErrorResponses.success.length > 0 &&
        successAndErrorResponses.errors.length === 0 &&
        existedProductIds.length === 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddSuccess",
                  defaultMessage: "Added {productCount} products",
                },
                variables: {
                  productCount: `${addedProductCount}`,
                },
                severity: "success",
                variant: "standard",
              },
            ],
          }),
        );
        if (categoryIds && categoryIds?.length)
          for (const c of categoryIds) {
            if (!c?.categoryId)
              yield put(
                LoadTopCategoriesByCatalogId(
                  c.catalogId,
                  localeCode,
                  c.storeId,
                ),
              );
            else if (
              successAndErrorResponses.success.find(
                (e) => e.categoryId === c.categoryId,
              )
            )
              yield put(
                LoadParentChildCategories(
                  c.catalogId,
                  c.categoryId,
                  localeCode,
                  c.storeId,
                ),
              );
          }
      } else if (
        successAndErrorResponses.success.length > 0 &&
        successAndErrorResponses.errors.length === 0 &&
        existedProductIds.length > 0
      ) {
        numberOfAPiCalls = 0;
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productSuccessAndProductAlreadyExistsFailure",
                  defaultMessage:
                    "Added {productCount} products. Product {productIds} already in the list",
                },
                variables: {
                  productCount: `${addedProductCount}`,
                  productIds: `${existedProductIds}`,
                },
                severity: "warning",
                variant: "standard",
              },
            ],
          }),
        );
        if (categoryIds && categoryIds?.length)
          for (const c of categoryIds) {
            if (!c?.categoryId)
              yield put(
                LoadTopCategoriesByCatalogId(
                  c.catalogId,
                  localeCode,
                  c.storeId,
                ),
              );
            else if (
              c?.categoryId &&
              successAndErrorResponses.success.find(
                (e) => e.categoryId === c.categoryId,
              )
            )
              yield put(
                LoadParentChildCategories(
                  c.catalogId,
                  c.categoryId,
                  localeCode,
                  c.storeId,
                ),
              );
          }
      } else if (
        successAndErrorResponses.errors.length > 0 &&
        successAndErrorResponses.success.length === 0 &&
        existedProductIds.length === 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddFailure",
                  defaultMessage: "Unable to Add Products",
                },
                severity: "error",
                variant: "standard",
              },
            ],
          }),
        );
      } else if (
        successAndErrorResponses.errors.length > 0 &&
        successAndErrorResponses.success.length === 0 &&
        existedProductIds.length > 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productFailureAndProductAlreadyExistsFailure",
                  defaultMessage:
                    "Unable to Add Products. Product {productIds} already in the list",
                },
                variables: {
                  productIds: `${existedProductIds}`,
                },
                severity: "warning",
                variant: "standard",
              },
            ],
          }),
        );
      } else if (
        successAndErrorResponses.success.length > 0 &&
        successAndErrorResponses.errors.length > 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddSuccessAndFailure",
                  defaultMessage:
                    "Added {productCount} products. Failed to add products with id: {productIds} from {categoryIds}",
                },
                variables: {
                  productCount: `${addedProductCount}`,
                  productIds: `${errorProducts}`,
                  categoryIds: `${errorCategories}`,
                },
                severity: "warning",
                variant: "standard",
              },
            ],
          }),
        );
        if (categoryIds && categoryIds?.length)
          for (const c of categoryIds) {
            if (
              !c?.categoryId &&
              !successAndErrorResponses.errors.find(
                (e) => e.categoryId === c.catalogId,
              )
            )
              yield put(
                LoadTopCategoriesByCatalogId(
                  c.catalogId,
                  localeCode,
                  c.storeId,
                ),
              );
            else if (
              c?.categoryId &&
              successAndErrorResponses.success.find(
                (e) => e.categoryId === c.categoryId,
              )
            )
              yield put(
                LoadParentChildCategories(
                  c.catalogId,
                  c.categoryId,
                  localeCode,
                  c.storeId,
                ),
              );
          }
      } else if (
        successAndErrorResponses.success.length === 0 &&
        successAndErrorResponses.errors.length === 0 &&
        existedProductIds.length > 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAlreadyExistsFailure",
                  defaultMessage: "Product {productIds} already in the list",
                },
                variables: {
                  productIds: `${existedProductIds}`,
                },
                severity: "warning",
                variant: "standard",
              },
            ],
          }),
        );
      }
      if (numberOfAPiCalls === 0) {
        yield put({ type: ADD_PRODUCTS_TO_CATEGORIES.COMPLETED });
      }

      if (addedProducts.length > 0) {
        const payload = {
          storeId: currentStoreId,
          catalogId: currentCatalogId,
          productIds: addedProducts,
          localeCode,
        };
        yield put({
          payload,
          type: LOAD_CATEGORIES_BY_PRODUCT_IDS,
        });
      }
    } else {
      const catalogId = action.payload.catalogId;
      const categoryId = action.payload.categoryId;
      const storeId = action.payload.storeId;
      const endpoint = acquireEndpoint(constName, categoryId);
      const headersObj = {
        "x-currency-code": "USD",
        "x-store-id": storeId,
        "x-catalog-id": catalogId,
        "x-locale-code": localeCode || "default",
      };

      for (const product of products) {
        const payload = {
          productId: product,
          sequence: isSfcc ? null : 0,
          categoryId: categoryId,
        };
        const result = yield call(
          callApi,
          actionType,
          postData,
          payload,
          endpoint,
          headersObj,
        );
        if (result && result.type === actionType.SUCCESS) {
          const singleProductFetchPayload = {
            productId: product,
            catalogId,
            localeId: localeCode,
            storeId: storeId,
            isProductAddedFromClipBoard: true,
          };
          const refreshProductAction = {
            payload: singleProductFetchPayload,
            type: REFRESH_PRODUCT_COLOR.REQUEST,
          };

          yield put({
            type: FETCH_PRODUCT.REQUEST,
            payload: singleProductFetchPayload,
          });
          yield put(refreshProductAction);
          result.productId = product;
          result.categoryId = categoryId;
          successAndErrorResponses.success.push(result);
        } else {
          result.productId = product;
          result.categoryId = categoryId;
          successAndErrorResponses.errors.push(result);
        }
      }
      const addedProducts: string[] = [];
      let addedProductCount: number = 0;
      const apiSuccessResults = successAndErrorResponses.success;
      for (const successResponse of apiSuccessResults) {
        const productId = successResponse["productId"];
        if (addedProducts.length > 0) {
          if (!addedProducts.includes(productId)) {
            addedProducts.push(productId);
            addedProductCount++;
          }
        } else {
          addedProducts.push(productId);
          addedProductCount++;
        }
      }
      const errorProducts: string[] = [];
      const errorCategories: string[] = [];
      const apiErrorResults = successAndErrorResponses.errors;
      for (const errorResponse of apiErrorResults) {
        const errProductId = errorResponse["productId"];
        const errCategoryId = errorResponse["categoryId"];
        if (errorProducts.length > 0) {
          if (!errorProducts.includes(errProductId)) {
            errorProducts.push(errProductId);
          }
        } else {
          errorProducts.push(errProductId);
        }
        if (!errorCategories.includes(errCategoryId)) {
          errorCategories.push(errCategoryId);
        }
      }
      if (
        successAndErrorResponses.success.length > 0 &&
        successAndErrorResponses.errors.length === 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddSuccess",
                  defaultMessage: "Added {productCount} products",
                },
                variables: {
                  productCount: `${addedProductCount}`,
                },
                severity: "success",
                variant: "standard",
              },
            ],
          }),
        );
        if (categoryIds && categoryIds?.length)
          for (const c of categoryIds) {
            if (!c?.categoryId) {
              yield put(
                LoadTopCategoriesByCatalogId(
                  c.catalogId,
                  localeCode,
                  c.storeId,
                ),
              );
            } else {
              yield put(
                LoadParentChildCategories(
                  c.catalogId,
                  c.categoryId,
                  localeCode,
                  c.storeId,
                ),
              );
            }
          }
      } else if (
        successAndErrorResponses.errors.length > 0 &&
        successAndErrorResponses.success.length === 0 &&
        existedProductIds.length === 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddFailure",
                  defaultMessage: "Unable to Add Products",
                },
                severity: "error",
                variant: "standard",
              },
            ],
          }),
        );
      } else if (
        successAndErrorResponses.success.length > 0 &&
        successAndErrorResponses.errors.length > 0
      ) {
        yield put(
          addGlobalAlertState({
            alertsProps: [
              {
                descriptor: {
                  id: "addProductCategoriesOperations.productAddSuccessAndFailure",
                  defaultMessage:
                    "Added {productCount} products. Failed to add products with id: {productIds} from {categoryIds}",
                },
                variables: {
                  productCount: `${addedProductCount}`,
                  productIds: `${errorProducts}`,
                  categoryIds: `${errorCategories}`,
                },
                severity: "warning",
                variant: "standard",
              },
            ],
          }),
        );
        if (categoryIds && categoryIds?.length)
          for (const c of categoryIds) {
            if (
              !c?.categoryId &&
              !successAndErrorResponses.errors.find(
                (e) => e.categoryId === c.catalogId,
              )
            )
              yield put(
                LoadTopCategoriesByCatalogId(
                  c.catalogId,
                  localeCode,
                  c.storeId,
                ),
              );
            else if (
              c?.categoryId &&
              successAndErrorResponses.success.find(
                (e) => e.categoryId === c.categoryId,
              )
            )
              yield put(
                LoadParentChildCategories(
                  c.catalogId,
                  c.categoryId,
                  localeCode,
                  c.storeId,
                ),
              );
          }
      }
      if (addedProducts.length > 0) {
        const payload = {
          storeId: currentStoreId,
          catalogId: currentCatalogId,
          productIds: addedProducts,
          localeCode,
        };
        yield put({
          payload,
          type: LOAD_CATEGORIES_BY_PRODUCT_IDS,
        });
      }
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: ADD_PRODUCTS_TO_CATEGORIES.FAILURE, message: e.message });
    yield put(
      addGlobalAlertState({
        alertsProps: [
          {
            descriptor: {
              id: "addProductCategoriesOperations.productAddFailure",
              defaultMessage: "Unable to Add Products",
            },
            severity: "error",
            variant: "standard",
          },
        ],
      }),
    );
  }
}

export function* watchResetProductAddedState() {
  yield takeEvery(RESET_PRODUCT_ADDED, resetProductAddedState);
}

function* resetProductAddedState() {
  try {
    yield put({ type: RESET_PRODUCT_ADDED_STATE });
  } catch (e: any) {
    console.error(e);
  }
}
