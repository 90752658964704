import { takeEvery, put, select, call } from "redux-saga/effects";

import {
  LOAD_CATEGORIES_BY_PRODUCT_IDS,
  DELETE_PRODUCTS_BY_CATEGORIES_ID,
  LoadCategoriesByProductIdsAction,
  DeleteProductByCategoriesAction,
  FETCH_CATEGORIES_BY_PRODUCT_IDS,
  DELETE_PRODUCT_BY_CATEGORIES,
  RESET_PRODUCT_REMOVE_STATE,
  RESET_PRODUCT_DELETED,
  DeleteProductByCategoriesIdSuccessAndErrorResponses,
} from "./RemoveProductCategoriesTypes";

import { callApi } from "../../utils/SagaUtils";

import { deleteData, postData } from "../../services/ApiService";

import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";

import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import { selectCurrentCategory } from "../category/CategorySelectors";
import { LoadParentChildCategories } from "../category/CategoryActions";
import { LoadTopCategoriesByCatalogId } from "../category/CategoryActions";
import { FETCH_PRODUCT } from "../product-list/ProductListTypes";

function* loadCategoriesByProductsId(action: LoadCategoriesByProductIdsAction) {
  try {
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const localeCode = yield select(selectCurrentLocale);
    const actionType = FETCH_CATEGORIES_BY_PRODUCT_IDS;
    const constName = Object.keys({
      FETCH_CATEGORIES_BY_PRODUCT_IDS,
    })[0].toString();

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    const endpoint = acquireEndpoint(constName);
    yield call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_CATEGORIES_BY_PRODUCT_IDS.FAILURE,
      message: e.message,
    });
  }
}

function* DeleteProductByCategories(action: DeleteProductByCategoriesAction) {
  try {
    const categoryIds = action.payload.categoryIds;
    const productIds = action.payload.productIds;
    const childrenCategories = action.payload.childrenCategories;
    const topCategories = action.payload.topCategories;
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const localeCode = yield select(selectCurrentLocale);
    const currentCategoryId = yield select(selectCurrentCategory);
    const actionType = DELETE_PRODUCT_BY_CATEGORIES;
    const successAndErrorResponses = {
      success: [],
      errors: [],
    } as DeleteProductByCategoriesIdSuccessAndErrorResponses;
    const constName = Object.keys({
      DELETE_PRODUCT_BY_CATEGORIES,
    })[0].toString();

    const currentStoreId = yield select(selectCurrentStoreId);
    const currentCatalogId = yield select(selectCurrentCatalogId);

    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    const optionsObj = {
      showGenericError: false,
    };

    for (const catId of categoryIds) {
      for (const prodId of productIds) {
        const endpoint = acquireEndpoint(constName, catId, prodId);

        const result = yield call(
          callApi,
          actionType,
          deleteData,
          null,
          endpoint,
          headersObj,
          optionsObj,
        );
        if (result && result.type === actionType.SUCCESS) {
          result.productId = prodId;
          result.categoryId = catId;
          if (currentCategoryId === catId) {
            const singleProductFetchPayload = {
              productId: prodId,
              catalogId,
              localeId: localeCode,
              storeId: storeId,
              isProductRemoved: true,
            };
            yield put({
              type: FETCH_PRODUCT.REQUEST,
              payload: singleProductFetchPayload,
            });
            yield put({
              type: DELETE_PRODUCT_BY_CATEGORIES.DONE,
              payload: { productId: prodId },
            });
          }
          successAndErrorResponses.success.push(result);
        } else {
          result.productId = prodId;
          result.categoryId = catId;
          successAndErrorResponses.errors.push(result);
        }
      }
    }

    const deletedProducts: string[] = [];
    let deletedProductCount: number = 0;
    const apiSuccessResults = successAndErrorResponses.success;
    for (const successResponse of apiSuccessResults) {
      const productId = successResponse["productId"];
      if (deletedProducts.length > 0) {
        if (!deletedProducts.includes(productId)) {
          deletedProducts.push(productId);
          deletedProductCount++;
        }
      } else {
        deletedProducts.push(productId);
        deletedProductCount++;
      }
    }

    const errorProducts: string[] = [];
    const errorCategories: string[] = [];
    const apiErrorResults = successAndErrorResponses.errors;
    for (const errorResponse of apiErrorResults) {
      const errProductId = errorResponse["productId"];
      const errCategoryId = errorResponse["categoryId"];
      if (errorProducts.length > 0) {
        if (!errorProducts.includes(errProductId)) {
          errorProducts.push(errProductId);
        }
      } else {
        errorProducts.push(errProductId);
      }
      if (!errorCategories.includes(errCategoryId)) {
        errorCategories.push(errCategoryId);
      }
    }

    if (
      successAndErrorResponses.success.length > 0 &&
      successAndErrorResponses.errors.length === 0
    ) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "removeProductCategoriesOperations.productDeleteSuccess",
                defaultMessage: "Removed {productCount} products",
              },
              variables: {
                productCount: `${deletedProductCount}`,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      if (
        (childrenCategories && childrenCategories?.length) ||
        (topCategories && topCategories?.length)
      )
        for (const s of successAndErrorResponses.success) {
          if (childrenCategories && childrenCategories?.length) {
            const search = childrenCategories.find((e) => e === s.categoryId);
            if (search)
              yield put(
                LoadParentChildCategories(
                  catalogId,
                  search,
                  localeCode,
                  storeId,
                ),
              );
          }
          if (
            topCategories?.length &&
            topCategories.find((e) => e.categoryId === s.categoryId)
          )
            yield put(
              LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId),
            );
        }
    } else if (
      successAndErrorResponses.errors.length > 0 &&
      successAndErrorResponses.success.length === 0
    ) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "removeProductCategoriesOperations.productDeleteFailure",
                defaultMessage: "Unable to Remove Products",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    } else if (
      successAndErrorResponses.success.length > 0 &&
      successAndErrorResponses.errors.length > 0
    ) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "removeProductCategoriesOperations.productDeleteSuccessAndFailure",
                defaultMessage:
                  "Removed {productCount} products. Failed to remove products with id: {productIds} from {categoryIds}",
              },
              variables: {
                productCount: `${deletedProductCount}`,
                productIds: `${errorProducts}`,
                categoryIds: `${errorCategories}`,
              },
              severity: "warning",
              variant: "standard",
            },
          ],
        }),
      );
      if (
        (childrenCategories && childrenCategories?.length) ||
        (topCategories && topCategories?.length)
      )
        for (const s of successAndErrorResponses.success) {
          if (childrenCategories && childrenCategories?.length) {
            const search = childrenCategories.find((e) => e === s.categoryId);
            if (search)
              yield put(
                LoadParentChildCategories(
                  catalogId,
                  search,
                  localeCode,
                  storeId,
                ),
              );
          }
          if (
            topCategories?.length &&
            topCategories.find((e) => e.categoryId === s.categoryId)
          )
            yield put(
              LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId),
            );
        }
    }
    if (deletedProducts.length > 0) {
      const payload = {
        storeId: currentStoreId,
        catalogId: currentCatalogId,
        productIds: deletedProducts,
        localeCode,
      };
      yield put({
        payload,
        type: LOAD_CATEGORIES_BY_PRODUCT_IDS,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: DELETE_PRODUCT_BY_CATEGORIES.FAILURE,
      message: e.message,
    });
  }
}

function* resetProductRemoveState() {
  try {
    yield put({ type: RESET_PRODUCT_REMOVE_STATE });
  } catch (e: any) {
    console.error(e);
  }
}

export function* watchResetProductRemoveState() {
  yield takeEvery(RESET_PRODUCT_DELETED, resetProductRemoveState);
}

export function* watchLoadCategoriesByProductIds() {
  yield takeEvery(LOAD_CATEGORIES_BY_PRODUCT_IDS, loadCategoriesByProductsId);
}

export function* watchDeleteProductByCategories() {
  yield takeEvery(DELETE_PRODUCTS_BY_CATEGORIES_ID, DeleteProductByCategories);
}
