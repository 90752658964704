import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import ButtonComponent from "../common/ButtonComponent";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    divActionBtn: {
      margin: "20px",
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
  }),
);

const ConfirmationColorManagement = (props: {
  // handleDelete: () => void;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyle();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmationColorManagement"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmationColorManagement", false));
  };

  const handleCloseProceed = () => {
    dispatch(setModalState("ConfirmationColorManagement", false));
    dispatch(setModalState("ColorManagementModal", false));
  };

  const confirmationColorManagementTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmation.modalTitle",
          defaultMessage: "Warning: Unsaved Changes",
        })}
      </Typography>
    );
  };

  const confirmationColorManagementContent = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmationColorManagement.instruction",
            defaultMessage:
              "You have unsaved sequence changes. Are you sure you want to continue without saving your changes?",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.divActionBtn}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleCloseProceed}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmationColorManagementTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmationColorManagementContent()}
    </CustomDialog>
  );
};

export default ConfirmationColorManagement;
