import React from "react";
import { useSelector } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@mui/material";

import AppState from "../../store/AppState";
import {
  selectCategoryByProductId,
  selectIsProductChildrenFetched,
  selectIsProductChildrenFetching,
} from "../../store/product/ProductSelectors";
import {
  selectIsOverlaySelectedByType,
  selectOverlay,
} from "../../store/overlay/OverlaySelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import { getProductCategoryNameSFCC } from "../../utils/ProductUtil";
import { OVERLAY_TABS } from "../../utils/Constants";
import Tooltip from "../common/ToolTip";
import { classicTheme } from "../../styles/themes/classic";

interface Props {
  productId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      margin: "5px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "16px",
    },
    listParent: {
      position: "relative",
      marginTop: 50,
      margin: 0,
      fontWeight: "bold",
    },
    listItem: {
      color: theme.palette.common.white,
      padding: "0 10px",
      "&:hover": {
        color: classicTheme.palette.primary.main,
      },
    },
  }),
);

const ProductCategoryOverlay: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { productId } = props;

  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const categoryList = useSelector((state: AppState) =>
    selectCategoryByProductId(state, productId),
  );
  const isCategoryOverlay = useSelector((state: AppState) =>
    selectIsOverlaySelectedByType(state, OVERLAY_TABS.CATEGORIES_OVERLAY),
  );
  const selectedOverlay = useSelector(selectOverlay);
  const isProductChildrenFetched = useSelector(selectIsProductChildrenFetched);
  const isProductChildrenFetching = useSelector(
    selectIsProductChildrenFetching,
  );

  if (isProductChildrenFetching && !isProductChildrenFetched)
    return (
      <>
        <div className={classes.overlay}>
          <ul className={classes.listParent}>
            {isCategoryOverlay &&
              selectedOverlay === OVERLAY_TABS.CATEGORIES_OVERLAY && (
                <li key={`${productId}`} className={classes.listItem}>
                  <div>
                    <Tooltip tooltipTitle={"load"} placement="left">
                      <Typography
                        noWrap
                        variant="inherit"
                        //className={classes.body2}
                      >
                        Loading. . .
                      </Typography>
                    </Tooltip>
                  </div>
                </li>
              )}
          </ul>
        </div>
      </>
    );
  else if (!isProductChildrenFetching && isProductChildrenFetched)
    return (
      <>
        <div className={classes.overlay}>
          <ul className={classes.listParent}>
            {isCategoryOverlay &&
              selectedOverlay === OVERLAY_TABS.CATEGORIES_OVERLAY &&
              categoryList.map((obj, index) => (
                <li key={`${productId}_${index}`} className={classes.listItem}>
                  <div>
                    <Tooltip tooltipTitle={obj.code} placement="left">
                      <Typography
                        noWrap
                        variant="inherit"
                        //className={classes.body2}
                      >
                        {getProductCategoryNameSFCC(
                          obj,
                          currentLocaleId,
                          defaultLocaleId,
                        )}
                      </Typography>
                    </Tooltip>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  else return <></>;
};

export default React.memo(ProductCategoryOverlay);
