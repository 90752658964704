import React from "react";
import { Skeleton } from "@mui/material";
import { StyledDiv, styleClasses } from "../ProductListStyles";

const ProductListLoading: React.FC<{ count: number }> = ({ count }) => {
  if (!Number.isInteger(count) || count <= 0) {
    return null;
  }

  return (
    <StyledDiv className={styleClasses.progressDiv}>
      {[...Array(count)].map((_, idx) => (
        <Skeleton key={idx} width="33%" height={330} variant="rectangular" />
      ))}
    </StyledDiv>
  );
};

export default ProductListLoading;
