import React, { useEffect } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material";
import { useIntl } from "react-intl";
import { makeStyles, createStyles } from "@material-ui/core";
import { keyframes } from "@mui/system";
import AppState from "../store/AppState";
import {
  selectProductListSequenceMode,
  selectIsProductListsFetched,
  shouldUpdateEditSequenceList,
  selectWorkingProductSequence,
} from "../store/product-list/ProductListSelectors";
import { OVERLAY_TABS, SEQUENCE_MODE_TYPE } from "../utils/Constants";
import {
  loadProductIdsFromCategory,
  updateProductListSequenceMode,
} from "../store/product-list/ProductListActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "../store/store-list/StoreListSelectors";
import { selectCurrentCategory } from "../store/category/CategorySelectors";
import { selectCurrentCatalogId } from "../store/catalog/CatalogSelectors";
import { LOAD_PINNED_PRODUCTS } from "../store/product-list/ProductListTypes";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import { getProductsSocialAnalytics } from "../store/product-social-analytics/SocialAnalyticsActions";
import CustomDialog from "./common/DialogContainer";
import ButtonComponent from "../components/common/ButtonComponent";
import Toggle from "./common/Toggle";
import Icons from "./common/Icons";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styleClasses = {
  refreshContainer: "refreshContainer",
  root: "root",
  actiondiv: "actiondiv",
};
const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.refreshContainer}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    color: "#709bb1",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [`&.${styleClasses.actiondiv}`]: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    refreshContent: {
      fontSize: "14px",

      marginLeft: "5px",
      fontWeight: 600,
    },
    icon: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
      animation: `${spin} 1s infinite ease`,
    },
  }),
);

export default function ProductListSwitch() {
  const intl = useIntl();
  const classes = useStyles();
  const [mode, setMode] = React.useState<string | null>(() => "View");
  const dispatch = useDispatch();
  const sequenceMode = useSelector((state: AppState) =>
    selectProductListSequenceMode(state),
  );
  const isProductListsFetched = useSelector((state: AppState) =>
    selectIsProductListsFetched(state),
  );

  const productIds = useSelector(selectWorkingProductSequence);
  const storeId = useSelector(selectCurrentStoreId);
  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const localeCode = useSelector(selectCurrentLocale);
  const storeListIds = useSelector(selectStoreListIds);
  const overlay = useSelector(selectOverlay);
  const selected = sequenceMode === SEQUENCE_MODE_TYPE.EDIT;
  const isChangeMadeToTheCurrentProductList = useSelector(
    shouldUpdateEditSequenceList,
  );

  useEffect(() => {
    if (selected) {
      setMode("Edit");
    } else {
      setMode("View");
    }
  }, [selected]);

  const handleSequenceModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string | null,
  ) => {
    if (newMode && newMode !== mode) {
      if (newMode === "Edit") {
        dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
      } else if (newMode === "View" && isChangeMadeToTheCurrentProductList) {
        handleClickOpen();
      }
      setMode(newMode);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (selected) {
      setMode("Edit");
    }
    setOpen(false);
  };

  const handleClickSubmit = () => {
    dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.VIEW));
    setOpen(false);
  };

  const refreshAllProducts = () => {
    if (storeId === null) {
      console.error("refreshAllProducts called with null storeId");
      return;
    }
    if (overlay === OVERLAY_TABS.RATINGS) {
      const payload = {
        productCodes: productIds,
        isRefreshingAllProducts: true,
      };
      dispatch(getProductsSocialAnalytics(payload));
    } else {
      let payload: Parameters<typeof loadProductIdsFromCategory>[0] = {
        categoryId,
        catalogId,
        localeCode,
        storeId,
        productIds,
        storeListIds,
        isRefreshingAllProducts: true,
      };

      dispatch(loadProductIdsFromCategory(payload)); // TODO this is a bad name, we should be loading products, not ids...  It would be even better to have a refreshAllProducts action.
      dispatch({
        type: LOAD_PINNED_PRODUCTS,
        payload: { catalogId, categoryId, storeId },
      });
    }
  };

  const productListSwitchContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        {intl.formatMessage({
          id: "productListSwitch.discardChangesText",
          defaultMessage:
            "Are you sure to discard all your changes to product list?",
        })}
      </DialogContentText>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.actiondiv}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "productListSwitch.cancelLabel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClickSubmit}
          autoFocus
        >
          {intl.formatMessage({
            id: "productListSwitch.confirmLabel",
            defaultMessage: "Confirm",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Toggle
        toggleId="ProductListSwitch"
        classes={styleClasses}
        handleSequenceModeChange={handleSequenceModeChange}
        mode={mode}
        isProductListsFetched={isProductListsFetched}
      >
        <CustomDialog
          open={open && isChangeMadeToTheCurrentProductList}
          onClose={handleClose}
          title={<></>}
          actions={dialogActions()}
          maxWidth={"sm"}
        >
          {productListSwitchContent()}
        </CustomDialog>
      </Toggle>
      {isProductListsFetched && (
        <StyledDiv
          onClick={() => refreshAllProducts()}
          className={styleClasses.refreshContainer}
        >
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            startIcon={
              <Icons iconId="RefreshIcon" type="bold" classes={classes.icon} />
            }
            onClick={() => refreshAllProducts()}
          >
            REFRESH ALL
          </ButtonComponent>
        </StyledDiv>
      )}
    </div>
  );
}
