import {
  Product,
  LoadProductDetailsResponseAction,
  ProductDetailsData,
  // RESET_PRODUCT_DETAILS,
  ProductSwatchType,
  LoadChildrenItemsResponseAction,
  FETCH_CHILDREN_ITEMS,
  ToChildProduct,
  ProductPrice,
  ProductInventory,
  LeadingColor,
  FETCH_PRODUCT_ANALYTICS,
  LoadProductAnalyticsResponseAction,
  AnalyticsData,
  FETCH_CATEGORY_BY_PRODUCT_ID,
  LoadCategoryByProductIdResponseAction,
  UPDATED_SELECTED_SWATCHES,
  UpdatedSelectedSwatchesAction,
  FETCH_PRODUCT_ATTRIBUTEVALUES,
  LoadProductAttributeValuesResponseAction,
  ProductAttribute,
  // UPDATE_SELECTED_PRODUCTS_CODE,
  // UpdateSelectedProductsCodeAction,
  UPDATE_CHILDREN_ITEMS,
  UpdateChildrenItemsAction,
  SetSearchTermAction,
  SET_SEARCH_TERM,
  UPDATE_PRODUCT_LIST_BY_SEARCH,
  UpdateProductListBySearchAction,
  FETCH_CHILD_PRODUCT_ATTRIBUTES,
  LoadChildProductAttributeResponseAction,
  ChildProductAttribute,
  FETCH_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID,
  LoadChildProductVariantAttributesByParentIdResponseAction,
  ChildProductVariantAttributeByParentIdNode,
  CategoryVariantAttributeNode,
  FETCH_INVENTORY_OVERLAY_ATTRIBUTES,
  LoadInventoryOvelrayAttributesResponseAction,
  LoadLowInventoryProductsResponseAction,
  FETCH_LOW_INVENTORY_PRODUCTS,
  RESET_PRODUCTS,
  FETCH_PRODUCT_DETAILS_FOR_REPORT,
  SET_DATE_PICKER_RANGE,
  SetDatePickerRangeAction,
  DatePickerPayload,
  CHANGE_PRODUCT_COLORS,
  UpdateProductColorsAction,
  CategoryType,
  LoadSortedProductsAction,
  FETCH_PRODUCTS_VARIANTS,
  FetchProductsVariantsResponse,
  UPDATE_PRODUCT_VARIANT_PUBLISH_STATE,
  UpdateProductVariantPublishStateResponse,
  RESET_PRODUCTS_VARIANTS_STATE,
  UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE,
  ADD_MIGRATED_CATEGORIES_NEW_DATA,
  CategoryDataSet,
  RecentlyAddedProductsData,
  REMOVE_PRODUCT_IN_CATEGORY,
  UPDATE_PRODUCT_ISPUBLISHED_STATE,
  RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE,
  LOAD_CATEGORY_BY_PRODUCT_ID,
  ColorProduct,
  LOAD_SINGLE_PRODUCT_ANALYTICS_STATE,
  productAnalyticsView,
  RESET_SINGLE_PRODUCT_ANALYTICS_VIEW,
  resetSingleProductAnalyticAction,
  RESET_ALL_PRODUCT_ANALYTICS_VIEW,
  ANALYTICS_DATA_LOADER,
  SET_PRODUCTS_LOADING_STATE,
} from "./ProductTypes";
import { getDateStringByDays } from "../../utils/TimeUtils";
import {
  CategoryToProductData,
  FETCH_PRODUCT,
  FETCH_PRODUCT_LIST,
  FETCH_SORTED_PRODUCTS,
  SET_ACTIVE_SORTING_RULE,
  REFRESH_PRODUCT,
  FETCH_PRODUCTS_COLORS,
  productColorsData,
  REFRESH_PRODUCT_COLOR,
} from "../product-list/ProductListTypes";
import { loadProductsColorsDataActionPayload } from "../product-list/ProductListActions";
import { FETCH_CATEGORIES_BY_PRODUCT_IDS } from "../remove-product-categories/RemoveProductCategoriesTypes";

export interface ProductsState {
  products: ProductNode;
  toChildrenItems: ChildrenItemNode;
  toChildrenItemsVariantAttributes: ChildProductVariantAttributeByParentIdNode;
  swatches: SwatchesNode;
  selectedSwatches: SelectedSwatchNode;
  prices: ProductPriceNode;
  inventory: ProductInventoryNode;
  variants: { data: ProductVariant[]; isVariantFetched: boolean };
  category: ProductCategoryNode;
  attributes: ProductAttributesNode;
  isFetched: boolean;
  isProductChildrenFetching: boolean;
  isProductChildrenFetched: boolean;
  leadingColors: LeadingColorNode;
  analytics: Analytics;
  selectedDateRange: DatePickerPayload;
  isAnalyticsFetched: boolean;
  isAttributesFetched: boolean;
  // selectedProductsCode: string[];
  childrenPage: number;
  fetchedPages: Set<number>;
  isFetchedBySearch: boolean;
  searchTerm: string;
  searchResultIds: string[];
  isChildProductAttributeFetching: boolean;
  isChildProductAttributeFetched: boolean;
  childrenAttributes: ChildAttributesNode;
  categoryVariantAttributes: CategoryVariantAttributeNode;
  fetchedVariants: string[];
  alertViewType: number;
  // clipBoardData: ProductSequenceData[];
  isPublishedFlag: boolean;
  activeSortingRule: string;
  recentlyAddedProducts: RecentlyAddedProductsData[];
  isVariantFlagPublished: boolean;
  productsColorData: ProductColorNode;
  isProductsLoading: boolean;
}

export interface ChildrenItemNode {
  [name: string]: ToChildProduct[];
}

export interface SwatchesNode {
  [name: string]: ProductSwatchType[];
}

export interface SelectedSwatch {
  selectedSwatch: string;
  productImage: string;
}
export interface SelectedSwatchNode {
  [name: string]: SelectedSwatch;
}

export interface ProductNode {
  [name: string]: Product;
}

export interface ProductColorNode {
  [name: string]: ColorProduct;
}

export interface ProductPriceNode {
  [name: string]: {
    [name: string]: ProductPrice;
  };
}

export interface ProductInventoryNode {
  [name: string]: ProductInventory;
}
export interface ProductCategoryNode {
  [name: string]: CategoryType[];
}

export interface ProductAttributesNode {
  [name: string]: ProductAttribute;
}

export interface ChildAttributesNode {
  [name: string]: ChildProductAttribute;
}

export interface LeadingColorNode {
  [name: string]: LeadingColor[];
}

export interface AnalyticsNode {
  [name: string]: AnalyticsData[];
}

export interface Analytics {
  startDate: string;
  endDate: string;
  productAnalytics: AnalyticsNode;
  productView: productAnalyticsView;
}
export interface ProductVariant {
  variantId: string;
  stock: number;
  code: string;
  isPublished: boolean;
  variationOptions: Translations[];
}

export interface Translations {
  attributeCode: string;
  attributeLabel: string;
  valueCode: string;
  valueLabel: string;
  translations: {
    localeCode: string;
    valueLabel: string;
  }[];
}

const initialState: ProductsState = {
  products: {},
  toChildrenItems: {},
  toChildrenItemsVariantAttributes: {},
  swatches: {},
  selectedSwatches: {},
  prices: {},
  inventory: {},
  variants: { data: [], isVariantFetched: false },
  category: {},
  leadingColors: {},
  analytics: {
    startDate: "",
    endDate: "",
    productAnalytics: {},
    productView: {},
  },
  selectedDateRange: {
    startDate: getDateStringByDays(-7),
    endDate: getDateStringByDays(-1),
  },
  attributes: {},
  // selectedProductsCode: [],
  isFetched: false,
  isProductChildrenFetching: false,
  isProductChildrenFetched: false,
  isAnalyticsFetched: false,
  isAttributesFetched: false,
  childrenPage: 0,
  fetchedPages: new Set(),
  isFetchedBySearch: false,
  searchTerm: "",
  searchResultIds: [],
  isChildProductAttributeFetching: false,
  isChildProductAttributeFetched: false,
  childrenAttributes: {},
  categoryVariantAttributes: {},
  fetchedVariants: [],
  alertViewType: 0,
  // clipBoardData: [],
  recentlyAddedProducts: [],
  isPublishedFlag: false,
  activeSortingRule: "",
  isVariantFlagPublished: false,
  productsColorData: {},
  isProductsLoading: false,
};

const getProductsColorsDataFromResponse = (
  productColorsData: productColorsData[],
): ProductColorNode => {
  const productColorObjects: ProductColorNode = {};
  productColorsData?.forEach((productColorObj) => {
    if (Object.keys(productColorObj).length) {
      const product = {
        productId: productColorObj.productId,
        hasManageableColors:
          productColorObj.cachedProduct.product.hasManageableColors,
        hasSequenceableColors:
          productColorObj.cachedProduct.product.hasSequenceableColors,
        colorVariationAttributeId:
          productColorObj.cachedProduct.product.colorVariationAttributeId ?? "",
        colors: productColorObj.cachedProduct.product.colors,
        cachedAt: productColorObj.cachedProduct.cachedAt,
        categorySpecific:
          productColorObj?.cachedProduct?.product?.categorySpecific ?? [],
        productColorObj,
      };
      productColorObjects[productColorObj.productId] = product;
    }
  });
  return productColorObjects;
};

const getProductDetailFromResponse = (
  action: LoadProductDetailsResponseAction,
  storeListIds,
): ProductNode => {
  const productObjects: ProductNode = {};
  if (action.payload) {
    action.payload.results.forEach(function (productObj: ProductDetailsData) {
      const categorySpecificObj =
        productObj?.cachedProduct?.product?.categorySpecific ?? [];
      const imageGroupsObj =
        productObj?.cachedProduct?.product?.imageGroups ?? [];
      let storeSpecificDetailsForAllStores: {
        storeId: string;
        isPublished: boolean | null;
      }[] = [];
      // setting all the stores publishflag initially, if not available set it to null with key as storeId and value as boolean or null
      Array.isArray(storeListIds) &&
        storeListIds?.forEach((store: { storeId: string }) => {
          storeSpecificDetailsForAllStores[store.storeId] = {
            isPublished:
              productObj?.cachedProduct?.product?.storeSpecific?.[store.storeId]
                ?.isPublished || null,
          };
        });
      storeSpecificDetailsForAllStores["default"] = {
        isPublished:
          productObj?.cachedProduct?.product?.storeSpecific?.["default"]
            ?.isPublished,
      };
      if (Object.keys(productObj).length) {
        const product = {
          productId: productObj.productId,
          code: productObj.cachedProduct.product.code,
          typeCode: productObj.cachedProduct.product.typeCode,
          variationBaseId: productObj.cachedProduct.product.variationBaseId,
          colors: productObj.cachedProduct.product.colors,
          hasManageableColors:
            productObj.cachedProduct.product.hasManageableColors,
          hasSequenceableColors:
            productObj.cachedProduct.product.hasSequenceableColors,
          name: productObj.cachedProduct.product.name,
          thumbnail: productObj.cachedProduct.product.thumbnail,
          isPublished: productObj.cachedProduct.product.isPublished,
          url: productObj.cachedProduct.product.url,
          stock: productObj.cachedProduct.product.stock,
          stockValue: productObj.cachedProduct.product.stockValue,
          listPrice: productObj.cachedProduct.product.listPrice,
          translations: productObj.cachedProduct.product.translations,
          sequence: productObj.cachedProduct.sequence,
          descriptions: [],
          overrides: [],
          cachedAt: productObj.cachedProduct.cachedAt,
          isRefreshing: false,
          storeSpecific: storeSpecificDetailsForAllStores,
          categorySpecific: categorySpecificObj,
          imageGroups: imageGroupsObj,
          colorVariationAttributeId:
            productObj.cachedProduct.product.colorVariationAttributeId ?? "",
          hasColors: productObj.cachedProduct.product.hasColors,
        };
        productObjects[productObj.productId] = product;
      }
    });
    return productObjects;
  }

  return productObjects;
};

const mergeChildrenToProducts = (
  action: LoadChildrenItemsResponseAction,
): ChildrenItemNode => {
  const toChildrenItems: ChildrenItemNode = {};

  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      toChildrenItems[product.productId] = product.toChildProducts;
    });
  }

  return toChildrenItems;
};

const mergeChildProductVariantAttributes = (
  action: LoadChildProductVariantAttributesByParentIdResponseAction,
  variants: ChildProductVariantAttributeByParentIdNode,
): ChildProductVariantAttributeByParentIdNode => {
  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      const productId = product.productId;
      const toChildProducts = product.toChildProducts;
      if (variants[productId]) {
        toChildProducts.forEach((child) => {
          const existing = variants[productId].find(
            (variant) => variant.childProductId === child.childProductId,
          );
          if (existing) {
            existing.childProduct.attributeValues =
              child.childProduct.attributeValues;
          } else {
            variants[productId].push(child);
          }
        });
      } else {
        variants[productId] = toChildProducts;
      }
    });
  }

  return variants;
};

const getProductsWithSwatches = (
  action: LoadChildrenItemsResponseAction,
): SwatchesNode => {
  const swatches: SwatchesNode = {};
  let productSwatches: { [name: string]: ProductSwatchType } = {};

  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      productSwatches = {};
      if (product.toChildProducts) {
        product.toChildProducts.forEach((toChildProduct) => {
          if (toChildProduct.childProduct.attributeValues) {
            toChildProduct.childProduct.attributeValues.forEach(
              (attributeValueObj) => {
                const attributeValue = attributeValueObj.attributeValue;
                const attrValId = attributeValueObj.attributeValueId;
                if (
                  attributeValue.attribute.isColor &&
                  attributeValue.descriptions.length > 0
                ) {
                  const swatch = {
                    attributeValueId: attrValId,
                    colorDescriptions: attributeValue.descriptions,
                    childProductDescriptions:
                      toChildProduct.childProduct.descriptions,
                    isPublished: toChildProduct.childProduct.isPublished,
                    attrValSequence: attributeValue?.sequence,
                    childProductSequence: toChildProduct.sequence,
                    childProductId: toChildProduct.childProductId,
                  };
                  if (productSwatches.hasOwnProperty(attrValId)) {
                    swatch.isPublished =
                      productSwatches[attrValId].isPublished ||
                      swatch.isPublished;
                  }
                  productSwatches[attrValId] = swatch;
                }
              },
            );
          }
        });
      }

      if (Object.keys(productSwatches).length > 0) {
        swatches[product.productId] = Object.values(productSwatches);
      }
    });
  }

  return swatches;
};

const parseProductPrices = (
  action: LoadChildrenItemsResponseAction,
): ProductPriceNode => {
  const prices: ProductPriceNode = {};
  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      const productId = product.productId;
      prices[productId] = {};
      if (product.toChildProducts && product.toChildProducts.length > 0) {
        product.toChildProducts.forEach((toChildProduct) => {
          if (
            toChildProduct.childProduct.prices &&
            toChildProduct.childProduct.prices.length > 0
          ) {
            toChildProduct.childProduct.prices.forEach((childPrice) => {
              const childProductPrice = childPrice.amount;
              const currencyPriceObj =
                prices[productId][childPrice.currencyCode];
              if (currencyPriceObj) {
                if (currencyPriceObj.lowPrice > childProductPrice)
                  currencyPriceObj.lowPrice = childProductPrice;
                if (currencyPriceObj.highPrice < childProductPrice)
                  currencyPriceObj.highPrice = childProductPrice;
                currencyPriceObj.childProductPrices.push({
                  childProductId: toChildProduct.childProductId,
                  price: childPrice.amount,
                });
              } else {
                prices[productId][childPrice.currencyCode] = {
                  price: childPrice.amount,
                  highPrice: childPrice.amount,
                  lowPrice: childPrice.amount,
                  childProductPrices: [
                    {
                      childProductId: toChildProduct.childProductId,
                      price: childPrice.amount,
                    },
                  ],
                };
              }
            });
          }
        });
      }
      if (product.prices && product.prices.length > 0) {
        product.prices.forEach((productPrice) => {
          const currencyPriceObj = prices[productId][productPrice.currencyCode];
          prices[productId][productPrice.currencyCode] = {
            price: productPrice.amount,
            highPrice: productPrice.amount,
            lowPrice: productPrice.amount,
            childProductPrices: currencyPriceObj
              ? currencyPriceObj.childProductPrices
              : [],
          };
        });
      }
    });
  }
  return prices;
};

const parseProductInventory = (
  action: LoadChildrenItemsResponseAction,
): ProductInventoryNode => {
  const inventory: ProductInventoryNode = {};

  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      const productId = product.productId;
      inventory[productId] = {
        inventory: 0,
        childProductInventories: [],
      };
      if (product.inventories && product.inventories.length > 0) {
        inventory[productId].inventory = product.inventories[0].quantity;
      }
      if (product.toChildProducts && product.toChildProducts.length > 0) {
        let totalInventory = 0;
        product.toChildProducts.forEach((toChildProduct) => {
          if (
            toChildProduct.childProduct.inventories &&
            toChildProduct.childProduct.inventories.length > 0
          ) {
            const childProductInventory =
              toChildProduct.childProduct.inventories[0].quantity;
            totalInventory += childProductInventory;
            inventory[productId].childProductInventories.push({
              childProductId: toChildProduct.childProductId,
              inventory: childProductInventory,
            });
          }
        });
        inventory[productId].inventory = totalInventory;
      }
    });
  }

  return inventory;
};

const parseProductInventoryForReport = (
  action: LoadProductDetailsResponseAction,
): ProductInventoryNode => {
  const inventory: ProductInventoryNode = {};

  if (action.payload) {
    action.payload.results.forEach((productObject: any) => {
      const product = productObject;
      const productId = product.productId;
      inventory[productId] = {
        inventory: 0,
        childProductInventories: [],
      };
      if (product.inventories && product.inventories.length > 0) {
        inventory[productId].inventory = product.inventories[0].quantity;
      }
      if (product.toChildProducts && product.toChildProducts.length > 0) {
        product.toChildProducts.forEach((toChildProduct) => {
          if (
            toChildProduct.childProduct.inventories &&
            toChildProduct.childProduct.inventories.length > 0
          ) {
            const childProductInventory =
              toChildProduct.childProduct.inventories[0].quantity;
            inventory[productId].inventory += childProductInventory;
            inventory[productId].childProductInventories.push({
              childProductId: toChildProduct.childProductId,
              inventory: childProductInventory,
            });
          }
        });
      }
    });
  }

  return inventory;
};

const parseProductCategory = (
  action: LoadCategoryByProductIdResponseAction,
): ProductCategoryNode => {
  let category: ProductCategoryNode = {};
  if (
    action.payload &&
    action.payload.products &&
    action.payload.products.length > 0
  ) {
    action.payload.products.forEach((productCategory) => {
      category = {
        ...category,
        [productCategory.productId]: productCategory.categories,
      };
    });
  }
  return category;
};

const parseProductAttributes = (
  action: LoadProductAttributeValuesResponseAction,
): ProductAttributesNode => {
  let attributes: ProductAttributesNode = {};
  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.CategoryToProduct &&
    action.payload.data.CategoryToProduct.length > 0
  ) {
    action.payload.data.CategoryToProduct.forEach((productObject) => {
      attributes = {
        ...attributes,
        [productObject.product.productId]: productObject.product,
      };
    });
  }
  return attributes;
};

const parseChildProductAttributes = (
  action: LoadChildProductAttributeResponseAction,
): ChildAttributesNode => {
  let attributes: ChildAttributesNode = {};
  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      attributes = {
        ...attributes,
        [product.productId]: product,
      };
    });
  }
  return attributes;
};

const getProductLeadingColors = (
  action: LoadChildrenItemsResponseAction,
): LeadingColorNode => {
  const leadingColors: LeadingColorNode = {};
  if (
    action.payload &&
    action.payload.data &&
    action.payload.data.Product &&
    action.payload.data.Product.length > 0
  ) {
    action.payload.data.Product.forEach((product) => {
      leadingColors[product.productId] = product.leadingColors;
    });
  }

  return leadingColors;
};

const parseAnalyticsData = (
  action: LoadProductAnalyticsResponseAction,
): Analytics => {
  const productAnalytics: Analytics = {
    startDate: "",
    endDate: "",
    productAnalytics: {},
    productView: {},
  };
  if (action.payload && action.payload.products) {
    action.payload.products.forEach((product) => {
      productAnalytics.productAnalytics[product.productCode] =
        product.analytics;
    });
  }
  return productAnalytics;
};

const parseVariantAttributes = (
  action: LoadInventoryOvelrayAttributesResponseAction,
): CategoryVariantAttributeNode => {
  const variants: CategoryVariantAttributeNode = {};
  if (action.payload && action.payload.data && action.payload.data.Attribute) {
    action.payload.data.Attribute.forEach((attribute) => {
      if (variants[attribute.code]) {
        variants[attribute.code].push(attribute);
      } else {
        variants[attribute.code] = [attribute];
      }
    });
  }
  return variants;
};

const getProductsFromLowInventoryResponse = (
  action: LoadLowInventoryProductsResponseAction,
): ProductNode => {
  const productObjects: ProductNode = {};
  if (
    action.payload?.data?.Inventory &&
    action.payload.data.Inventory.length > 0
  ) {
    action.payload.data.Inventory.forEach(function (productObj) {
      productObj.product.toParentProducts.forEach(function (parent) {
        const product = parent.parentProduct;
        productObjects[product.productId] = product;
      });
    });
  }

  return productObjects;
};

const changeProductColors = (
  products: ProductsState,
  action: UpdateProductColorsAction,
): ProductsState => {
  const { localeCodes, productSequences, attrValueSequences, productId } =
    action.payload;

  const swatches: ProductSwatchType[] = [];
  products.swatches[productId].forEach((swatch) => {
    if (productSequences && productSequences.length > 0) {
      productSequences.forEach((productSequence) => {
        const { productId, sequence } = productSequence;
        if (swatch.childProductId === productId) {
          swatch.childProductSequence = sequence;
        }
      });
    } else if (attrValueSequences && attrValueSequences.length > 0) {
      if (localeCodes && localeCodes.length > 0) {
        attrValueSequences.forEach((attrValueSequence) => {
          const { attributeValueId, sequence } = attrValueSequence;
          if (swatch.attributeValueId === attributeValueId) {
            swatch.colorDescriptions.forEach((colorDesc) => {
              if (localeCodes.includes(colorDesc.localeCode)) {
                colorDesc.sequence = sequence;
              }
            });
          }
        });
      } else {
        attrValueSequences.forEach((attrValueSequence) => {
          const { attributeValueId, sequence } = attrValueSequence;
          if (swatch.attributeValueId === attributeValueId) {
            swatch.attrValSequence = sequence;
          }
        });
      }
    }
    swatches.push(swatch);
  });

  const childrenItems: ToChildProduct[] = [];
  products.toChildrenItems[productId].forEach((item) => {
    if (productSequences && productSequences.length > 0) {
      productSequences.forEach((productSequence) => {
        const { productId, sequence } = productSequence;
        if (item.childProductId === productId) {
          item.sequence = sequence;
        }
      });
    } else if (attrValueSequences && attrValueSequences.length > 0) {
      if (localeCodes && localeCodes.length > 0) {
        attrValueSequences.forEach((attrValueSequence) => {
          const { attributeValueId, sequence } = attrValueSequence;
          item.childProduct.attributeValues.forEach((attriValObj) => {
            if (attriValObj.attributeValueId === attributeValueId) {
              attriValObj.attributeValue.descriptions.forEach((desc) => {
                if (localeCodes.includes(desc.localeCode)) {
                  desc.sequence = sequence;
                }
              });
            }
          });
        });
      } else {
        attrValueSequences.forEach((attrValueSequence) => {
          const { attributeValueId, sequence } = attrValueSequence;
          item.childProduct.attributeValues.forEach((attriValObj) => {
            if (attriValObj.attributeValueId === attributeValueId) {
              attriValObj.attributeValue.sequence = sequence;
            }
          });
        });
      }
    }
    childrenItems.push(item);
  });
  return {
    ...products,
    swatches: {
      ...products.swatches,
      [productId]: swatches,
    },
    toChildrenItems: {
      ...products.toChildrenItems,
      [productId]: childrenItems,
    },
  };
};

const getProductsScore = (
  state: ProductsState,
  action: LoadSortedProductsAction,
) => {
  const updateProductObj = { ...state };
  if (action.payload.products && action.payload.products.length) {
    action.payload.products.forEach((product) => {
      if (updateProductObj.products[product.productId]) {
        updateProductObj.products[product.productId].productScore =
          product.score;
      }
    });
  }
  return updateProductObj;
};

const removeDuplicateArrays = (
  dataSet: CategoryDataSet[],
): CategoryDataSet[] => {
  return dataSet.map((data) => {
    const uniqueData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const products = data[key];
        const uniqueProducts = products.filter(
          (product, index, self) =>
            index ===
            self.findIndex(
              (p) => JSON.stringify(p) === JSON.stringify(product),
            ),
        );

        uniqueData[key] = uniqueProducts;
      }
    }

    return uniqueData;
  });
};

export const ProductReducer = (state = initialState, action): ProductsState => {
  switch (action.type) {
    case CHANGE_PRODUCT_COLORS: {
      const fetchAction = action as UpdateProductColorsAction;
      const newState = changeProductColors(state, fetchAction);
      return newState;
    }
    case FETCH_PRODUCT_LIST.REQUEST: {
      return {
        ...state,
        isProductsLoading: true,
      };
    }
    case FETCH_PRODUCT_LIST.SUCCESS:
      let fetchAction = action as LoadProductDetailsResponseAction;
      const storeListIds = action?.payload?.storeListIds;
      const products = getProductDetailFromResponse(fetchAction, storeListIds);
      const isFetchedBySearch = false;
      const returnState = {
        ...state,
        isFetched: true,
        products: {
          ...state.products,
          ...products,
        },
        isFetchedBySearch,
      };
      if (isFetchedBySearch) {
        returnState.searchResultIds = Object.keys(products);
      }
      return returnState;
    case FETCH_PRODUCT_LIST.FAILURE: {
      return {
        ...state,
        isFetched: true,
        isProductsLoading: false,
      };
    }
    case FETCH_PRODUCT_DETAILS_FOR_REPORT.SUCCESS: {
      let fetchAction = action as LoadProductDetailsResponseAction;
      const products = getProductDetailFromResponse(fetchAction, []);
      const returnState = {
        ...state,
        isFetched: true,
        products: {
          ...state.products,
          ...products,
        },
        inventory: {
          ...state.inventory,
          ...parseProductInventoryForReport(fetchAction),
        },
      };

      return returnState;
    }
    case FETCH_CHILDREN_ITEMS.SUCCESS:
      let fetchChildrenAction = action as LoadChildrenItemsResponseAction;
      const page = parseInt(fetchChildrenAction.payload.variables.page);
      if (page > state.childrenPage) return state;
      const currentPage = state.fetchedPages.has(page)
        ? state.childrenPage
        : state.childrenPage + 1;
      const clonedSet = new Set(Array.from(state.fetchedPages));
      clonedSet.add(page);
      return {
        ...state,
        isProductChildrenFetching: false,
        isProductChildrenFetched: true,
        childrenPage: currentPage,
        toChildrenItems: {
          ...state.toChildrenItems,
          ...mergeChildrenToProducts(fetchChildrenAction),
        },
        swatches: {
          ...state.swatches,
          ...getProductsWithSwatches(fetchChildrenAction),
        },
        leadingColors: {
          ...state.leadingColors,
          ...getProductLeadingColors(fetchChildrenAction),
        },
        fetchedPages: clonedSet,
        inventory: {
          ...state.inventory,
          ...parseProductInventory(fetchChildrenAction),
        },
        prices: {
          ...state.prices,
          ...parseProductPrices(fetchChildrenAction),
        },
      };
    case FETCH_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID.SUCCESS:
      let fetchChildProductVariantAttributesAction =
        action as LoadChildProductVariantAttributesByParentIdResponseAction;
      const code =
        fetchChildProductVariantAttributesAction.payload.variables
          .attributeCode;
      const fetchedSet = new Set(state.fetchedVariants);
      if (code) fetchedSet.add(code);
      return {
        ...state,
        fetchedVariants: Array.from(fetchedSet),
        toChildrenItemsVariantAttributes: mergeChildProductVariantAttributes(
          fetchChildProductVariantAttributesAction,
          Object.assign({}, state.toChildrenItemsVariantAttributes),
        ),
      };
    case FETCH_PRODUCT_ANALYTICS.SUCCESS:
      let fetchAnalyticsAction = action as LoadProductAnalyticsResponseAction;
      const { productAnalytics, ...rest } =
        parseAnalyticsData(fetchAnalyticsAction);
      const mergedProductAnalytics = {
        ...state.analytics.productAnalytics,
        ...productAnalytics,
      };
      return {
        ...state,
        isAnalyticsFetched: true,
        analytics: {
          ...state.analytics,
          ...rest,
          productAnalytics: mergedProductAnalytics,
        },
      };
    case LOAD_CATEGORY_BY_PRODUCT_ID:
      return {
        ...state,
        category: { ...state.category },
        isProductChildrenFetching: true,
        isProductChildrenFetched: false,
      };
    case FETCH_CATEGORY_BY_PRODUCT_ID.SUCCESS:
      let fetchProductCategoryAction =
        action as LoadCategoryByProductIdResponseAction;
      return {
        ...state,
        category: {
          ...state.category,
          ...parseProductCategory(fetchProductCategoryAction),
        },
        isProductChildrenFetching: false,
        isProductChildrenFetched: true,
      };
    case FETCH_CATEGORIES_BY_PRODUCT_IDS.SUCCESS:
      let fetchProductCategoryActionData =
        action as LoadCategoryByProductIdResponseAction;
      return {
        ...state,
        category: {
          ...state.category,
          ...parseProductCategory(fetchProductCategoryActionData),
        },
      };
    case FETCH_PRODUCT_ATTRIBUTEVALUES.SUCCESS:
      const fetchProductAttributeValuesAction =
        action as LoadProductAttributeValuesResponseAction;
      return {
        ...state,
        isAttributesFetched: true,
        attributes: parseProductAttributes(fetchProductAttributeValuesAction),
      };
    case UPDATED_SELECTED_SWATCHES:
      let {
        payload: { productId, swatch },
      } = action as UpdatedSelectedSwatchesAction;
      return {
        ...state,
        selectedSwatches: {
          ...state.selectedSwatches,
          [productId]: swatch,
        },
      };

    // case UPDATE_SELECTED_PRODUCTS_CODE:
    //   let {
    //     payload: { selectedProductsCode },
    //   } = action as UpdateSelectedProductsCodeAction;
    //   return { ...state, selectedProductsCode };
    // case RESET_PRODUCT_DETAILS:
    //   // keep ProductCodes on clipboard
    //   const prevSelectedProductsCode = state.selectedProductsCode;
    //   return {
    //     ...initialState,
    //     searchTerm: state.searchTerm,
    //     selectedProductsCode: prevSelectedProductsCode,
    //   };

    case UPDATE_CHILDREN_ITEMS: {
      const actions = action as UpdateChildrenItemsAction;
      const parentId = actions.payload.parentId;
      const toChildProducts = actions.payload.toChildProducts;
      const newToChildrenItems = { ...state.toChildrenItems };

      newToChildrenItems[parentId] = toChildProducts;

      return {
        ...state,
        toChildrenItems: {
          ...newToChildrenItems,
        },
      };
    }

    case SET_SEARCH_TERM: {
      const actions = action as SetSearchTermAction;

      return {
        ...state,
        searchTerm: actions.payload.searchTerm,
      };
    }

    case UPDATE_PRODUCT_LIST_BY_SEARCH: {
      const updateProductListAction = action as UpdateProductListBySearchAction;
      const productsNode: ProductNode = {};
      updateProductListAction.payload.products.forEach((product) => {
        productsNode[product.productId] = state.products[product.productId];
      });
      return {
        ...state,
        products: productsNode,
      };
    }

    case FETCH_CHILD_PRODUCT_ATTRIBUTES.REQUEST: {
      return {
        ...state,
        isChildProductAttributeFetching: true,
        isChildProductAttributeFetched: false,
      };
    }

    case FETCH_CHILD_PRODUCT_ATTRIBUTES.SUCCESS: {
      const childAttrributesAction =
        action as LoadChildProductAttributeResponseAction;

      return {
        ...state,
        childrenAttributes: parseChildProductAttributes(childAttrributesAction),
      };
    }

    case FETCH_INVENTORY_OVERLAY_ATTRIBUTES.SUCCESS: {
      const variantAttributesAction =
        action as LoadInventoryOvelrayAttributesResponseAction;

      return {
        ...state,
        categoryVariantAttributes: parseVariantAttributes(
          variantAttributesAction,
        ),
      };
    }

    case FETCH_LOW_INVENTORY_PRODUCTS.SUCCESS:
      let lowInventoryAction = action as LoadLowInventoryProductsResponseAction;
      const parentProducts =
        getProductsFromLowInventoryResponse(lowInventoryAction);

      return {
        ...state,
        isFetched: true,
        products: parentProducts,
        isFetchedBySearch: true,
        searchResultIds: Object.keys(parentProducts),
        alertViewType: parseInt(lowInventoryAction.payload.variables.page),
      };
    case RESET_PRODUCTS: {
      return {
        ...initialState,
        recentlyAddedProducts: [...state.recentlyAddedProducts],
      };
    }
    case SET_DATE_PICKER_RANGE: {
      let datePickerAction = action as SetDatePickerRangeAction;
      const startDate = state.analytics.startDate;
      const endDate = state.analytics.endDate;
      const isAnalyticsFetched =
        startDate !== datePickerAction.payload.startDate &&
        endDate !== datePickerAction.payload.endDate;

      return {
        ...state,
        selectedDateRange: datePickerAction.payload,
        isAnalyticsFetched,
      };
    }
    case FETCH_SORTED_PRODUCTS.SUCCESS:
      const fetchedProductActionObj = action as LoadSortedProductsAction;
      const newProductsObj = getProductsScore(state, fetchedProductActionObj);
      return {
        ...state,
        products: newProductsObj.products,
      };
    case SET_ACTIVE_SORTING_RULE:
      const ruleId = action.payload;
      return {
        ...state,
        activeSortingRule: ruleId,
      };
    case FETCH_PRODUCTS_VARIANTS.SUCCESS:
      const fetchedProductVariants = action as FetchProductsVariantsResponse;
      return {
        ...state,
        variants: {
          data: fetchedProductVariants.payload.results,
          isVariantFetched: true,
        },
      };
    case UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.REQUEST:
      return {
        ...state,
        isVariantFlagPublished: true,
      };
    case UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.SUCCESS:
      const updateProductVariant =
        action as UpdateProductVariantPublishStateResponse;
      const variantId = updateProductVariant.payload.variantId;
      const updatedData = state.variants.data.map((variant) => {
        if (variant.variantId === variantId) {
          return {
            ...variant,
            isPublished: !variant.isPublished,
          };
        } else {
          return variant;
        }
      });
      return {
        ...state,
        isVariantFlagPublished: true,
        variants: {
          data: updatedData,
          isVariantFetched: true,
        },
      };
    case UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.DONE:
      return {
        ...state,
        isVariantFlagPublished: false,
      };
    case RESET_PRODUCTS_VARIANTS_STATE: {
      return {
        ...state,
        variants: {
          ...state.variants,
          isVariantFetched: false,
        },
      };
    }
    case ADD_MIGRATED_CATEGORIES_NEW_DATA: {
      const updatedData = action.payload;
      const newProductsArray = updatedData.map((item) => ({
        ...item,
        sequence: 0,
      }));

      const resultArray = [...state.recentlyAddedProducts];

      action.categoryIds.forEach((categoryId) => {
        const categoryProducts = newProductsArray.filter(
          (item) => item.categoryId === categoryId,
        );

        if (categoryProducts.length > 0) {
          const existingIndex = resultArray.findIndex(
            (obj) => Object.keys(obj)[0] === categoryId,
          );

          if (existingIndex !== -1) {
            // Update existing category
            resultArray[existingIndex][categoryId] = [
              ...resultArray[existingIndex][categoryId],
              ...categoryProducts.map((item) => item.product),
            ];
          } else {
            // Add new category
            const categoryObject = {
              [categoryId]: categoryProducts.map((item) => item.product),
            };
            resultArray.push(categoryObject);
          }
        }
      });
      const updatedDataSet = removeDuplicateArrays(resultArray);

      return {
        ...state,
        recentlyAddedProducts: updatedDataSet,
      };
    }
    // case RESET_TEMPORARY_DATA: {
    //   return {
    //     ...state,
    //     clipBoardData: [],
    //   };
    // }
    case UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE:
      const updatedDataSet = action.payload;
      return {
        ...state,
        recentlyAddedProducts: updatedDataSet,
      };
    case REFRESH_PRODUCT.REQUEST:
      const refreshProductRequestResponse = action.payload;
      return {
        ...state,
        products: {
          ...state.products,
          [refreshProductRequestResponse.productId]: {
            ...state.products[refreshProductRequestResponse.productId],
            isRefreshing: true,
          },
        },
      };
    case REFRESH_PRODUCT.SUCCESS: {
      const responseData = action.payload as CategoryToProductData;
      return {
        ...state,
        products: {
          ...state.products,
          [responseData.productId]: {
            ...state.products[responseData.productId],
            cachedAt: responseData.cachedProduct.cachedAt,
            colors: responseData.cachedProduct?.product?.colors,
            isRefreshing: false,
            code: responseData.cachedProduct?.product?.code,
            typeCode: responseData.cachedProduct?.product?.typeCode,
            variationBaseId:
              responseData.cachedProduct?.product?.variationBaseId,
            name: responseData.cachedProduct?.product?.name,
            thumbnail: responseData.cachedProduct?.product?.thumbnail,
            isPublished: responseData.cachedProduct?.product?.isPublished,
            stock: responseData.cachedProduct?.product?.stock,
            listPrice: responseData.cachedProduct?.product?.listPrice,
            translations: responseData.cachedProduct?.product?.translations,
            categorySpecific:
              responseData?.cachedProduct?.product?.categorySpecific ?? [],
            imageGroups:
              responseData?.cachedProduct?.product?.imageGroups ?? [],
            colorVariationAttributeId:
              responseData.cachedProduct.product?.colorVariationAttributeId ??
              "",
            hasColors: responseData.cachedProduct?.product?.hasColors,
          },
        },
      };
    }
    case REFRESH_PRODUCT.FAILURE:
      const refreshProductErrorResponse = action.payload;
      return {
        ...state,
        products: {
          ...state.products,
          [refreshProductErrorResponse.productId]: {
            ...state.products[refreshProductErrorResponse.productId],
            isRefreshing: false,
          },
        },
      };
    case FETCH_PRODUCT.SUCCESS: {
      const productData = action?.payload;
      let productObject = {};
      productObject[productData.productId] = {
        ...productData.cachedProduct.product,
        descriptions: [],
        overrides: [],
        cachedAt: productData.cachedProduct.cachedAt,
        isRefreshing: false,
      };
      return {
        ...state,
        products: {
          ...state.products,
          ...productObject,
        },
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.SUCCESS: {
      const productIdToBeRemoved = action.payload.productId;
      const productListData = { ...state.products };
      delete productListData[productIdToBeRemoved];

      return {
        ...state,
        products: {
          ...productListData,
        },
      };
    }
    case UPDATE_PRODUCT_ISPUBLISHED_STATE.SUCCESS:
      return {
        ...state,
      };
    case UPDATE_PRODUCT_ISPUBLISHED_STATE.PENDING:
      return {
        ...state,
        isPublishedFlag: true,
      };
    case RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE:
      return {
        ...state,
        isPublishedFlag: false,
      };
    case FETCH_PRODUCTS_COLORS.SUCCESS: {
      const fetchProductsColorsObj =
        action as loadProductsColorsDataActionPayload;
      const productColorsList = fetchProductsColorsObj.payload?.results;
      const productsColorDataFromResponse =
        getProductsColorsDataFromResponse(productColorsList);
      return {
        ...state,
        productsColorData: {
          ...state.productsColorData,
          ...productsColorDataFromResponse,
        },
        isProductsLoading: false,
      };
    }
    case FETCH_PRODUCTS_COLORS.FAILURE: {
      return {
        ...state,
        isProductsLoading: false,
      };
    }
    case REFRESH_PRODUCT_COLOR.REQUEST:
      const refreshProductColorRequestResponse = action.payload;
      return {
        ...state,
        productsColorData: {
          ...state.productsColorData,
          [refreshProductColorRequestResponse.productId]: {
            ...state.productsColorData[
              refreshProductColorRequestResponse.productId
            ],
            isRefreshing: true,
          },
        },
      };
    case REFRESH_PRODUCT_COLOR.SUCCESS: {
      const responseProductColorData = action.payload as productColorsData;
      return {
        ...state,
        productsColorData: {
          ...state.productsColorData,
          [responseProductColorData.productId]: {
            ...responseProductColorData.cachedProduct.product,
            isRefreshing: false,
          },
        },
      };
    }
    case REFRESH_PRODUCT_COLOR.FAILURE:
      const refreshProductColorErrorResponse = action.payload;
      return {
        ...state,
        productsColorData: {
          ...state.productsColorData,
          [refreshProductColorErrorResponse.productId]: {
            ...state.productsColorData[
              refreshProductColorErrorResponse.productId
            ],
            isRefreshing: false,
          },
        },
      };
    case ANALYTICS_DATA_LOADER:
      const { analyticsProductId } = action;
      return {
        ...state,
        analytics: {
          ...state.analytics,
          productView: {
            ...state.analytics.productView,
            [analyticsProductId]: {
              ...state.analytics.productView[analyticsProductId],
              loadAnalytics: true,
            },
          },
        },
      };
    case LOAD_SINGLE_PRODUCT_ANALYTICS_STATE.SUCCESS:
      const fetchProductAnalytics = action.payload;
      return {
        ...state,
        isAnalyticsFetched: true,
        analytics: {
          ...state.analytics,
          productAnalytics: {
            ...state.analytics.productAnalytics,
            [fetchProductAnalytics.productId]: [
              ...fetchProductAnalytics.analytics,
            ],
          },
          productView: {
            ...state.analytics.productView,
            [fetchProductAnalytics.productId]: {
              showAnalytics: true,
              loadAnalytics: false,
            },
          },
        },
      };
    case RESET_SINGLE_PRODUCT_ANALYTICS_VIEW: {
      const { productId } = action as resetSingleProductAnalyticAction;
      return {
        ...state,
        analytics: {
          ...state.analytics,
          productView: {
            ...state.analytics.productView,
            [productId]: {
              showAnalytics: false,
              loadAnalytics: false,
            },
          },
        },
      };
    }
    case RESET_ALL_PRODUCT_ANALYTICS_VIEW: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          productView: {},
        },
      };
    }
    case SET_PRODUCTS_LOADING_STATE:
      return {
        ...state,
        isProductsLoading: false,
      };
    default:
      return state;
  }
};
