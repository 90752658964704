import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import useStyles from "./AddProductsModalStyles";
import ProductCategorySelection from "../../components/add-products-modal/ProductCategorySelection";
import {
  AddProductsToCategory,
  ResetProductAddedState,
} from "../../store/add-product-categories/AddProductCategoriesActions";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import { selectCategoriesByProductId } from "../../store/product/ProductSelectors";
import {
  selectAreAllProductsAdded,
  selectIsProductAdded,
  selectIsProgressing,
} from "../../store/add-product-categories/AddProductCategoriesSelector";
import { CircularProgress } from "@material-ui/core";
import {
  addMigratedCategoriesNew,
  loadCategoryByProductId,
} from "../../store/product/ProductActions";
import { decodeNodeId } from "../../store/add-product-categories/AddProductCategoriesUtils";
import ConfirmAddProducts from "./ConfirmAddProducts";
import { selectCurrentStoreId } from "../../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { selectTopCategories } from "../../store/category/CategorySelectors";
import useClipboard from "../../hooks/useClipBoard";
import CustomDialog from "../common/DialogContainer";
import { useIntl } from "react-intl";
import ButtonComponent from "../common/ButtonComponent";

const AddProductsModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "AddProductsModal"),
  );
  const clipBoardHook = useClipboard();
  const productIds = clipBoardHook.getClipboardProductCodes();
  const categoryList = useSelector((state: AppState) =>
    selectCategoriesByProductId(state),
  );
  const isProductAdded = useSelector(selectIsProductAdded);
  const topCategories = useSelector(selectTopCategories);
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId) ?? "";
  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";

  const isProgressing = useSelector(selectIsProgressing);
  const areAllProductsAdded = useSelector(selectAreAllProductsAdded);

  const handleClose = () => {
    dispatch(setModalState("AddProductsModal", false));
  };

  useEffect(() => {
    if (selectedIds.length) {
      const nodeIds: string[] = [];
      selectedIds.forEach((id) => {
        //First 2 ids we are getting storeId & catalogId. so we are ignoring it.
        if (id.split("&").length > 2) {
          nodeIds.push(id);
        }
      });
      setSelectedNodeIds(nodeIds);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (isModalOpen && productIds.length > 0) {
      const payload = {
        productIds,
      };
      dispatch(loadCategoryByProductId(payload));
    }
  }, [productIds, isModalOpen, dispatch]);

  const copiedData = productIds;
  const handleSubmitCategoryChanges = () => {
    let categoryIds: {
      storeId: string;
      catalogId: string;
      categoryId?: string;
    }[] = [];
    selectedNodeIds.forEach((id) => {
      const idsArr = id.split("&");
      const storeId = idsArr[0];
      const catalogId = idsArr[1];
      const categoryId = idsArr[idsArr.length - 1];
      currentStoreId === storeId &&
      currentCatalogId === catalogId &&
      topCategories.find((e) => e.categoryId === categoryId)
        ? categoryIds.push({ storeId: storeId, catalogId: catalogId })
        : categoryIds.push({
            storeId: storeId,
            catalogId: catalogId,
            categoryId: categoryId,
          });
    });
    dispatch(
      AddProductsToCategory({
        selectedIds: selectedNodeIds,
        products: productIds,
        localeCode: currentLocaleId,
        productCategories: categoryList,
        categoryIds: categoryIds,
      }),
    );
  };

  useEffect(() => {
    if (isProductAdded) {
      dispatch(ResetProductAddedState());
      if (selectedNodeIds && Array.isArray(selectedNodeIds)) {
        const newDataArr: any[] = [];
        const newProductsArr: any[] = [];
        for (const node of selectedNodeIds) {
          const parts = decodeNodeId(node);
          const categoryId = parts[parts.length - 1];
          for (const product of productIds) {
            const shouldAddProduct =
              categoryList &&
              categoryList.product &&
              categoryList?.[product].find(
                (cat) => cat?.categoryId === categoryId,
              );
            if (!shouldAddProduct) {
              newDataArr.push(categoryId);
              copiedData.forEach((data) => {
                if (data === product) {
                  newProductsArr.push({ data, categoryId: categoryId });
                }
              });
            }
          }
        }
        dispatch(
          addMigratedCategoriesNew(newProductsArr, [...new Set(newDataArr)]),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductAdded, dispatch]);

  useEffect(() => {
    if (!isProgressing && areAllProductsAdded) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProgressing, areAllProductsAdded]);

  const addProductsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "addProductsModal.header",
          defaultMessage: "Add Products to Categories",
        })}
      </Typography>
    );
  };

  const addProductsModalContent = () => {
    return (
      <>
        <ProductCategorySelection
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </>
    );
  };

  const dialogActions = () => {
    return (
      <>
        {isProgressing && !areAllProductsAdded && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.dailogAction}>
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: "addProductsModal.cancel",
              defaultMessage: "Cancel",
            })}
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            variant="contained"
            justifyContent="center"
            onClick={() => dispatch(setModalState("ConfirmAddProducts", true))}
          >
            {intl.formatMessage({
              id: "addProductsModal.save",
              defaultMessage: "Save",
            })}
          </ButtonComponent>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomDialog
        open={isModalOpen}
        onClose={() => handleClose()}
        title={addProductsModalTitle()}
        actions={dialogActions()}
        fullWidth
        maxWidth={"sm"}
      >
        <>
          {addProductsModalContent()}
          <ConfirmAddProducts handleAdd={handleSubmitCategoryChanges} />
        </>
      </CustomDialog>
    </>
  );
};

export default AddProductsModal;
