import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useField } from "formik";
import moment from "moment-timezone";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import AppState from "../../../store/AppState";
import { useIntl } from "react-intl";
import { setModalState } from "../../../store/modal/ModalActions";
import Typography from "@material-ui/core/Typography";
import { styled, Theme, useTheme } from "@mui/material";
import CustomDialog from "../../common/DialogContainer";
import { Box, CircularProgress } from "@material-ui/core";
import { SelectChangeEvent } from "@mui/material";
import ButtonComponent from "../../common/ButtonComponent";
import * as Yup from "yup";
import {
  cancelProductAlertsSchedule,
  scheduleProductAlertsJob,
} from "../../../store/product-alerts-schedule-modal/ProductAlertsScheduleAction";
import {
  selectIsFetchingProductAlertsSchedule,
  selectIsUpdatingProductAlertSchedule,
  selectProductAlertsScheduleData,
} from "../../../store/product-alerts-schedule-modal/ProductAlertsScheduleSelectors";
import DropdownMenu from "../../common/DropdownMenu";

type Key = string | number;

type SelectProps = {
  name: string | Number;
  label: string;
  menuOptions: Key[];
  width: string;
};

const hours = Array.from({ length: 24 }, (_, i) => i); // Hours from 0 to 23
const minutes = Array.from({ length: 60 }, (_, i) => i); // Minutes from 0 to 59
const timezones = moment.tz.names();

const validationSchema = Yup.object({
  hour: Yup.string().required("Required"),
  minute: Yup.string().required("Required"),
  timezone: Yup.string().required("Required"),
});

const styleClasses = {
  overlay: "overlay",
  productAlertsScheduleContainer: "productAlertsScheduleContainer",
  buttonContainer: "buttonContainer",
};

const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.productAlertsScheduleContainer}`]: {
    display: "flex",
    justifyContent: "center", // Center the SelectWrapper components
    alignItems: "center", // Vertically center the SelectWrapper components
    gap: "20px", // Add equal spacing between the SelectWrapper components
  },
  [`&.${styleClasses.buttonContainer}`]: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
  },
}));

const useStyles = (theme: Theme) => {
  return {
    productAlertsModalDescription: {
      padding: "10px 0px 10px 10px",
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: theme.palette.text.primary,
    },
  };
};

function ProductAlertsScheduleModal() {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ProductAlertsScheduleModal"),
  );
  const isFecthingDetails = useSelector(selectIsFetchingProductAlertsSchedule);
  const isUpdatingScheduleData = useSelector(
    selectIsUpdatingProductAlertSchedule,
  );
  const selectProductAlertsSchedule = useSelector(
    selectProductAlertsScheduleData,
  );

  const enableDisableAction = useMemo(
    () =>
      Object.values(selectProductAlertsSchedule).filter(
        (value) => value !== null && value !== "",
      )?.length,
    [selectProductAlertsSchedule],
  );

  const [selectedHour, setSelectedHour] = useState<Number | null>(null);
  const [selectedMinutes, setSelectedMinutes] = useState<Number | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const [initialState, setInitialState] = useState({});

  const enableScheduleButton = useMemo(() => {
    const isProductAlertDataUpdated =
      selectedHour !== selectProductAlertsSchedule.hour ||
      selectedMinutes !== selectProductAlertsSchedule.minute ||
      selectedTimezone !== selectProductAlertsSchedule.timezone;
    return (
      selectedHour !== null &&
      selectedMinutes !== null &&
      selectedTimezone !== "" &&
      isProductAlertDataUpdated
    );
  }, [
    selectedHour,
    selectedMinutes,
    selectedTimezone,
    selectProductAlertsSchedule,
  ]);

  const showCancelButton = useMemo(() => {
    const isProductAlertDataUpdated =
      selectedHour || selectedMinutes || selectedTimezone;
    const isHourUpdated = selectedHour !== selectProductAlertsSchedule.hour;
    const isMinuteUpdated =
      selectedMinutes !== selectProductAlertsSchedule.minute;
    const isTimeZoneUpdate =
      selectedTimezone !== selectProductAlertsSchedule.timezone;
    if (
      isProductAlertDataUpdated &&
      (isHourUpdated || isMinuteUpdated || isTimeZoneUpdate)
    ) {
      return true;
    }
    return false;
  }, [
    selectedHour,
    selectedMinutes,
    selectedTimezone,
    selectProductAlertsSchedule,
  ]);

  const handleClose = useCallback(() => {
    updateProductAlertScheduleData(null, null, "");
    dispatch(setModalState("ProductAlertsScheduleModal", false));
  }, [dispatch]);

  const deleteExistingSchedule = useCallback(() => {
    dispatch(cancelProductAlertsSchedule());
    updateProductAlertScheduleData(null, null, "");
    setInitialState({});
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedHour === null &&
      selectedMinutes === null &&
      selectedTimezone === "" &&
      !isUpdatingScheduleData
    ) {
      updateProductAlertScheduleData(
        selectProductAlertsSchedule.hour,
        selectProductAlertsSchedule.minute,
        selectProductAlertsSchedule.timezone,
      );
    }
  }, [
    selectProductAlertsSchedule,
    selectedHour,
    selectedMinutes,
    selectedTimezone,
    isUpdatingScheduleData,
  ]);

  useEffect(() => {
    if (selectProductAlertsSchedule.hour !== null) {
      setInitialState(selectProductAlertsSchedule);
    }
  }, [selectProductAlertsSchedule]);

  const updateProductAlertScheduleData = (
    hour: Number | null,
    minute: Number | null,
    timezone: string,
  ) => {
    setSelectedHour(hour);
    setSelectedMinutes(minute);
    setSelectedTimezone(timezone);
  };
  const productAlersScheduleModalHeader = useCallback(() => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "productAlertsScheduleModal.modalTitle",
          defaultMessage: "Product Alerts Schedule",
        })}
      </Typography>
    );
  }, [intl]);

  const SelectWrapper = ({
    name,
    label,
    menuOptions = [],
    width = "",
  }: SelectProps) => {
    const [field, meta, helpers] = useField(name as any);
    const { value } = field;
    const { setValue } = helpers;

    const handleChange = (event: SelectChangeEvent<string | Number>) => {
      if (label === "Minute") {
        setSelectedMinutes(Number(event.target.value));
      }
      if (label === "Hour") {
        setSelectedHour(Number(event.target.value));
      }
      if (label === "Timezone") {
        setSelectedTimezone(event.target.value as string);
      }
      setValue(event.target.value);
    };

    return (
      <>
        <DropdownMenu
          menuId="ProductAlertScheduleModal"
          value={value}
          label={label}
          changeHandler={handleChange}
          menuOptions={menuOptions}
        />
        {meta.touched && meta.error ? (
          <div style={{ color: "red", fontSize: "12px" }}>{meta.error}</div>
        ) : null}
      </>
    );
  };

  const productAlersScheduleModalContent = () => (
    <div>
      {(isFecthingDetails || isUpdatingScheduleData) && (
        <StyledDiv className={styleClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      <Typography style={classes.productAlertsModalDescription} variant="body1">
        {intl.formatMessage({
          id: "productAlertsScheduleModal.modalDescription",
          defaultMessage:
            "Please enter the time when the daily job to generate product alerts should run",
        })}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const payload = {
            hour: Number(selectedHour),
            minute: Number(selectedMinutes),
            timezone: selectedTimezone,
          };
          dispatch(scheduleProductAlertsJob(payload));
        }}
      >
        {(_formik) => {
          return (
            <div>
              <Form>
                <StyledDiv
                  className={styleClasses.productAlertsScheduleContainer}
                >
                  <SelectWrapper
                    name="hour"
                    menuOptions={hours}
                    label="Hour"
                    width="100px"
                  />
                  <SelectWrapper
                    name="minute"
                    menuOptions={minutes}
                    label="Minute"
                    width="100px"
                  />
                  <SelectWrapper
                    name="timezone"
                    menuOptions={timezones}
                    label="Timezone"
                    width="150px"
                  />
                </StyledDiv>

                <StyledDiv className={styleClasses.buttonContainer}>
                  <ButtonComponent
                    color="tertiary"
                    variant="contained"
                    disabled={!enableDisableAction}
                    onClick={deleteExistingSchedule}
                  >
                    {intl.formatMessage({
                      id: "productAlertsScheduleModal.disableAction",
                      defaultMessage: "Disable",
                    })}
                  </ButtonComponent>
                  {showCancelButton ? (
                    <ButtonComponent
                      color="tertiary"
                      variant="contained"
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: "productAlertsScheduleModal.cancelAction",
                        defaultMessage: "Cancel",
                      })}
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent
                      color="primary"
                      variant="contained"
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: "productAlertsScheduleModal.closeAction",
                        defaultMessage: "Close",
                      })}
                    </ButtonComponent>
                  )}
                  <ButtonComponent
                    color="tertiary"
                    variant="contained"
                    disabled={!enableScheduleButton}
                    type="submit"
                  >
                    {intl.formatMessage({
                      id: "productAlertsScheduleModal.scheduleAction",
                      defaultMessage: "Schedule",
                    })}
                  </ButtonComponent>
                </StyledDiv>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <Box style={{ maxWidth: "450px" }}>
      <CustomDialog
        open={isModalOpen}
        onClose={handleClose}
        title={productAlersScheduleModalHeader()}
      >
        {productAlersScheduleModalContent()}
      </CustomDialog>
    </Box>
  );
}

export default ProductAlertsScheduleModal;
