export const SET_MODAL_STATE = "SET_MODAL_STATE";

export interface setModalStateAction {
  payload: Modal;
  type: string;
}

interface Modal {
  modalId: string;
  isOpen: boolean;
  [key: string]: any;
}

export interface Variables {
  [key: string]: any;
}
