import React, { useState } from "react";
import ReactGA from "react-ga";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { useIntl } from "react-intl";

import ItemManagementTablePagination from "./ItemManagementTablePagination";
import { Item } from "../../store/product/ProductTypes";
import { Order } from "../../utils/SortTableUtils";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProductsVariantPublishState } from "../../store/product/ProductActions";
import {
  selectProductVariants,
  selectVariantPublishedFlag,
} from "../../store/product/ProductSelectors";
import { CircularProgress } from "@material-ui/core";
import TableComponent from "../common/Table";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    container: {
      maxHeight: "50vh",
    },
    table: {
      minWidth: 750,
    },
    toolBar: {
      backgroundColor: theme.palette.grey[200],
      minHeight: "48px",
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      marginRight: 0,
      marginLeft: "auto !important",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
    noRecordsTablecell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);

interface ItemManagementTableProps {
  selected: { [key: string]: { [sku: string]: boolean } };
  setSelected: (selected: {
    [key: string]: { [sku: string]: boolean };
  }) => void;
  items: Item[];
  productId: string;
}

export default function ItemManagementTable(props: ItemManagementTableProps) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isVariantPublished = useSelector(selectVariantPublishedFlag);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [useSearchResults, setUseSearchResults] = useState(false);
  const { selected, setSelected, items, productId } = props;
  const [rows, setRows] = useState<Item[]>(items);
  const productVariants = useSelector(selectProductVariants);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    ReactGA.event({
      category: "Item Management",
      action: `Sort ${property} In ${isAsc ? "desc" : "asc"} Order Action`,
    });
  };

  const handleCheck = (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => {
    const isItemSelected = isSelected(SKU, key);
    const rowsData = useSearchResults ? rows : items;
    const data = rowsData.find((e) => e["SKU"] === SKU);
    const isDefaultSelected = data ? (data[key] as boolean) : data;
    const variantCode =
      productVariants?.data?.find((e) => e?.variantId === SKU)?.code ?? "";
    if (key === "Published") {
      const payload = {
        productId: productId,
        variantId: SKU,
        variantCode: variantCode,
        isPublished: !isDefaultSelected,
      };
      dispatch(UpdateProductsVariantPublishState(payload));
    }
    let newSelected = { ...selected };
    if (isItemSelected === undefined) {
      if (selected[key][SKU] === undefined && isDefaultSelected !== undefined) {
        newSelected[key] = {
          ...selected[key],
          [SKU]: !isDefaultSelected,
        };
      }
    } else {
      if (isDefaultSelected !== undefined) {
        newSelected[key] = {
          ...selected[key],
        };
        delete newSelected[key][SKU];
      }
    }
    setSelected(newSelected);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newRows = [...items];
    if (event.target.value.length) {
      newRows = newRows.filter((data) => {
        return Object.keys(data).some((key) => {
          if (typeof data[key] === "string") {
            const value = data[key] as string;
            return value
              .toLowerCase()
              .includes(event.target.value.toLowerCase());
          } else {
            return false;
          }
        });
      });
    }
    setRows(newRows);
    setPage(0);
    setUseSearchResults(newRows.length !== items.length);
    if (!useSearchResults && newRows.length !== items.length) {
      ReactGA.event({
        category: "Item Management",
        action: "Search Action",
      });
    }
  };

  const isSelected = (sku: string, key: string) =>
    selected[key][sku] !== undefined ? selected[key][sku] : undefined;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {isVariantPublished && (
          <div className={classes.overlay}>
            {""}
            <CircularProgress />
            {""}
          </div>
        )}
        <Toolbar className={classes.toolBar}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Icons iconId="SearchIcon" />
            </div>
            <InputBase
              placeholder={intl.formatMessage({
                id: "itemManagementTable.searchBoxPlaceholder",
                defaultMessage: "Search...",
              })}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleSearch}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
        <TableComponent
          tableId="ItemManagementTable"
          classes={classes}
          rows={rows}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          selected={selected}
          items={items}
          useSearchResults={useSearchResults}
          page={page}
          rowsPerPage={rowsPerPage}
          isSelected={isSelected}
          handleCheck={handleCheck}
        />
        <ItemManagementTablePagination
          count={useSearchResults ? rows.length : items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </div>
  );
}
